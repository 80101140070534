import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { userServices } from "api/services";
import Title from "components/Title/Title";
import Template from "layout/Template/Template";
import UpdateUserPageStore from "./UpdateUserPageStore";
import AuthPageStore from "pages/AuthPage/AuthPageStore";
import BranchesPageStore from "pages/BranchesPage/BranchesPageStore";
import CategoriesPageStore from "pages/CategoriesPage/CategoriesPageStore";
import { genders } from "refs/genders";
import { roles } from "refs/roles";
import { Button, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, Select, Switch, TextField } from "@mui/material";
import './UpdateUserPage.sass';


const UpdateUserPage: React.FC = observer(() => {
  const {
    firstName,
    lastName,
    middleName,
    login,
    branchId,
    gender,
    password,
    isActive,
    role,
    categoryIds,
    loading,
    hasError,
    setFirstName,
    setLastName,
    setMiddleName,
    setLogin,
    setBranchId,
    setGender,
    setPassword,
    setIsActive,
    setRole,
    setCategoryIds,
    setHasError,
    setLoading,
    getUserById,
    clearFields
  } = UpdateUserPageStore;
  const { branches } = BranchesPageStore
  const { categories } = CategoriesPageStore
  const { userId } = useParams<{ userId: string }>();
  const { user } = AuthPageStore
  const history = useNavigate()

  useEffect(() => {
    if(!userId) return
    getUserById(Number(userId))
  }, [getUserById, userId])

  const updateUser = async (e: React.FormEvent) => {
    e.preventDefault()
    let validation = true
    if(!firstName.trim() || !lastName.trim() || !role.trim() || !login.trim() || !gender?.trim()) {
      validation = false
      setHasError(false)
    }

    if(!branchId){
      setHasError(true)
      validation = false
    }

    if(!categories.length && role === 'INSTRUCTOR'){
      setHasError(true)
      validation = false
    }

    if(!validation) return 

    const params = {
      firstName,
      lastName,
      middleName,
      login,
      branchId,
      gender,
      ...(!!password.trim() ? {password} : {}),
      isActive,
      role,
      categoryIds,
    }

    try {
      setLoading(true)
      await userServices.updateUser(Number(userId), params)
      clearFields()
      history('/users')
    } catch (e: any) { 

    } finally {
      setLoading(false)
    }
  }

  return (
    <Template>
      <div className='update-user-page'>
        <Title
          title='Обновить пользователя'
          className='update-user-page__title'
        />
        <form onSubmit={updateUser}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  onChange={(e) => setIsActive(e.target.checked)}
                  checked={isActive}
                />
              }
              label='Активность'
            />
          </FormGroup>
          <TextField
            className='update-user-page__field'
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            placeholder='Имя'
            error={hasError && !firstName.trim()}
          />
          <TextField
            className='update-user-page__field'
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            placeholder='Фамилия'
            error={hasError && !lastName.trim()}
          />
          <TextField
            className='update-user-page__field'
            value={middleName}
            onChange={(e) => setMiddleName(e.target.value)}
            placeholder='Отчество'
          />
          <TextField
            className='update-user-page__field'
            value={login}
            onChange={(e) => setLogin(e.target.value)}
            placeholder='Логин'
            error={hasError && !login.trim()}
          />
          <TextField
            className='update-user-page__field'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder='Пароль'
            error={hasError && !password.trim()}
          />
          <FormControl fullWidth className='update-user-page__field'>
            <InputLabel variant='outlined'>Выберите филиал</InputLabel>
            {!!branches.length && (
              <Select
                label={'Выберите филиал'}
                value={branchId}
                onChange={(e) => setBranchId(e.target.value)}
                error={hasError && !branchId}
              >
                {branches?.map((i) => (
                  <MenuItem key={i.id} value={i.id}>
                    {i.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormControl>
          <FormControl fullWidth className='update-user-page__field'>
            <InputLabel variant='outlined'>Выберите роль</InputLabel>
            <Select
              label={'Выберите роль'}
              value={role}
              onChange={(e) => setRole(e.target.value)}
              error={hasError && !branchId}
            >
              {roles?.map((i) => (
                <MenuItem key={i.value} value={i.value}>
                  {i.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth className='update-user-page__field'>
            <InputLabel variant='outlined'>Выберите пол</InputLabel>
            <Select
              label={'Выберите пол'}
              value={gender}
              onChange={(e) => setGender(e.target.value)}
              error={hasError && !gender}
            >
              {genders?.map((i) => (
                <MenuItem key={i.value} value={i.value}>
                  {i.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {!!categories.length && role === 'INSTRUCTOR' && (
            <FormControl fullWidth className='update-user-page__field'>
              <InputLabel>Категории</InputLabel>
              <Select
                label='Категории'
                multiple
                value={categoryIds}
                onChange={(e) => setCategoryIds(e.target.value)}
                error={hasError && !categoryIds.length && role === 'INSTRUCTOR'}
              >
                {categories.map((i) => (
                  <MenuItem key={i.id} value={i.id}>
                    {i.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button type="submit" color='primary' size='medium' disabled={(user?.role !== 'ADMIN' && user?.role !== 'SU')  && loading}>
                Сохранить
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Link to='/users'>
                <Button color='inherit'>Назад</Button>
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Template>
  )
})

export default UpdateUserPage