import { studentsServices } from 'api/services';
import { IResStudent, IStudentParams } from 'api/services/students/types';
import { makeAutoObservable } from 'mobx';
import { GENDER } from 'refs/genders';

class StudentsPageStore {
  params: IStudentParams = {
    lastName: '',
    middleName: '',
    firstName: '',
    pinfl: '',
    passportNumber: '',
    passportSerial: '',
    gender: '',
    phone: '',
    skip: 0,
    take: 25,
  };
  students: IResStudent[] = [];
  totalCount = 0;
  hasError = false;

  constructor() {
    makeAutoObservable(this);
  }

  setSkip = (value: number) => (this.params.skip = value);
  setTake = (value: number) => (this.params.take = value);
  setSearch = (value: string) => (this.params.lastName = value);
  setHasError = (value: boolean) => (this.hasError = value);
  setTotalCount = (value: number) => (this.totalCount = value);
  setStudents = (data: IResStudent[]) => (this.students = data);
  setLastName = (value: string) => (this.params.lastName = value);
  setFirstName = (value: string) => (this.params.firstName = value);
  setMiddleName = (value: string) => (this.params.middleName = value);
  setPassportNumber = (value: string) => (this.params.passportNumber = value);
  setPassportSerial = (value: string) => (this.params.passportSerial = value);
  setPinfl = (value: string) => (this.params.pinfl = value);
  setGender = (value: GENDER) => (this.params.gender = value);
  setPhone = (value: string) => (this.params.phone = value);

  getStudents = async () => {
    const res = await studentsServices
      .getStudents({
        ...this.params,
        skip: this.params.take * this.params.skip,
      })
      .then((res) => res.data);
    this.setStudents(res.data);
    this.setTotalCount(res.count);
  };

  clearFields = (isButton?: boolean) => {
    this.setSearch('');
    this.setTake(0);
    if (isButton) this.getStudents();
  };
}

export default new StudentsPageStore();
