import { contractsServices, groupsServises } from 'api/services';
import { IResUser } from 'api/services/users/types';
import clone from 'lodash/clone';
import { makeAutoObservable } from 'mobx';

class UpdateGroupPageStore {
  brId = '';
  teacherId = '' as any;
  lang = '';
  loading = false;
  hasError = false;
  search = '';
  users = [] as IResUser[];
  contracts = [] as any;
  searchContracts = '';
  contractId = '' as any;
  contractIds = [] as any;
  lessons = [] as any;
  data = {} as any;

  constructor() {
    makeAutoObservable(this);
  }
  setData = (e: any) => {
    this.data = e;
  };
  setLang = (e: any) => {
    this.lang = e;
  };
  setTeacherId = (e: any) => {
    this.teacherId = e;
  };
  setBrId = (e: any) => {
    this.brId = e;
  };
  setLoading = (e: boolean) => {
    this.loading = e;
  };
  setHasError = (e: boolean) => {
    this.hasError = e;
  };
  setSearch = (e: any) => {
    this.search = e;
  };
  setSearchContracts = (e: any) => {
    this.searchContracts = e;
  };
  setContractId = (e: any) => {
    this.contractId = e;
  };
  setUsers = (e: any) => {
    this.users = e;
  };
  clearFields = () => {
    this.brId = '';
    this.teacherId = '';
    this.lang = '';
    this.loading = false;
    this.hasError = false;
    this.search = '';
    this.users = [];
    this.contracts = [];
    this.searchContracts = '';
    this.contractId = '';
    this.contractIds = [];
    this.lessons = [];
    this.data = {};
  };
  addLessons = () => {
    this.lessons = [...this.lessons, { value: undefined }];
  };
  lessonsChange = (e: any, index: number) => {
    const arr = clone(this.lessons);
    let findIndex = arr?.findIndex(
      (i: any, findIndex: number) => findIndex === index
    );
    let currentObj = arr?.find(
      (i: any, findIndex: number) => findIndex === index
    );
    if (currentObj) {
      currentObj.value = e;
      arr.splice(findIndex, 1, currentObj);
    }
    this.lessons = [...arr];
  };
  deleteLessons = (index: number) => {
    const arr = clone(this.lessons);
    let findIndex = arr?.findIndex(
      (i: any, currenIndex: any) => currenIndex === index
    );
    arr.splice(findIndex, 1);
    this.lessons = [...arr];
  };
  addContracts = () => {
    if (!this.contractId || !this.searchContracts) return;
    let arr = clone(this.contractIds);
    let find = arr?.some((i: any) => i?.id === this.contractId);
    if (!find) {
      this.contractIds = [
        ...this.contractIds,
        {
          id: this.contractId,
          name: this.searchContracts,
          status: this.data?.status,
          tariff: this.data.tariff,
        },
      ];
    }
    this.setSearchContracts('');
  };
  deleteContracts = (id: number) => {
    let arr = clone(this.contractIds);
    let find = arr?.some((i: any) => i?.id === id);
    if (find) {
      let newArr = arr.filter((i: any) => i.id !== id);
      this.contractIds = newArr;
    }
  };
  getGroupById = async (id: number) => {
    const res = await groupsServises.getGroupById(id).then((res) => res.data);
    this.brId = res.branchId ? res.branchId.toString() : '';
    this.lang = res.lang;
    this.teacherId = res.teacher ? res.teacher.id.toString() : '';
    this.search = res.teacher
      ? `${res.teacher.lastName} ${res.teacher.firstName} ${res.teacher.middleName}`
      : '';
    this.contractIds = res?.contracts?.map((i) => ({
      id: i.id,
      name: `${i.contractNumber}, ${i.student.lastName} ${i.student.firstName} ${i.student.middleName}`,
      tariff: `${i.tariff.titleRu}`,
      status: i.status,
    }));
    this.lessons = !!res?.lessons?.length
      ? res?.lessons?.map((i) => ({ id: i.id, value: i.datetime }))
      : [];
  };
  getContracts = async (value?: any) => {
    if (!this.brId) return;
    const params = {
      skip: 0,
      take: 15,
      branchId: Number(this.brId),
      search: value,
      statuses: ['NEW', 'PAYED'] as IStatus[],
    };
    const res = await contractsServices
      .getContracts(params)
      .then((res) => res.data);
    this.contracts = res.data.map((i) => ({
      name: `${i.contractNumber}, ${i.student.firstName} ${i.student.lastName} ${i.student.firstName} ${i.student?.middleName}`,
      tariff: i.tariff.titleRu,
      value: i.id,
      status: i.status,
    }));
  };
}

export default new UpdateGroupPageStore();
