import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { genders } from 'refs/genders';
import { contractStatuses } from 'refs/contractStatus';
import Title from 'components/Title/Title';
import Template from 'layout/Template/Template';
import ContractsPageStore from './ContractsPageStore';
import BranchesPageStore from 'pages/BranchesPage/BranchesPageStore';
import TariffsPageStore from 'pages/TariffsPage/TariffsPageStore';
import AuthPageStore from 'pages/AuthPage/AuthPageStore';
import { isSU, isAdmin } from 'helpers/checkRole';
import Statuses from 'components/Statuses/Statuses';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/CancelPresentation';
import './ContractsPage.sass';
import { ContractStatus } from 'refs/contractStatuses';

const referrals: {
  title: string;
  value: string;
}[] = [
  {
    title: 'Есть',
    value: 'true',
  },
  {
    title: 'Нет',
    value: 'false',
  },
];

const confrimReferrals: {
  title: string;
  value: string;
}[] = [
  {
    title: 'Подтвержден',
    value: 'true',
  },
  {
    title: 'Не подтвержден',
    value: 'false',
  },
];

const ContractsPage: React.FC = observer(() => {
  const {
    getContracts,
    setPage,
    params,
    setTake,
    contracts,
    totalCount,
    filters,
    searchHandler,
    clearHandler,
    setStudentId,
    setBranchId,
    setFinished,
    setLang,
    setInstructorGender,
    setStatus,
    setTariffId,
    setOperatorId,
    setRefChecked,
    setRefferals,
  } = ContractsPageStore;
  const { tariffs } = TariffsPageStore;
  const { branches } = BranchesPageStore;
  const { user } = AuthPageStore;
  const history = useNavigate();

  useEffect(() => {
    return () => {
      clearHandler();
    };
  }, []);

  useEffect(() => {
    if (!user?.branchId) return;
    setBranchId(Number(user.branchId));
  }, [user?.branchId]);

  useEffect(() => {
    if (!isSU(user) && !isAdmin(user)) {
      setBranchId(user?.branchId);
    }
    getContracts();
  }, [getContracts, params.take, params.page, filters]);

  return (
    <Template>
      <div className='contracts-page'>
        <Title title='Контракты' className='contracts-page__title' />
        <Grid container spacing={2} className='users-page__filter'>
          <Grid item xs={2.4}>
            <TextField
              value={params.studentId}
              onChange={(e) => setStudentId(e.target.value)}
              size='small'
              placeholder='ID студента'
              type='number'
            />
          </Grid>
          {(isSU(user) || isAdmin(user)) && (
            <Grid item xs={2.4}>
              <FormControl fullWidth size='small'>
                <InputLabel variant='outlined'>Выберите филиал</InputLabel>
                <Select
                  label={'Выберите филиал'}
                  value={params.branchId}
                  onChange={(e) => setBranchId(e.target.value)}
                  size='small'
                >
                  {branches?.map((i) => (
                    <MenuItem key={i.id} value={i.id}>
                      {i.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={2.4}>
            <FormControl fullWidth size='small'>
              <InputLabel>Успешно окончен</InputLabel>
              <Select
                label={'Успешно окончен'}
                value={params.finished}
                onChange={(e) => setFinished(e.target.value)}
                size='small'
              >
                <MenuItem value={'true'}>Да</MenuItem>
                <MenuItem value={'false'}>Нет</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2.4}>
            <FormControl fullWidth size='small'>
              <InputLabel>Язык</InputLabel>
              <Select
                label={'Язык'}
                value={params.lang}
                onChange={(e) => setLang(e.target.value)}
                size='small'
              >
                <MenuItem value={'RU'}>RU</MenuItem>
                <MenuItem value={'UZ'}>UZ</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2.4}>
            <FormControl fullWidth size='small'>
              <InputLabel>Пол инструктора</InputLabel>
              <Select
                label={'Пол инструктора'}
                value={params.instructorGender}
                onChange={(e) => setInstructorGender(e.target.value)}
                size='small'
              >
                {genders.map((i) => (
                  <MenuItem key={i.value} value={i.value}>
                    {i.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2.4}>
            <FormControl fullWidth size='small'>
              <InputLabel>Статус</InputLabel>
              <Select
                label={'Статус'}
                value={params.status}
                onChange={(e) => setStatus(e.target.value)}
                size='small'
              >
                {contractStatuses.map((i) => (
                  <MenuItem key={i.value} value={i.value}>
                    {i.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2.4}>
            <FormControl fullWidth size='small'>
              <InputLabel>Тариф</InputLabel>
              <Select
                label={'Тариф'}
                value={params.tariffId}
                onChange={(e) => setTariffId(e.target.value)}
                size='small'
              >
                {tariffs.map((i) => (
                  <MenuItem key={i.id} value={i.id}>
                    {i.titleRu}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2.4}>
            <TextField
              value={params.operatorId}
              onChange={(e) => setOperatorId(e.target.value)}
              size='small'
              placeholder='ID оператора'
              type='number'
            />
          </Grid>
          <Grid item xs={2.4}>
            <FormControl fullWidth size='small'>
              <InputLabel variant='outlined'>
                Реферальный пользователь
              </InputLabel>
              <Select
                label={'Реферальный пользователь'}
                value={params.referrals || ''}
                onChange={(e) => setRefferals(e.target.value)}
                size='small'
              >
                {referrals.map((i) => (
                  <MenuItem key={i.value} value={i.value}>
                    {i.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2.4}>
            <FormControl fullWidth size='small'>
              <InputLabel variant='outlined'>
                Подтвержденный реферальнный пользователь
              </InputLabel>
              <Select
                label={'Подтвержденный реферальнный пользователь'}
                value={params.refChecked || ''}
                onChange={(e) => setRefChecked(e.target.value)}
                size='small'
              >
                {confrimReferrals?.map((i) => (
                  <MenuItem key={i.value} value={i.value}>
                    {i.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2.4}>
            <div className='row'>
              <Button
                className='users-page__button'
                color='primary'
                size='small'
                onClick={searchHandler}
              >
                Найти
              </Button>
            </div>
          </Grid>
          <Grid item xs={2.4}>
            <div className='row'>
              <Button
                className='users-page__button'
                color='error'
                size='small'
                onClick={clearHandler}
              >
                Очистить
              </Button>
            </div>
          </Grid>
        </Grid>
        {!!contracts.length && (
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Филиал</TableCell>
                  <TableCell>Тариф</TableCell>
                  <TableCell>Номер контракта</TableCell>
                  <TableCell>Ф.И.О. студента</TableCell>
                  <TableCell>Статус</TableCell>
                  <TableCell>Язык обучения</TableCell>
                  <TableCell>Пол инструктора</TableCell>
                  <TableCell>Дата создания</TableCell>
                  {(user?.role === 'SU' || user?.role === 'ADMIN') && (
                    <TableCell>Проверен</TableCell>
                  )}
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!!contracts?.length &&
                  contracts.map((i) => (
                    <TableRow key={i.id}>
                      <TableCell>{i.id}</TableCell>
                      <TableCell>{i.branch?.name}</TableCell>
                      <TableCell>{i.tariff?.titleRu}</TableCell>
                      <TableCell>{i.contractNumber}</TableCell>
                      <TableCell>
                        {i.student.lastName} {i.student.firstName}{' '}
                        {i.student.middleName}
                      </TableCell>
                      <TableCell>
                        <Statuses status={i.status} />
                      </TableCell>
                      <TableCell>{i.lang}</TableCell>
                      <TableCell>{i.instructorGender}</TableCell>
                      <TableCell>
                        {moment(i.createdAt).format('DD.MM.YYYY')}
                      </TableCell>
                      {(user?.role === 'SU' || user?.role === 'ADMIN') && (
                        <TableCell>
                          {i.refChecked ? (
                            <CheckIcon
                              sx={{ fontSize: 20, color: 'green' }}
                              fontSize='large'
                              className='contracts-page__icon'
                            />
                          ) : (
                            <CloseIcon
                              sx={{ fontSize: 20, color: '#ff0000' }}
                              fontSize='large'
                              className='contracts-page__icon'
                            />
                          )}
                        </TableCell>
                      )}
                      <TableCell>
                        <Link to={`/contracts/${i.id}`} className='go-to'>
                          <ArrowForwardIcon />
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Paper>
        )}
        {!!totalCount && (
          <TablePagination
            count={totalCount}
            rowsPerPageOptions={[10, 25, 50]}
            component='div'
            rowsPerPage={params.take}
            labelRowsPerPage='Количество на странице'
            page={params.page}
            onRowsPerPageChange={(e) => setTake(Number(e.target.value))}
            onPageChange={(e, newPage) => setPage(newPage)}
          />
        )}
      </div>
    </Template>
  );
});

export default ContractsPage;
