import { studentsServices } from "api/services"
import { IResStudent } from "api/services/students/types"
import { makeAutoObservable } from "mobx"



class StudentTimelineStore {
  timeline = [] as any
  examType = '' 
  result = {}
  student = {} as IResStudent

  constructor(){
    makeAutoObservable(this)
  }
  setExamType = (e: string) => {
    this.examType = e
  }
  setResult = (e: any) => {
    this.result = e
  }
  getTimeline = async (id: number) => {
    const res = await studentsServices.getStudentTimeline(id).then((res) => res.data)
    this.timeline = res.view
    this.student = res.student
  }
}

export default new StudentTimelineStore