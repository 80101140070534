import { makeAutoObservable } from "mobx";


class CreateRefPageStore {
  lastName = ''
  firstName = ''
  middleName = ''
  percent = ''
  loading = false
  hasError = false

  setLastName = (e: string) => {
    this.lastName = e
  }
  setFirstName = (e: string) => {
    this.firstName = e
  }
  setMiddleName = (e: string) => {
    this.middleName = e
  }
  setPercent = (e: any) => {
    this.percent = e
  }
  setLoading = (e: boolean) => {
    this.loading = e
  }
  setHasError = (e: any) => {
    this.hasError = e
  }

  clearFields = () => {
    this.lastName = ''
    this.firstName = ''
    this.middleName = ''
    this.percent = ''
    this.loading = false
    this.hasError = false
  }


  constructor (){
    makeAutoObservable(this)
  }
}

export default new CreateRefPageStore