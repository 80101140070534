import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import Title from 'components/Title/Title';
import Template from 'layout/Template/Template';
import StudentContractsPageStore from './StudentContractsPageStore';
import BranchesPageStore from 'pages/BranchesPage/BranchesPageStore';
import TariffsPageStore from 'pages/TariffsPage/TariffsPageStore';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import './StudentContractsPage.sass';

const StudentContractsPage: React.FC = observer(() => {
  const {
    getStudentContracts,
    studentContracts,
    params,
    totalCount,
    setStudentId,
    setTake,
    setSkip,
    getStudent,
    student,
    setStudent,
    setStudentContracts,
  } = StudentContractsPageStore;
  const { tariffs } = TariffsPageStore;
  const { branches } = BranchesPageStore;
  const { studentId } = useParams<{ studentId: string }>();
  const history = useNavigate();

  useEffect(() => {
    if (studentId) {
      setStudentId(Number(studentId));
      getStudentContracts();
      getStudent(Number(studentId));
    }
  }, [getStudentContracts, studentId, params.take, params.skip]);

  useEffect(() => {
    return () => {
      setStudent(undefined);
      setStudentContracts([]);
    };
  }, []);

  const goToUpdate = (id: number) => {
    history(`/contracts/${id}`);
  };

  return (
    <Template>
      <div className='student-contracts-page'>
        <div className='student-contracts-page__top'>
          <ArrowBackIcon onClick={() => history(-1)} />
          <Title
            title={
              !!student
                ? `Контракты студента (${student?.lastName} ${student?.firstName})`
                : ''
            }
            className='student-contracts-page__title'
          />
        </div>
        <Paper>
          <Table>
            <TableHead>
              <TableRow style={{ pointerEvents: 'none' }}>
                <TableCell>#</TableCell>
                <TableCell>Филиал</TableCell>
                <TableCell>Тариф</TableCell>
                <TableCell>Язык обучения</TableCell>
                <TableCell>Пол инструктора</TableCell>
                <TableCell>Дата создания</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {studentContracts.map((i, index) => (
                <TableRow key={i.id} onClick={() => goToUpdate(i.id)}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    {branches?.find((ii) => ii.id === i.branchId)?.name}
                  </TableCell>
                  <TableCell>
                    {tariffs?.find((ii) => ii.id === i.tariffId)?.titleRu}
                  </TableCell>
                  <TableCell>{i.lang}</TableCell>
                  <TableCell>{i.instructorGender}</TableCell>
                  <TableCell>
                    {moment(i.createdAt).format('DD.MM.YYYY')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
        {totalCount > params.take && (
          <TablePagination
            count={totalCount}
            rowsPerPageOptions={[10, 25, 50]}
            component='div'
            rowsPerPage={params.take}
            labelRowsPerPage='Количество на странице'
            page={params.skip}
            onRowsPerPageChange={(e) => setTake(Number(e.target.value))}
            onPageChange={(e, newPage) => setSkip(newPage)}
          />
        )}
      </div>
    </Template>
  );
});

export default StudentContractsPage;
