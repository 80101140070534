import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { courseServices, groupsServises } from 'api/services';
import { CustomSearchableSelect } from 'components/CustomSearchableSelect/CustomSearchableSelect';
import { NotificationStatus } from 'components/Notification/types';
import Statuses from 'components/Statuses/Statuses';
import Title from 'components/Title/Title';
import ru from 'date-fns/locale/ru';
import { isAdmin, isRegistrator, isSU } from 'helpers/checkRole';
import showNotification from 'helpers/showNotification';
import Template from 'layout/Template/Template';
import { observer } from 'mobx-react-lite';
import AuthPageStore from 'pages/AuthPage/AuthPageStore';
import BranchesPageStore from 'pages/BranchesPage/BranchesPageStore';
import React, { useEffect } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import './CreateGroupPage.sass';
import CreateGroupPageStore from './CreateGroupPageStore';

const CreateGroupPage: React.FC = observer(() => {
  registerLocale('ru', ru);
  const {
    lang,
    setLang,
    brId,
    setBranchId,
    teacherId,
    setTeacherId,
    loading,
    setLoading,
    hasError,
    setHasError,
    search,
    setSearch,
    users,
    clearFields,
    contracts,
    contractId,
    contractIds,
    setContractId,
    searchContracts,
    setSearchContracts,
    setData,
    lessons,
    lessonsChange,
    addContracts,
    deleteContracts,
    getContracts,
    courseId,
    setCourseId,
    courses,
    setCourses,
    searchCourse,
    setSearchCourse,
    getCourseById,
    getUsers,
  } = CreateGroupPageStore;
  const { branches } = BranchesPageStore;
  const { user } = AuthPageStore;
  const history = useNavigate();

  useEffect(() => {
    if (user?.branchId) {
      setBranchId(user?.branchId);
    }
  }, [user?.branchId, setBranchId]);

  useEffect(() => {
    getCourses('');
  }, []);

  useEffect(() => {
    if(!brId) return
    getUsers('');
  }, [brId, getUsers]);

  const getCourses = async (value: any) => {
    const params = {
      skip: 0,
      take: 15,
      search: value,
      type: 'THEORY',
    };
    const res = await courseServices.getCourses(params).then((res) => res.data);
    setCourses(
      res.data.map((i) => ({
        name: `${i.titleRu}`,
        value: i.id,
      }))
    );
  };

  useEffect(() => {
    return () => {
      clearFields();
    };
  }, []);

  useEffect(() => {
    getCourseById();
  }, [courseId, getCourseById]);

  const createGroup = async (e: React.FormEvent) => {
    e.preventDefault();
    let validation = true;

    if (!brId || !lang || !courseId) {
      validation = false;
    }

    if (!validation) {
      setHasError(true);
      return;
    }

    const requestData = {
      lang: lang,
      teacherId: teacherId ? Number(teacherId) : undefined,
      branchId: Number(brId),
      contractIds: !!contractIds.length
        ? contractIds?.map((i: any) => i.id)
        : [],
      lessons: !!lessons.length
        ? lessons?.map((i: any) => {
            if (!i.value) return {};
            return {
              datetime: new Date(i.value).toISOString(),
            };
          })
        : [],
      courseId: Number(courseId),
    };
    try {
      setLoading(true);
      await groupsServises.createGroup(requestData).then((res) => res.data);
      showNotification('Группа создана', NotificationStatus.SUCCESS);
      clearFields();
      history(`/groups`);
    } catch (e: any) {
      if(e?.response?.data?.message === 'Course ID'){
        showNotification('Выбран контракт по индивидуальному курсу')
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Template
      roleGuard={
        (user && isSU(user)) ||
        (user && isAdmin(user)) ||
        (user && isRegistrator(user))
      }
    >
      <div className='create-group-page'>
        <Title title='Создать группу' className='create-group-page__title' />
        <form onSubmit={createGroup}>
          {((user && isSU(user)) || (user && isAdmin(user))) && (
            <FormControl fullWidth className='create-group-page__field'>
              <InputLabel>Филиал</InputLabel>
              <Select
                label='Филиал'
                value={brId}
                onChange={(e) => setBranchId(e.target.value)}
                error={hasError && !brId}
              >
                {branches?.map((i) => (
                  <MenuItem key={i.id} value={i.id}>
                    {i.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <FormControl fullWidth className='create-group-page__field'>
            <InputLabel>Язык</InputLabel>
            <Select
              label='Язык'
              value={lang}
              onChange={(e) => setLang(e.target.value)}
              error={hasError && !lang.trim()}
            >
              <MenuItem value={'RU'}>RU</MenuItem>
              <MenuItem value={'UZ'}>UZ</MenuItem>
            </Select>
          </FormControl>
          {brId && (
            <div className='create-group-page__field'>
              <CustomSearchableSelect
                items={[{ name: 'Нет', value: null }, ...users]}
                setSelectItem={setTeacherId}
                value={teacherId}
                setLoading={() => {}}
                loading={false}
                setSearchValue={setSearch}
                searchValue={search}
                setValue={getUsers}
                placeholder='Выбрать учителя'
              />
            </div>
          )}
          <div className='create-group-page__field'>
            <CustomSearchableSelect
              items={[{ name: 'Нет', value: null }, ...courses]}
              setSelectItem={setCourseId}
              value={courseId}
              setLoading={() => {}}
              loading={false}
              setSearchValue={setSearchCourse}
              searchValue={searchCourse}
              setValue={getCourses}
              placeholder='Выбрать курс'
            />
          </div>
          {!!brId && (
            <>
              <div className='create-group-page__hr'>
                <div className='create-group-page__label'>
                  Добавить контракт
                </div>
              </div>
              <div className='create-group-page__field create-group-page__field--flex'>
                <CustomSearchableSelect
                  items={[{ name: 'Нет', value: null }, ...contracts]}
                  setSelectItem={setContractId}
                  value={contractId}
                  setLoading={() => {}}
                  loading={false}
                  setSearchValue={setSearchContracts}
                  searchValue={searchContracts}
                  setValue={getContracts}
                  placeholder='Введите номер контракта'
                  error={hasError && !contractId}
                  setData={setData}
                />
                <Button
                  size='medium'
                  color='success'
                  startIcon={<AddCircleIcon />}
                  className='create-group-page__add'
                  onClick={addContracts}
                ></Button>
              </div>
              {!!contractIds.length && (
                <Paper className='create-group-page__contracts'>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell>Номер контаркта / Ф.И.О.</TableCell>
                        <TableCell>Тариф</TableCell>
                        <TableCell>Статус</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {contractIds.map((i: any, index: number) => (
                        <TableRow key={index}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{i.name}</TableCell>
                          <TableCell>{i.tariff}</TableCell>
                          <TableCell>
                            <Statuses status={i.status} />
                          </TableCell>
                          <TableCell>
                            <div className='create-group-page__flex'>
                              <IconButton
                                aria-label='delete'
                                size='medium'
                                color='error'
                                onClick={() => deleteContracts(i.id)}
                              >
                                <DeleteForeverIcon />
                              </IconButton>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>
              )}
            </>
          )}
          {!!lessons.length && (
            <>
              <div className='create-group-page__hr'>
                <div className='create-group-page__label'>Список уроков</div>
                {/* <Button
              color='success'
              className='create-group-page__add'
              startIcon={<AddCircleIcon />}
              onClick={addLessons}
              size='small'
            ></Button> */}
              </div>
              {lessons?.map((i: any, index: number) => (
                <div
                  className='create-group-page__field create-group-page__field--flex'
                  key={index}
                >
                  <DatePicker
                    selected={
                      !!lessons[index].value
                        ? new Date(lessons[index].value)
                        : undefined
                    }
                    timeInputLabel='Time:'
                    autoComplete='off'
                    dateFormat='dd.MM.yyyy HH:mm'
                    timeIntervals={20}
                    showTimeInput
                    onChange={(e: any) => lessonsChange(e, index)}
                    locale={ru}
                    customInput={<TextField autoComplete='off' />}
                  />
                  {/* <Button
                  size='medium'
                  color='error'
                  className='create-group-page__delete'
                  onClick={() => deleteLessons(index)}
                >
                  -
                </Button> */}
                </div>
              ))}
            </>
          )}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                type='submit'
                color='primary'
                size='medium'
                disabled={loading}
              >
                Сохранить
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Link to='/groups'>
                <Button color='inherit'>Назад</Button>
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Template>
  );
});

export default CreateGroupPage;
