export enum ROLE {
  SU = 'SU',
  ADMIN = 'ADMIN',
  REGISTRATOR = 'REGISTRATOR',
  GARAGE = 'GARAGE',
  INSTRUCTOR = 'INSTRUCTOR',
  TEACHER = 'TEACHER',
  REFERRAL = 'REFERRAL',
  ACCOUNTANT = 'ACCOUNTANT',
  CASHIER = 'CASHIER',
  REGISTRATOR_WITH_CASHIER = 'REGISTRATOR_WITH_CASHIER'

}

export interface IRole {
  value: ROLE;
  title: string;
}

export const roles: IRole[] = [
  {
    value: ROLE.SU,
    title: 'Суперюзер',
  },
  {
    value: ROLE.ADMIN,
    title: 'Администратор',
  },
  {
    value: ROLE.REGISTRATOR,
    title: 'Регистратор',
  },
  {
    value: ROLE.GARAGE,
    title: 'Гараж',
  },
  {
    value: ROLE.INSTRUCTOR,
    title: 'Инструктор',
  },
  {
    value: ROLE.TEACHER,
    title: 'Преподаватель',
  },
  {
    value: ROLE.REFERRAL,
    title: 'Реферал'
  },
  {
    value: ROLE.ACCOUNTANT,
    title: 'Бухгалтер'
  },
  {
    value: ROLE.CASHIER,
    title: 'Кассир'
  },
  {
    value: ROLE.REGISTRATOR_WITH_CASHIER,
    title: 'Регистратор и кассир'
  }
];