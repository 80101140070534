import { AxiosPromise } from 'axios';
import { IResCategory } from '../categories/types';

export interface ICourse {
  titleRu: string;
  titleUz: string;
  isActive: boolean;
  type: CourseType;
  categoryId?: number;
  individual: boolean;
  duration: number;
}

export interface ICourseParam {
  search?: string;
  isActive?: string;
  type?: any;
  individual?: string;
  categoryId?: number | string;
  take: number;
  skip: number;
}

export interface IResCourse extends ICourse {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  category?: IResCategory;
  tariffCourses: {
    id: number;
    count: number;
    tariffId: number;
    courseId: number;
    createdAt: number;
    updatedAt: number;
  }[];
}

export interface IGetCourse {
  (data: ICourseParam): AxiosPromise<{ data: IResCourse[]; count: number }>;
}

export interface IGetCourseById {
  (id: number): AxiosPromise<IResCourse>;
}

export interface ICreateCourse {
  (data: ICourse): AxiosPromise;
}

export interface IUpdateCourse {
  (id: number, data: ICourse): AxiosPromise;
}

export interface IDeleteCourse {
  (id: number): AxiosPromise;
}

export enum CourseType {
  'THEORY' = 'THEORY',
  'PILOT_RUN' = 'PILOT_RUN',
  'RUN' = 'RUN',
}
