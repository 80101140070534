import { refsServices } from "api/services";
import { IResRef } from "api/services/refs/types";
import { makeAutoObservable } from "mobx";


class RefsPageStore {
  data = [] as IResRef[];
  totalCount = 0;
  params = {
    lastName: '',
    firstName: '',
    middleName: '',
    page: 0,
    take: 25,
  };
  filters = {
    lastName: '',
    firstName: '',
    middleName: '',
    page: 0,
    take: 25,
  };

  constructor() {
    makeAutoObservable(this);
  }

  setLastName = (e: any) => {
    this.params.lastName = e;
  };
  setFirstName = (e: any) => {
    this.params.firstName = e;
  };
  setMiddleName = (e: any) => {
    this.params.middleName = e;
  };
  setTake = (e: any) => {
    this.params.take = e;
  };
  setPage = (e: any) => {
    this.params.page = e;
  };

  searchHandler = () => {
    this.filters = {
      firstName: this.params.firstName,
      lastName: this.params.lastName,
      middleName: this.params.middleName,
      page: this.params.page,
      take: this.params.take,
    };
  };

  clearHandler = () => {
    this.filters = {
      lastName: '',
      firstName: '',
      middleName: '',
      page: 0,
      take: 25,
    };
    this.setLastName('');
    this.setFirstName('');
    this.setMiddleName('');
    this.setPage(0);
  };

  getRefs = async () => {
    const params = {
      firstName: this.params.firstName,
      lastName: this.params.lastName,
      middleName: this.params.middleName,
      take: this.params.take,
      skip: this.params.take * this.params.page,
    };
    try {
      const res = await refsServices.getRefs(params).then((res) => res.data);
      this.data = res.data;
      this.totalCount = res.count;
    } catch (e) {}
  };

}

export default new RefsPageStore