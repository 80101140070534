import { makeAutoObservable } from 'mobx';
import clone from 'lodash/clone';
import cloneDeep from 'lodash/cloneDeep';
import uniq from 'lodash/uniq';
import {
  attendanceServices,
  groupsServises,
  lessonsServices,
} from 'api/services';
import { IResContract } from 'api/services/contracts/types';
import { IResLesson } from 'api/services/lessons/types';
import { IResAttendance } from 'api/services/attendance/types';
import showNotification from 'helpers/showNotification';

class UpdateLessonPageStore {
  loading = false;
  lessons = [] as IResLesson[];
  contracts = [] as IResContract[];
  contractId = undefined;
  groupId = '';
  lessonType = '' as any;
  attendanceLesson = [] as IResAttendance[] as any;

  constructor() {
    makeAutoObservable(this);
  }

  setContractId = (e: any) => {
    this.contractId = e;
  };
  clearFields = () => {
    this.loading = false;
    this.lessons = [] as IResLesson[];
    this.contracts = [] as IResContract[];
    this.contractId = undefined;
    this.lessonType = '';
  };

  getLessonById = async (id: number) => {
    try {
      const res = await lessonsServices
        .getLessonById(id)
        .then((res) => res.data);
      this.lessonType = res?.type;
      if (!res?.group?.id) return;
      this.groupId = res.groupId ? res.groupId?.toString() : '';
      this.getGroupById(res.group.id, res.type);
    } catch {}
  };

  getGroupById = async (id: number, type?: ILessonType) => {
    const res = await groupsServises.getGroupById(id).then((res) => res.data);
    res?.contracts?.map((i) => {
      if (!!i.attendance?.length) {
        i.attendance.map(
          (i) => (this.attendanceLesson = [...this.attendanceLesson, i.lesson])
        );
      }
    });
    this.lessons = uniq(
      [
        ...res.lessons.filter((i) => i.type === type),
        ...this.attendanceLesson,
      ].sort((a, b) => +new Date(a.datetime) - +new Date(b.datetime))
    );
    this.contracts = !!res?.contracts?.length ? res.contracts : [];
  };

  attendanceHandler = (
    value?: boolean | null,
    lessonId?: number,
    contractId?: number,
    attendanceId?: number
  ) => {
    if (value === undefined && lessonId && contractId) {
      this.createAttendance(true, lessonId, contractId);
    }
    if (value === false && attendanceId && contractId) {
      this.deleteAttendance(attendanceId, contractId);
    }
    if (value === true && attendanceId) {
      this.updateAttendance(attendanceId, false);
    }
  };
  createAttendance = async (
    value: boolean,
    lessonId: number,
    contractId: number
  ) => {
    const requestData = {
      lessonId,
      contractId,
      value,
    };
    try {
      this.loading = true;
      const res = await attendanceServices
        .createAttendance(requestData)
        .then((res) => res.data);
      let cloneContracts = clone(this.contracts);
      cloneContracts
        ?.find((i) => i.id === res.contractId)
        ?.attendance?.push({
          contractId: res.contractId,
          id: res.id,
          lessonId: res.lessonId,
          value: true,
        });
    } catch (e: any) {
      if (e?.response?.data?.message === 'Contract status NEW') {
        showNotification('Контракт не оплачен');
      }
      if (e?.response?.data?.message === 'Contract status FINISHED') {
        showNotification('Контракт окончен');
      }
      if (e?.response?.data?.message === 'Contract is not active') {
        showNotification('Контракт не активен');
      }
    } finally {
      this.loading = false;
    }
  };
  updateAttendance = async (id: number, value: boolean) => {
    const requestData = {
      value,
    };
    try {
      this.loading = true;
      const res = await attendanceServices
        .updateAttendance(id, requestData)
        .then((res) => res.data);
      let cloneContracts = clone(this.contracts);
      let findAttendance = cloneContracts
        ?.find((i) => i.id === res.contractId)
        ?.attendance?.find((ii) => ii.id === res.id);
      let findIndex = cloneContracts
        ?.find((i) => i.id === res.contractId)
        ?.attendance?.findIndex((ii) => ii.id === res.id);
      let deep = cloneDeep(findAttendance);
      cloneContracts?.map((i) => {
        if (i.id === res.contractId && findIndex !== undefined && deep) {
          deep.value = res.value;
          i?.attendance?.splice(findIndex, 1, deep);
        }
        return i;
      });
    } catch (e: any) {
      if (e?.response?.data?.message === 'Contract status NEW') {
        showNotification('Контракт не оплачен');
      }
      if (e?.response?.data?.message === 'Contract status FINISHED') {
        showNotification('Контракт окончен');
      }
      if (e?.response?.data?.message === 'Contract is not active') {
        showNotification('Контракт не активен');
      }
    } finally {
      this.loading = false;
    }
  };
  deleteAttendance = async (id: number, contractId: number) => {
    try {
      this.loading = true;
      await attendanceServices.deleteAttendance(id).then((res) => res.data);
      let cloneContracts = clone(this.contracts);
      cloneContracts?.map((i) => {
        if (i.id === contractId) {
          let deep = cloneDeep(i);
          i.attendance = deep?.attendance?.filter((ii) => ii.id !== id);
        }
        return i;
      });
    } catch (e: any) {
      if (e?.response?.data?.message === 'Contract status NEW') {
        showNotification('Контракт не оплачен');
      }
      if (e?.response?.data?.message === 'Contract status FINISHED') {
        showNotification('Контракт окончен');
      }
      if (e?.response?.data?.message === 'Contract is not active') {
        showNotification('Контракт не активен');
      }
    } finally {
      this.loading = false;
    }
  };
}

export default new UpdateLessonPageStore();
