import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { roles } from 'refs/roles';
import { genders } from 'refs/genders';
import UsersStore from './UsersPageStore';
import Title from 'components/Title/Title';
import Template from 'layout/Template/Template';
import BranchesPageStore from 'pages/BranchesPage/BranchesPageStore';
import AuthPageStore from 'pages/AuthPage/AuthPageStore';
import { isAdmin, isSU } from 'helpers/checkRole';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/CancelPresentation';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import './UsersPage.sass';

const UsersPage: React.FC = observer(() => {
  const {
    params,
    totalCount,
    data,
    filters,
    getUsers,
    searchHandler,
    clearHandler,
    setRole,
    setBranchId,
    setSearch,
    setGender,
    setIsActive,
    setTake,
    setPage,
  } = UsersStore;
  const { branches } = BranchesPageStore;
  const { user } = AuthPageStore;

  useEffect(() => {
    return (() => {
      clearHandler()
    })
  }, [clearHandler])

  useEffect(() => {
    getUsers();
  }, [getUsers, params.take, params.page, filters]);

  return (
    <Template
      btnUrl='/users/create'
      roleGuard={(user && isSU(user)) || (user && isAdmin(user))}
    >
      <div className='users-page'>
        <Title title='Пользователи' className='users-page__title' />
        <Grid container spacing={2} className='users-page__filter'>
          <Grid item xs={4}>
            {!!branches.length && (
              <FormControl fullWidth size='small'>
                <InputLabel variant='outlined'>Выберите филиал</InputLabel>
                <Select
                  label={'Выберите филиал'}
                  value={params.branchId}
                  onChange={(e) => setBranchId(e.target.value)}
                  size='small'
                >
                  {branches?.map((i) => (
                    <MenuItem key={i.id} value={i.id}>
                      {i.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth size='small'>
              <InputLabel>Выберите роль</InputLabel>
              <Select
                label={'Выберите роль'}
                value={params.role}
                onChange={(e) => setRole(e.target.value)}
                size='small'
              >
                {roles?.filter((ii) => ii.value !== 'SU')?.map((i) => (
                  <MenuItem key={i.value} value={i.value}>
                    {i.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth size='small'>
              <InputLabel>Выберите пол</InputLabel>
              <Select
                label={'Выберите пол'}
                value={params.gender}
                onChange={(e) => setGender(e.target.value)}
                size='small'
              >
                {genders?.map((i) => (
                  <MenuItem key={i.value} value={i.value}>
                    {i.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth size='small'>
              <InputLabel>Выберите активность</InputLabel>
              <Select
                label={'Выберите активность'}
                value={params.isActive}
                onChange={(e) => setIsActive(e.target.value)}
                size='small'
              >
                <MenuItem value={'true'}>Активен</MenuItem>
                <MenuItem value={'false'}>Неактивен</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <TextField
              value={params.search}
              onChange={(e) => setSearch(e.target.value)}
              size='small'
              placeholder='Поиск'
            />
          </Grid>
          <Grid item xs={4}>
            <div className='row'>
              <Button
                className='users-page__button'
                color='primary'
                size='small'
                onClick={searchHandler}
              >
                Найти
              </Button>
              <Button
                className='users-page__button'
                color='error'
                size='small'
                onClick={clearHandler}
              >
                Очистить
              </Button>
            </div>
          </Grid>
        </Grid>
        {!!data.length && (
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Ф.И.О.</TableCell>
                  <TableCell>Логин</TableCell>
                  <TableCell>Роль</TableCell>
                  <TableCell>Пол</TableCell>
                  <TableCell>Категория</TableCell>
                  <TableCell>Филиал</TableCell>
                  <TableCell>Активность</TableCell>
                  <TableCell>Дата изменения</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((i, index) => (
                  <TableRow key={i.id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      {i.firstName} {i.lastName} {i.middleName}
                    </TableCell>
                    <TableCell>{i.login}</TableCell>
                    <TableCell>{i.role}</TableCell>
                    <TableCell>
                      {i.gender === 'MALE' ? 'Мужчина' : 'Женщина'}
                    </TableCell>
                    <TableCell>
                      {i?.categories?.map((ii) => (
                        <span className='users-page__categories' key={ii.id}>
                          {ii.name},{' '}
                        </span>
                      ))}
                    </TableCell>
                    <TableCell>
                      {branches?.find((ii) => ii.id === i.branchId)?.name}
                    </TableCell>
                    <TableCell>
                      {i.isActive ? (
                        <CheckIcon
                          sx={{ fontSize: 20, color: 'green' }}
                          className='users-page__checked-btn'
                          fontSize='large'
                        />
                      ) : (
                        <CloseIcon
                          sx={{ fontSize: 20, color: '#ff0000' }}
                          fontSize='large'
                          className='users-page__statues-btn'
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      {moment(i.updatedAt).format('DD.MM.YYYY')}
                    </TableCell>
                    <TableCell>
                      <Link to={`/users/${i.id}`} className="go-to">
                        <ArrowForwardIcon />
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        )}
        {!!totalCount && (
          <TablePagination
            count={totalCount}
            rowsPerPageOptions={[10, 25, 50]}
            component='div'
            rowsPerPage={params.take}
            labelRowsPerPage='Количество на странице'
            page={params.page}
            onRowsPerPageChange={(e) => setTake(Number(e.target.value))}
            onPageChange={(e, newPage) => setPage(newPage)}
          />
        )}
      </div>
    </Template>
  );
});

export default UsersPage;
