import { makeAutoObservable } from 'mobx';
import { NotificationStatus } from './types';
import { v1 as uuidv1 } from 'uuid';

class NotificationStore {
  message = '';
  status = NotificationStatus.INFO;
  id = '';
  isShow = false;

  constructor() {
    makeAutoObservable(this);
  }

  showNotification = (message: string, status: NotificationStatus) => {
    const id = uuidv1();

    this.message = message;
    this.status = status;
    this.id = id;
    this.isShow = true;
    setTimeout(() => {
      if (id === this.id) {
        this.hideNotification();
      }
    }, 4000);
  };

  hideNotification = () => {
    this.isShow = false;
    this.message = '';
    this.status = NotificationStatus.INFO;
  };
}

export default new NotificationStore();
