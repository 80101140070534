import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from '@mui/material';
import { CourseType } from 'api/services/courses/types';
import { IResLesson } from 'api/services/lessons/types';
import { ReactComponent as AutoIcon } from 'assets/images/auto.svg';
import { ReactComponent as NotCheckedIcon } from 'assets/images/not-checked.svg';
import { ReactComponent as SuccessIcon } from 'assets/images/success.svg';
import { ReactComponent as TheoryIcon } from 'assets/images/theory.svg';
import { Calendar } from 'components/Calendar/Calendar';
// import { CustomSearchableSelect } from 'components/CustomSearchableSelect/CustomSearchableSelect';
import { Lessons } from 'components/Lessons/Lessons';
import { NotificationStatus } from 'components/Notification/types';
import Title from 'components/Title/Title';
import ru from 'date-fns/locale/ru';
import { isAdmin, isGarage, isInstructor, isSU } from 'helpers/checkRole';
import { LessonType, isIndividual } from 'helpers/lessonType';
import showNotification from 'helpers/showNotification';
import Template from 'layout/Template/Template';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import AuthPageStore from 'pages/AuthPage/AuthPageStore';
import BranchesPageStore from 'pages/BranchesPage/BranchesPageStore';
import ContractsPageStore from 'pages/ContractsPage/ContractsPageStore';
import GroupsPageStore from 'pages/GroupsPage/GroupsPageStore';
import UsersPageStore from 'pages/UsersPage/UsersPageStore';
import { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { Link, useNavigate } from 'react-router-dom';
import { ContractStatus } from 'refs/contractStatuses';
// import { coursesTypes } from 'refs/courseTypes';
import cn from 'classnames';
import { ROLE } from 'refs/roles';
import EditIcon from '@mui/icons-material/Edit';
import './LessonsPage.sass';
import LessonsPageStore from './LessonsPageStore';

export const LessonsPage = observer(() => {
  const {
    lessons,
    getLessons,
    setParams,
    params,
    showModal,
    setShowModal,
    groupId,
    setGroupId,
    contractId,
    // searchContract,
    setSearchContract,
    setContractId,
    setSearchGroup,
    // searchGroup,
    hasError,
    datetime,
    teacherId,
    setTeacherId,
    setDatetime,
    // createLesson,
    lessonType,
    setLessonType,
    clearFields,
    updateLesson,
    lesson,
    setLesson,
    // deleteLesson,
    getFavoriteTeachers,
    favoriteTeachers,
    lessonsWithoutDatetime,
    getLessonsWithoutDateTime,
    getLessonsCount,
    lessonsCount,
  } = LessonsPageStore;
  const { branches } = BranchesPageStore;
  const { user } = AuthPageStore;
  const { setBranchId: setGroupsBranchId } = GroupsPageStore;
  const { data, getUsers, setRole, setBranchId } = UsersPageStore;
  const {
    contracts,
    getContracts,
    setBranchId: setContractBrachId,
  } = ContractsPageStore;

  const history = useNavigate();

  const getData = () => {
    if (!!params?.branchId && !isAdmin(user)) {
      setRole(
        lesson?.course?.type === CourseType.THEORY
          ? ROLE.TEACHER
          : ROLE.INSTRUCTOR
      );
      if (isIndividual(lessonType)) {
        setGroupId(undefined);
      } else {
        setContractId(undefined);
      }
      setBranchId(params.branchId);
      setGroupsBranchId(params.branchId);
      setContractBrachId(params.branchId);
      getUsers();
      if (user?.role === ROLE.GARAGE) {
        setLessonType(LessonType.INDIVIDUAL);
        return;
      }
    }
  };

  useEffect(() => {
    getData();
  }, [params?.branchId, lessonType, lesson?.course?.type]);

  useEffect(() => {
    if (isIndividual(lessonType) && !contracts.length && !isGarage(user)) {
      getContracts();
    }
    !lesson && clearFields();
  }, [lessonType, getContracts, lesson, clearFields, contracts.length]);


  useEffect(() => {
    if (!params || !user) return;
    getLessons();
  }, [JSON.stringify(params), getLessons, user]);

  useEffect(() => {
    if (!isSU(user) && !params?.branchId) return;
    getLessonsWithoutDateTime();

  }, [
    JSON.stringify({ branch: params?.branchId }),
    getLessonsWithoutDateTime,
  ]);

  useEffect(() => {
    return () => {
      setBranchId(undefined);
      setGroupsBranchId('');
      setRole(undefined);
      setContractBrachId(undefined);
      setShowModal(false);
      setParams(undefined);
    };
  }, [
    setBranchId,
    setGroupsBranchId,
    setRole,
    setContractBrachId,
    setShowModal,
    setParams,
  ]);


  const getMonth = (data: { from: string; to: string }) => {
    setParams({ ...params, ...(!isSU(user) && user?.branchId && !params?.branchId ? { branchId: user.branchId } : {}), ...data });
  };

  const confirmShowModal = (value: boolean) => {
    setShowModal(value);
  };

  const closeModal = () => {
    clearFields();
    setLesson(undefined);
    setShowModal(false);
  };

  const showUpdateModal = (data: IResLesson) => {
    if (!params?.branchId) {
      showNotification('Выберите филиал', NotificationStatus.INFO);
      return;
    }
    setLesson(data);
    setTeacherId(data.teacherId ? data.teacherId : undefined);
    if (data.contractId) {
      setLessonType(LessonType.INDIVIDUAL);
      setSearchContract(`Контракт №${data.contractId}`);
      setContractId(data.contractId);
      getFavoriteTeachers();
    }
    if (data.group?.id) {
      setLessonType(LessonType.NOT_INDIVIDUAL);
      setSearchGroup(
        `Группа №${data.group?.id} от ${moment(data.group?.createdAt).format(
          'DD.MM.YYYY HH:mm'
        )}`
      );
    }
    setGroupId(data.group?.id);
    setShowModal(true);
  };

  return (
    <Template btnUrl='/courses/create'>
      <div className='lessons-page'>
        {showModal && (
          <div className='lessons-page__modal'>
            <div
              className='lessons-page__modal-overlay'
              onClick={closeModal}
            ></div>
            <div className='lessons-page__modal-in'>
              <Paper className='lessons-page__modal-container'>
                <div className='lessons-page__modal-top'>
                  <Title
                    title={
                      lesson?.groupId
                        ? `Группа №${lesson.groupId}`
                        : lesson?.contractId
                          ? `Контракт ${lesson.contract?.contractNumber}`
                          : ''
                    }
                    className='lessons-page__modal-edit-title'
                  />
                  {lesson && (
                    <div className='lessons-page__modal-info'>
                      {lesson?.course?.type === CourseType.THEORY && (
                        <div
                          data-title='Теория'
                          className='lessons-page__modal-info-icon'
                        >
                          <TheoryIcon />
                        </div>
                      )}
                      {(lesson?.course?.type === CourseType.RUN ||
                        lesson?.course?.type === CourseType.PILOT_RUN) && (
                          <div
                            data-title='Практика'
                            className='lessons-page__modal-info-icon'
                          >
                            <AutoIcon />
                          </div>
                        )}
                      {lesson?.contract?.status === ContractStatus.PAYED && (
                        <div
                          data-title='Оплачен'
                          className='lessons-page__modal-info-icon'
                        >
                          <SuccessIcon />
                        </div>
                      )}
                      {lesson?.contract?.status === ContractStatus.NEW && (
                        <div
                          data-title='Неоплачен'
                          className='lessons-page__modal-info-icon'
                        >
                          <NotCheckedIcon />
                        </div>
                      )}
                    </div>
                  )}
                  <Button
                    className='lessons-page__modal-edit'
                    endIcon={<EditIcon />}
                    size='small'
                    onClick={() =>
                      lesson?.contractId
                        ? history(`/contracts/${lesson.contractId}`)
                        : history(`/groups/${lesson?.groupId}`)
                    }
                  >
                    Изменить
                  </Button>
                </div>
                <CloseIcon
                  className='lessons-page__modal-close-icon'
                  onClick={closeModal}
                />
                {lesson?.contractId && (
                  <div className='lessons-page__modal-user'>
                    <span>ФИО: </span>
                    {`${lesson?.contract?.student?.lastName} ${lesson?.contract?.student?.firstName}`}
                  </div>
                )}
                <div className='lessons-page__modal-fields'>
                  {/* {!lesson && (
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel variant='outlined'>Тип занятий</InputLabel>
                        <Select
                          label={'Тип занятий'}
                          value={lessonType}
                          onChange={(e) =>
                            setLessonType(e.target.value as LessonType)
                          }
                        >
                          {lessonTypes?.map((i) => (
                            <MenuItem key={i.value} value={i.value}>
                              {i.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  {!lesson && (
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel variant='outlined'>Тип курса</InputLabel>
                        <Select
                          label={'Тип курса'}
                          value={courseType}
                          onChange={(e) =>
                            setCourseType(e.target.value as CourseType)
                          }
                        >
                          {coursesTypes?.map((i) => (
                            <MenuItem key={i.value} value={i.value}>
                              {i.title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  {!lesson &&
                    !!params?.branchId &&
                    !isIndividual(lessonType) && (
                      <Grid item xs={12}>
                        <CustomSearchableSelect
                          items={[
                            ...groups.map((i) => ({
                              ...i,
                              value: i.id,
                              name: `Группа №${i.id} от ${moment(
                                i.createdAt
                              ).format('DD.MM.YYYY HH:mm')}`,
                            })),
                          ]}
                          setSelectItem={setGroupId}
                          value={groupId}
                          setLoading={() => {}}
                          loading={false}
                          setSearchValue={setSearchGroup}
                          searchValue={searchGroup}
                          setValue={() => getGroups()}
                          placeholder='Выбор группы'
                          error={!groupId && hasError}
                        />
                      </Grid>
                    )}
                  {!lesson &&
                    !!params?.branchId &&
                    isIndividual(lessonType) && (
                      <Grid item xs={12}>
                        <CustomSearchableSelect
                          items={[
                            ...contracts.map((i) => ({
                              ...i,
                              name: `Контракт №${i.id}`,
                              value: i.id,
                            })),
                          ]}
                          setSelectItem={setContractId}
                          value={contractId}
                          setLoading={() => {}}
                          loading={false}
                          setSearchValue={setSearchContract}
                          searchValue={searchContract}
                          setValue={getContracts}
                          placeholder='Выберите контракт'
                          error={hasError && !contractId}
                        />
                      </Grid>
                    )} */}
                  {!!lesson && !isGarage(user) && (
                    <Grid item xs={12}>
                      <DatePicker
                        selected={datetime}
                        placeholderText='Укажите время'
                        dateFormat='dd.MM.yyyy HH:mm'
                        showTimeSelect
                        autoComplete='off'
                        timeCaption='Время'
                        timeIntervals={20}
                        onChange={(date) => {
                          setDatetime(date);
                        }}
                        timeFormat='HH:mm'
                        locale={ru}
                        customInput={<TextField autoComplete='off' />}
                      />
                    </Grid>
                  )}
                  {(!!groupId || !!contractId) &&
                    lesson?.course?.type === CourseType.THEORY && (
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <InputLabel variant='outlined'>
                            Выберите преподавателя
                          </InputLabel>
                          <Select
                            label={'Выберите преподавателя'}
                            value={teacherId || ''}
                            onChange={(e) =>
                              setTeacherId(Number(e.target.value))
                            }
                            error={hasError && !teacherId}
                          >
                            {data?.map((i) => (
                              <MenuItem key={i.id} value={i.id}>
                                {`${i.lastName} ${i.firstName}`}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    )}
                  {(!!groupId || !!contractId) &&
                    (lesson?.course?.type === CourseType.RUN ||
                      lesson?.course?.type === CourseType.PILOT_RUN) && (
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <InputLabel variant='outlined'>
                            Выберите инструктора
                          </InputLabel>
                          <Select
                            label={'Выберите инструктора'}
                            value={teacherId || ''}
                            onChange={(e) =>
                              setTeacherId(Number(e.target.value))
                            }
                            error={hasError && !teacherId}
                          >
                            {data?.map((i) => (
                              <MenuItem key={i.id} value={i.id}>
                                {`${i.lastName} ${i.firstName}`}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    )}
                  {!!lesson?.contractId &&
                    !!favoriteTeachers.length &&
                    lesson?.course?.type !== CourseType.THEORY && (
                      <Grid item xs={12}>
                        <div className='lessons-page__modal-teachers'>
                          {favoriteTeachers.map((i) => (
                            <div
                              className={cn(
                                'lessons-page__modal-teachers-item',
                                {
                                  'lessons-page__modal-teachers-item--selected':
                                    i.id === teacherId,
                                }
                              )}
                              key={i.id}
                              onClick={() => setTeacherId(i.id)}
                            >
                              {`${i.lastName || ''} ${i.firstName || ''}`}
                            </div>
                          ))}
                        </div>
                      </Grid>
                    )}
                  <Grid
                    container
                    item
                    xs={12}
                    spacing={2}
                    className='lessons-page__modal-buttons'
                  >
                    <Grid item xs={4}>
                      <Button size='small' color='inherit' onClick={closeModal}>
                        Отменить
                      </Button>
                    </Grid>
                    {!!lesson && (
                      <>
                        {/* <Grid item xs={3}>
                          <Button
                            size='small'
                            color='secondary'
                            onClick={deleteLesson}
                            disabled={!isAdmin(user) && !isSU(user)}
                          >
                            Удалить
                          </Button>
                        </Grid> */}
                        <Grid item xs={4}>
                          <Link
                            to={
                              lessonType === LessonType.INDIVIDUAL
                                ? `/lessons/${lesson.id}`
                                : `/lessons/${lesson.id}/group`
                            }
                          >
                            <Button size='small' color='success'>
                              Перейти к уроку
                            </Button>
                          </Link>
                        </Grid>
                      </>
                    )}
                    <Grid item xs={4}>
                      <Button
                        size='small'
                        // disabled={!isAdmin(user) && !isSU(user)}
                        onClick={updateLesson}
                      >
                        Сохранить
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </Paper>
            </div>
          </div>
        )}
        <Grid container spacing={2}>
          {(isSU(user) || isAdmin(user)) && (
            <Grid container spacing={2} item className='lessons-page__top'>
              {!!branches.length && (
                <Grid item xs={4}>
                  <FormControl fullWidth size='small'>
                    <InputLabel>Филиал</InputLabel>
                    <Select
                      label={'Филиал'}
                      value={params?.branchId || ''}
                      onChange={(e) =>
                        setParams({
                          ...params,
                          branchId: Number(e.target.value),
                        })
                      }
                      size='small'
                    >
                      {branches.map((i) => (
                        <MenuItem key={i.id} value={i.id}>
                          {i.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {!!data.length && !!params?.branchId && (
                <Grid item xs={4}>
                  <FormControl fullWidth size='small'>
                    <InputLabel>Преподаватель</InputLabel>
                    <Select
                      label={'Преподаватель'}
                      value={params?.userId || ''}
                      onChange={(e) =>
                        setParams({ ...params, userId: Number(e.target.value) })
                      }
                      size='small'
                    >
                      {data.map((i) => (
                        <MenuItem key={i.id} value={i.id}>
                          {`${i.lastName} ${i.firstName}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={2}>
                <Button size='small'>Очистить</Button>
              </Grid>
            </Grid>
          )}
          {!!lessonsWithoutDatetime.length && (
            <Grid item xs={12}>
              <Lessons
                lessons={lessonsWithoutDatetime}
                showUpdateModal={showUpdateModal}
                lessonId={lesson?.id}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <div className='lessons-page__title'>Расписание</div>
            <Calendar
              items={lessons}
              getMonth={getMonth}
              getLessonsCount={getLessonsCount}
              setLesson={setLesson}
              setDatetime={setDatetime}
              updateLesson={updateLesson}
              showUpdateModal={showUpdateModal}
            />
          </Grid>
        </Grid>
      </div>
    </Template>
  );
});
