import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router';
import { categoriesServises } from "api/services";
import showNotification from "helpers/showNotification";
import CreateCategoryPageStore from "./CreateCategoryPageStore";
import CategoriesPageStore from "pages/CategoriesPage/CategoriesPageStore";
import Title from "components/Title/Title";
import Template from "layout/Template/Template";
import { Button, Grid, TextField } from "@mui/material";
import './CreateCategoryPage.sass'

const CreateCategoryPage: React.FC = observer(() => {
  const { name, setName, loading, setLoading, hasError, setHasError, clearFields } = CreateCategoryPageStore
  const { getData } = CategoriesPageStore
  const history = useNavigate();

  useEffect(() => {
    return () => {
      clearFields();
    };
  }, []);

  const createCategory = async (e: React.FormEvent) => {
    e.preventDefault();
    if(!name.trim()){
      setHasError(true)
      return
    }
    const data = {
      name: name
    }
    try {
      setLoading(true)
      await categoriesServises.createCategory(data)
      getData()
      clearFields()
      history('/categories')
    }catch (e: any) {
      showNotification('Такая категория уже существует')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Template>
      <div className="create-category-page">
        <Title title="Создать категорию" className="create-category-page__title"></Title>
        <form onSubmit={createCategory}>
          <TextField
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={hasError}
            placeholder="Название"
            className="create-category-page__field"
          />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button type='submit' color="primary" size="medium">Сохранить</Button>
            </Grid>
            <Grid item xs={6}>
              <Link to="/categories">
                <Button color="inherit">Назад</Button>
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Template>
  )
})

export default CreateCategoryPage