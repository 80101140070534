import { contractsServices, courseServices, userServices } from 'api/services';
import { IResCourse } from 'api/services/courses/types';
import { IResUser } from 'api/services/users/types';
import clone from 'lodash/clone';
import { makeAutoObservable } from 'mobx';
import { ROLE } from 'refs/roles';

class CreateGroupPageStore {
  brId = '';
  teacherId = '' as any;
  lang = '';
  loading = false;
  hasError = false;
  search = '';
  searchCourse = '';
  users = [] as IResUser[];
  courses = [] as IResCourse[];
  contracts = [] as any;
  searchContracts = '';
  contractId = '' as any;
  courseId = '' as any;
  contractIds = [] as any;
  lessons = [] as any;
  data = {} as any;

  constructor() {
    makeAutoObservable(this);
  }
  setData = (e: any) => {
    this.data = e;
  };
  setLang = (e: any) => {
    this.lang = e;
  };
  setTeacherId = (e: any) => {
    this.teacherId = e;
  };
  setCourseId = (e: any) => {
    this.courseId = e;
  };
  setBranchId = (e: any) => {
    this.brId = e;
  };
  setLoading = (e: boolean) => {
    this.loading = e;
  };
  setHasError = (e: boolean) => {
    this.hasError = e;
  };
  setSearch = (e: any) => {
    this.search = e;
  };
  setSearchCourse = (e: any) => {
    this.searchCourse = e;
  };
  setUsers = (e: any) => {
    this.users = e;
  };
  setCourses = (e: any) => {
    this.courses = e;
  };
  setSearchContracts = (e: any) => {
    this.searchContracts = e;
  };
  setContractId = (e: any) => {
    this.contractId = e;
  };
  setLessons = (lessons: { value: undefined }[]) => (this.lessons = lessons);
  clearFields = () => {
    this.brId = '';
    this.teacherId = '';
    this.lang = '';
    this.loading = false;
    this.hasError = false;
    this.search = '';
    this.users = [];
    this.contracts = [];
    this.searchContracts = '';
    this.searchCourse = '';
    this.courseId = ''
    this.contractId = '';
    this.contractIds = [];
    this.lessons = [];
    this.data = {};
  };
  addLessons = () => {
    this.setLessons([...this.lessons, { value: undefined }]);
  };

  getCourseById = async () => {
    if (!this.courseId) return;
    this.setLessons([]);
    const res = await courseServices
      .getCourseById(this.courseId)
      .then((res) => res.data);
    const totalCount = res.tariffCourses.reduce((a, b) => a + b.count, 0);
    let num = 0;
    while (num < totalCount) {
      num++;
      this.addLessons();
    }
  };

  lessonsChange = (e: any, index: number) => {
    const arr = clone(this.lessons);
    let findIndex = arr?.findIndex(
      (i: any, findIndex: number) => findIndex === index
    );
    let currentObj = arr?.find(
      (i: any, findIndex: number) => findIndex === index
    );
    if (currentObj) {
      currentObj.value = e;
      arr.splice(findIndex, 1, currentObj);
    }
    this.lessons = [...arr];
  };
  deleteLessons = (index: number) => {
    const arr = clone(this.lessons);
    let findIndex = arr?.findIndex(
      (i: any, currenIndex: any) => currenIndex === index
    );
    arr.splice(findIndex, 1);
    this.lessons = [...arr];
  };
  addContracts = () => {
    if (!this.contractId || !this.searchContracts) return;
    let arr = clone(this.contractIds);
    let find = arr?.some((i: any) => i?.id === this.contractId);
    if (!find) {
      this.contractIds = [
        ...this.contractIds,
        {
          id: this.contractId,
          name: this.searchContracts,
          status: this.data?.status,
          tariff: this.data.tariff,
        },
      ];
    }
  };
  deleteContracts = (id: number) => {
    let arr = clone(this.contractIds);
    let find = arr?.some((i: any) => i?.id === id);
    if (find) {
      let newArr = arr.filter((i: any) => i.id !== id);
      this.contractIds = newArr;
    }
  };
  getUsers = async (value: any) => {
    if (!this.brId) return;
    const params = {
      skip: 0,
      take: 15,
      role: ROLE.TEACHER,
      search: value,
      isActive: true,
      branchId: this.brId,
    };
    const res = await userServices.getUsers(params).then((res) => res.data);
    this.setUsers(
      res.data.map((i) => ({
        name: `${i.lastName} ${i.firstName} ${i.middleName}`,
        value: i.id,
      }))
    );
  };
  getContracts = async (value: any) => {
    if (!this.brId) return;
    const params = {
      skip: 0,
      take: 15,
      branchId: Number(this.brId),
      search: value,
      status: 'PAYED',
    };
    const res = await contractsServices
      .getContracts(params)
      .then((res) => res.data);
    this.contracts = res.data.map((i) => ({
      name: `${i.contractNumber}, ${i.student.firstName} ${i.student.lastName} ${i.student.firstName} ${i.student?.middleName}`,
      tariff: i.tariff.titleRu,
      value: i.id,
      status: i.status,
    }));
  };
}

export default new CreateGroupPageStore();
