import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';
import moment from 'moment';
import { GENDER, genders } from 'refs/genders';
import Template from 'layout/Template/Template';
import StudentsPageStore from './StudentsPageStore';
import Title from 'components/Title/Title';
import AuthPageStore from 'pages/AuthPage/AuthPageStore';
import { isAdmin, isRegistrator, isRegistratorWidthCashier, isSU } from 'helpers/checkRole';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import './StudentsPage.sass';

export const StudentsPage: React.FC = observer(() => {
  const {
    params,
    setTake,
    setSkip,
    setLastName,
    setFirstName,
    setPhone,
    setPinfl,
    setPassportNumber,
    setPassportSerial,
    setGender,
    setMiddleName,
    setHasError,
    hasError,
    clearFields,
    students,
    totalCount,
    getStudents,
  } = StudentsPageStore;
  const history = useNavigate();
  const { user } = AuthPageStore

  useEffect(() => {
    getStudents();
  }, [getStudents, params.take, params.skip]);

  useEffect(() => {
    return () => {
      clearFields();
    };
  }, []);

  const formSubmit = (e: React.FormEvent) => {
    e?.preventDefault();
    setHasError(false);
    if (params.skip > 0) {
      setSkip(0);
    } else {
      getStudents();
    }
  };

  return (
    <Template btnUrl='/students/create' roleGuard={(user && isSU(user)) || (user && isAdmin(user)) || (user && isRegistrator(user) || (user && isRegistratorWidthCashier(user)))}>
      <div className='students-page'>
        <Title title='Студенты' className='students-page__title' />
        <form onSubmit={formSubmit}>
          <Grid container spacing={2} justifyContent='flex-end'>
            <Grid item xs={4}>
              <TextField
                value={params.lastName}
                onChange={(e) => setLastName(e.target.value)}
                size='small'
                label='Фамилия'
                error={hasError && !params.lastName?.trim()}
                autoComplete='off'
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                value={params.firstName}
                onChange={(e) => setFirstName(e.target.value)}
                size='small'
                label='Имя'
                error={hasError && !params.lastName?.trim()}
                autoComplete='off'
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                value={params.middleName}
                onChange={(e) => setMiddleName(e.target.value)}
                size='small'
                label='Отчество'
                error={hasError && !params.middleName?.trim()}
                autoComplete='off'
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                value={params.passportSerial}
                onChange={(e) => setPassportSerial(e.target.value)}
                size='small'
                label='Серия паспорта'
                error={hasError && !params.passportSerial?.trim()}
                autoComplete='off'
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                value={params.passportNumber}
                onChange={(e) => setPassportNumber(e.target.value)}
                size='small'
                label='Номер паспорта'
                error={hasError && !params.passportNumber?.trim()}
                autoComplete='off'
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                value={params.pinfl}
                onChange={(e) => setPinfl(e.target.value)}
                size='small'
                label='ПИНФЛ'
                error={hasError && !params.pinfl?.trim()}
                autoComplete='off'
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                value={params.phone}
                onChange={(e) => setPhone(e.target.value)}
                size='small'
                label='Номер телефона'
                error={hasError && !params.phone?.trim()}
                autoComplete='off'
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl
                fullWidth
                size='small'
                error={hasError && !params.gender?.trim()}
              >
                <InputLabel variant='outlined'>Выберите пол</InputLabel>
                <Select
                  label={'Выберите пол'}
                  value={params.gender}
                  onChange={(e) => setGender(e.target.value as GENDER)}
                  size='small'
                >
                  {genders?.map((i) => (
                    <MenuItem key={i.value} value={i.value}>
                      {i.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4} justifyContent='flex-end'>
              <div className='row'>
                <Button
                  className='students-page__button'
                  color='primary'
                  size='small'
                  type='submit'
                >
                  Найти
                </Button>
                <Button
                  className='students-page__button'
                  color='error'
                  size='small'
                  type='button'
                  onClick={() => clearFields(true)}
                >
                  Очистить
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>

        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>ФИО</TableCell>
                <TableCell>Дата рождения</TableCell>
                <TableCell>ПИНФЛ</TableCell>
                <TableCell>Серия/номер паспорта</TableCell>
                <TableCell>Номер телефона</TableCell>
                <TableCell>Дата изменения</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {students.map((i) => (
                <TableRow
                  style={{ cursor: 'pointer' }}
                  key={i.id}
                >
                  <TableCell>{i.id}</TableCell>
                  <TableCell>{`${i.lastName} ${i.firstName} ${i.middleName}`}</TableCell>
                  <TableCell>
                    {moment(i.dateOfBirth).format('DD.MM.YYYY')}
                  </TableCell>
                  <TableCell>{i.pinfl}</TableCell>
                  <TableCell>{`${i.passportSerial.toUpperCase()} ${
                    i.passportNumber
                  }`}</TableCell>
                  <TableCell>{'+' + i.phone}</TableCell>
                  <TableCell>
                    {moment(i.updatedAt).format('DD.MM.YYYY')}
                  </TableCell>
                  <TableCell>
                    <Link to={`/students/${i.id}/update`} className="go-to">
                      <ArrowForwardIcon />
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
      {totalCount > params.take && (
        <TablePagination
          count={totalCount}
          rowsPerPageOptions={[10, 25, 50]}
          component='div'
          rowsPerPage={params.take}
          labelRowsPerPage='Количество на странице'
          page={params.skip}
          onRowsPerPageChange={(e) => setTake(Number(e.target.value))}
          onPageChange={(e, newPage) => setSkip(newPage)}
        />
      )}
    </Template>
  );
});
