import { makeAutoObservable } from 'mobx';
import { branchServices } from 'api/services';
import { IBranchParams, IResBranch } from 'api/services/branches/types';

class BranchesPageStore {
  branches: IResBranch[] = [];
  params: IBranchParams = {
    searchValue: '',
    regionId: '',
  };

  constructor() {
    makeAutoObservable(this);
  }

  setRegionId = (value?: number | string) => (this.params.regionId = value);
  setSearchValue = (value: string) => (this.params.searchValue = value);
  setBranches = (data: IResBranch[]) => (this.branches = data);

  clearFields = (isButton?: boolean) => {
    this.setSearchValue('');
    this.setRegionId('');
    if (isButton) this.getBranches();
  };

  getBranches = async () => {
    if (this.branches.length) return;
    const res = await branchServices
      .getBranches({
        ...(!!this.params.searchValue?.trim()
          ? { search: this.params.searchValue }
          : {}),
        ...(typeof this.params.regionId === 'number'
          ? { regionId: this.params.regionId }
          : {}),
      })
      .then((res) => res.data);
    this.setBranches(res.sort((a, b) => a.id - b.id));
  };

  getBranchById = (id: number) => {
    return this.branches.find((i) => i.id === id)?.name || '';
  };
}

export default new BranchesPageStore();
