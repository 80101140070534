import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import moment from 'moment'
import { isAdmin, isReferral, isSU } from "helpers/checkRole";
import Template from "layout/Template/Template";
import Title from "components/Title/Title";
import AuthPageStore from "pages/AuthPage/AuthPageStore";
import RefsPageStore from "./RefsPageStore";
import { Button, Grid, Paper, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const RefsPage: React.FC = observer(() => {
  const {data, params, totalCount, setPage, setTake, setLastName, setFirstName, setMiddleName, filters, getRefs, searchHandler, clearHandler} = RefsPageStore
  const { user } = AuthPageStore;


  useEffect(() => {
    getRefs()
  }, [getRefs, filters, params.page, params.take])

  return (
    <Template
      btnUrl='/refs/create'
      roleGuard={(user && isSU(user)) || (user && isAdmin(user) || (user && isReferral(user)))}
    >
      <div className="refs-page">
      <Title title='Реферальные пользователи' className='users-page__title' />
        <Grid container spacing={2} className='users-page__filter'>
          
          <Grid item xs={3}>
            <TextField
              value={params.lastName}
              onChange={(e) => setLastName(e.target.value)}
              size='small'
              placeholder='Фамилия'
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              value={params.firstName}
              onChange={(e) => setFirstName(e.target.value)}
              size='small'
              placeholder='Имя'
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              value={params.middleName}
              onChange={(e) => setMiddleName(e.target.value)}
              size='small'
              placeholder='Отчество'
            />
          </Grid>
          <Grid item xs={3}>
            <div className='row'>
              <Button
                className='users-page__button'
                color='primary'
                size='small'
                onClick={searchHandler}
              >
                Найти
              </Button>
              <Button
                className='users-page__button'
                color='error'
                size='small'
                onClick={clearHandler}
              >
                Очистить
              </Button>
            </div>
          </Grid>
        </Grid>
        {!!data.length && (
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Ф.И.О.</TableCell>
                  <TableCell>Процент</TableCell>
                  <TableCell>Дата изменения</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((i, index) => (
                  <TableRow key={i.id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                    {i.lastName} {i.firstName} {i.middleName ? i.middleName : ''}
                    </TableCell>
                    <TableCell>{i.percent}</TableCell>
                    <TableCell>
                      {moment(i.updatedAt).format('DD.MM.YYYY')}
                    </TableCell>
                    <TableCell>
                      <Link to={`/refs/${i.id}`} className="go-to">
                        <ArrowForwardIcon />
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        )}
        {!!totalCount && (
          <TablePagination
            count={totalCount}
            rowsPerPageOptions={[10, 25, 50]}
            component='div'
            rowsPerPage={params.take}
            labelRowsPerPage='Количество на странице'
            page={params.page}
            onRowsPerPageChange={(e) => setTake(Number(e.target.value))}
            onPageChange={(e, newPage) => setPage(newPage)}
          />
        )}
      </div>
    </Template>
  )
})

export default RefsPage