import { makeAutoObservable } from "mobx"


class NoteModalStore {
  note = ''
  loading = false

  setNote = (e: string) => {
    this.note = e
  }
  setLoading = (e: boolean) => {
    this.loading = e
  }

  constructor () {
    makeAutoObservable(this)
  }
}


export default new NoteModalStore