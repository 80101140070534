import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import ConfirmationStore from './ConfirmationStore';
import './Confirmation.sass';

export const Confirmation: React.FC = observer(() => {
  const { showConfirmation, confirmation, setShowConfirmation, message } =
    ConfirmationStore;
  if (!showConfirmation) return null;
  return (
    <Dialog
      open={showConfirmation}
      onClose={() => setShowConfirmation(false)}
      PaperComponent={Paper}
      className='confirmation'
    >
      <DialogTitle style={{ cursor: 'move' }} id='draggable-dialog-title'>
        Внимание
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus size="large" onClick={() => setShowConfirmation(false)}>
          Отменить
        </Button>
        <Button size="large" onClick={confirmation}>Подтвердить</Button>
      </DialogActions>
    </Dialog>
  );
});
