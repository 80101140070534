import { makeAutoObservable } from "mobx"


class InitEvalModalStore {
  initEval = ''
  hasError = false
  loading = false

  constructor(){
    makeAutoObservable(this)
  }

  setLoading = (e: boolean) => {
    this.loading = e
  }
   
  setInitEval = (e: any) => {
    if(e > 20) return
    this.initEval = e
  }

  setHasError = (e: any) => {
    this.hasError = e
  }

}

export default new InitEvalModalStore