import instance from 'api/instance';
import sidebarStore from 'components/Sidebar/SidebarStore';
import AuthPageStore from 'pages/AuthPage/AuthPageStore';
import regionsStore from 'refs/regionsStore';

const appConfig = async () => {
  const token = localStorage.getItem('accessToken');
  const showMenu = localStorage.getItem('showMenu');
  sidebarStore.handleVisibleSidebar(showMenu === 'true');
  if (!token) return;
  instance.defaults.headers.common.Authorization = `Bearer ${token}`;
  await AuthPageStore.getProfile(token);
  regionsStore.getRegions();
};

export default appConfig;
