import { useNavigate } from 'react-router';
import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import Title from 'components/Title/Title';
import ru from 'date-fns/locale/ru';
import { sumAmount } from 'helpers/sumAmount';
import Template from 'layout/Template/Template';
import BranchesPageStore from 'pages/BranchesPage/BranchesPageStore';
import DatePicker, { registerLocale } from 'react-datepicker';
import { transactionTypes } from 'refs/transactionTypes';
import { TransactionType } from 'refs/transactionTypes';
import TransactionsPageStore from './TransactionsPageStore';
import { isAccountant, isAdmin, isSU } from 'helpers/checkRole';
import UsersPageStore from 'pages/UsersPage/UsersPageStore';
import AuthPageStore from 'pages/AuthPage/AuthPageStore';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import 'react-datepicker/dist/react-datepicker.css';
import './TransactionsPage.sass';

export const TransactionsPage: React.FC = observer(() => {
  const {
    params,
    setTake,
    setSkip,
    setBranchId,
    setFrom,
    setTo,
    transactions,
    getTransactions,
    totalSum,
    clearFields,
    totalCount,
    setOperatorId,
    setType,
    getExport
  } = TransactionsPageStore;
  const {
    data: users,
    getUsers,
    setBranchId: setUsersBranchId,
  } = UsersPageStore;
  const { branches } = BranchesPageStore;
  const { user } = AuthPageStore;
  registerLocale('ru', ru);
  const history = useNavigate();

  useEffect(() => {
    getTransactions();
  }, [getTransactions, params.take, params.skip]);

  useEffect(() => {
    if (user?.branchId) {
      setBranchId(user.branchId);
    }
  }, [user?.branchId, setBranchId]);

  useEffect(() => {
    if (!isSU(user) && !isAdmin(user) && !isAccountant(user)) {
      setOperatorId(user?.id);
    }
  }, [user?.id]);

  useEffect(() => {
    if (params.branchId) setUsersBranchId(params.branchId);
    getUsers();
  }, [params.branchId, getUsers]);

  useEffect(() => {
    return () => {
      clearFields();
    };
  }, [clearFields]);

  const formSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (params.skip > 0) {
      setSkip(0);
    } else {
      getTransactions();
    }
  };

  return (
    <Template>
      <div className='transactions-page'>
        <div className='transactions-page__top'>
          <Title title='Транзакции' className='transactions-page__title' />
          <div className='transactions-page__amount'>{`Итого: ${totalSum} сум`}</div>
        </div>
        <form onSubmit={formSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <DatePicker
                selected={params.from}
                dateFormat='dd.MM.yyyy'
                locale='ru'
                placeholderText='От'
                autoComplete='off'
                calendarClassName='date-picker'
                onChange={(date: any) => setFrom(date)}
                customInput={
                  <TextField
                    autoComplete='off'
                    size='small'
                  />
                }
              />
            </Grid>
            <Grid item xs={4}>
              <DatePicker
                selected={params.to}
                dateFormat='dd.MM.yyyy'
                locale='ru'
                placeholderText='До'
                autoComplete='off'
                calendarClassName='date-picker'
                onChange={(date: any) => setTo(date)}
                customInput={
                  <TextField
                    autoComplete='off'
                    size='small'
                  />
                }
              />
            </Grid>
            {!user?.branchId && (
              <Grid item xs={4}>
                <FormControl
                  fullWidth
                  size='small'
                >
                  <InputLabel variant='outlined'>Филиал</InputLabel>
                  <Select
                    label={'Филиал'}
                    value={params.branchId}
                    onChange={(e) => setBranchId(Number(e.target.value))}
                    size='small'
                  >
                    {branches?.map((i) => (
                      <MenuItem key={i.id} value={i.id}>
                        {i.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={4}>
              <FormControl fullWidth size='small'>
                <InputLabel variant='outlined'>Выберите тип</InputLabel>
                <Select
                  label={'Выберите тип'}
                  value={params.type || ''}
                  onChange={(e) => setType(e.target.value as TransactionType)}
                >
                  {transactionTypes?.map((i) => (
                    <MenuItem key={i.value} value={i.value}>
                      {i.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {(isAdmin(user) || isSU(user) || isAccountant(user)) && (
              <Grid item xs={4}>
                <FormControl fullWidth size='small'>
                  <InputLabel variant='outlined'>Выберите оператора</InputLabel>
                  <Select
                    label={'Выберите оператора'}
                    value={params.operatorId || ''}
                    onChange={(e) => setOperatorId(Number(e.target.value))}
                  >
                    {users?.map((i) => (
                      <MenuItem key={i.id} value={i.id}>
                        {`${i.lastName} ${i.firstName}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={4} justifyContent='flex-end'>
              <div className='row'>
                <Button
                  className='transactions-page__button'
                  color='primary'
                  size='small'
                  type='submit'
                >
                  Найти
                </Button>
                <Button
                  className='transactions-page__button'
                  color='error'
                  size='small'
                  type='button'
                  onClick={() => clearFields(true)}
                >
                  Очистить
                </Button>
              </div>
            </Grid>
            <Grid item xs={4} justifyContent='flex-end'>
              <Button
                className='transactions-page__button'
                color='primary'
                size='small'
                onClick={getExport}
              >
                Экспорт EXCEL
              </Button>
            </Grid>
          </Grid>
        </form>

        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                {!params.branchId && <TableCell>Филиал</TableCell>}
                <TableCell>Номер контракта</TableCell>
                <TableCell>Тариф</TableCell>
                <TableCell>Студент</TableCell>
                <TableCell>Сумма</TableCell>
                <TableCell>Баланс</TableCell>
                <TableCell>Тип</TableCell>
                <TableCell>Банк карта</TableCell>
                {(isAdmin(user) || isSU(user)) && !params.operatorId && (
                  <TableCell>Оператор</TableCell>
                )}
                <TableCell>Дата создания</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions.map((i) => (
                <TableRow key={i.id}>
                  <TableCell>{i.id}</TableCell>
                  {!params.branchId && (
                    <TableCell>{i.contract.branch?.name}</TableCell>
                  )}
                  <TableCell
                    onClick={() => history(`/contracts/${i.contract.id}`)}
                    className='transactions-page__item--selectable'
                  >
                    {i.contract.contractNumber}
                  </TableCell>
                  <TableCell>{i.contract.tariff?.titleRu}</TableCell>
                  <TableCell>{`${i.contract?.student?.lastName} ${i.contract?.student?.firstName}`}</TableCell>
                  <TableCell>{sumAmount(i.amount)}</TableCell>
                  <TableCell>{sumAmount(i.balance)}</TableCell>
                  <TableCell>
                    {transactionTypes.find((ii) => ii.value === i.type)
                      ?.label || ''}
                  </TableCell>
                  <TableCell>{i.cardNumber ? i?.cardNumber : ''}</TableCell>
                  {(isAdmin(user) || isSU(user)) && !params.operatorId && (
                    <TableCell>
                      {i.operator &&
                        `${i.operator?.lastName} ${i.operator?.firstName}`}
                    </TableCell>
                  )}
                  <TableCell>
                    {moment(i.createdAt).format('DD.MM.YYYY HH:mm')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
      {totalCount > params.take && (
        <TablePagination
          count={totalCount}
          rowsPerPageOptions={[10, 25, 50]}
          component='div'
          rowsPerPage={params.take}
          labelRowsPerPage='Количество на странице'
          page={params.skip}
          onRowsPerPageChange={(e) => setTake(Number(e.target.value))}
          onPageChange={(e, newPage) => setSkip(newPage)}
        />
      )}
    </Template>
  );
});
