import axios from 'axios';
import AuthPageStore from 'pages/AuthPage/AuthPageStore';

const url = process.env.REACT_APP_BASE_URL || '';

const instance = axios.create({
  baseURL: `${url}/api/v1`,
  headers: {
    common: {
      Accept: 'application/json',
      'Content-Type': 'application/json charset=utf-8',
    },
  },
});

instance.interceptors.response.use(
  (res) => res,
  (err) => {
    if (
      err.response.data.message === 'Unauthorized' &&
      err.response.data.statusCode === 401
    ) {
      AuthPageStore.signOut();
      window.location.reload();
    }
    return Promise.reject(err);
  }
);

export default instance;
