import React from 'react';
import { observer } from 'mobx-react-lite';
import DatePicker, { registerLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import { lessonsServices, userServices } from 'api/services';
import { IResLesson } from "api/services/lessons/types";
import { CustomSearchableSelect } from 'components/CustomSearchableSelect/CustomSearchableSelect';
import { NotificationStatus } from 'components/Notification/types';
import showNotification from 'helpers/showNotification';
import ModalTemplate from "layout/ModalTemplate/ModalTemplate";
import LessonModalStore from './LessonModalStore';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { Button, TextField } from "@mui/material";
import './LessonModal.sass';

interface Props {
  lessons?: IResLesson[]
  setLessons: (e: any, index: number, type: 'teacherId' | 'datetime' | 'teacherName') => void
  hide: () => void
}

const LessonModal: React.FC<Props> = observer(({
  lessons,
  setLessons,
  hide,
}) => {
  const {users, setUsers, loading, setLoading} = LessonModalStore
  registerLocale('ru', ru);


  const getUsers = async (value: any, type: 'PILOT_RUN' | 'RUN' | 'THEORY' ) => {
    if(!lessons?.length) return
    const params = {
      branchId: Number(lessons?.[0].branchId),
      skip: 0,
      take: 15,
      role: type === 'PILOT_RUN' ? 'INSTRUCTOR' : type === 'RUN' ? 'INSTRUCTOR' : 'TEACHER',
      search: value,
      isActive: true,
    };
    const res = await userServices.getUsers(params).then((res) => res.data);
    setUsers(
      res.data.map((i) => ({
        name: `${i.lastName} ${i.firstName} ${i.middleName ? i.middleName : ''}`,
        value: i.id,
      }))
    );
  };

  const updateLesson = async (
    id: number,
    datetime: string, 
    teacherId?: number | null,  
  ) => {
    if(!datetime || !id) {
      showNotification('Выберите дату и время')
      return
    }
    const requestData = {
      datetime,
      teacherId: teacherId ? teacherId : null
    }
    try {
      setLoading(true, id)
      await lessonsServices.updateLesson(id, requestData)
      showNotification('Дата успешно добавлена', NotificationStatus.SUCCESS)
    } catch (e: any) {

    } finally {
      setLoading(false, '')
    }
  }
  
  return (
    <ModalTemplate title="Выберите время" hide={hide}>
      <div className="lesson-modal">
        {lessons?.map((i, index) => (
          <React.Fragment key={i.id}>
            <div className="lesson-modal__label">{i.course?.titleRu}</div>
            <div className="lesson-modal__row" key={i.id}>
              <DatePicker
                selected={!lessons[index].datetime ? undefined : new Date(lessons[index].datetime)}
                timeInputLabel='Time:'
                dateFormat='Pp'
                showTimeInput
                onChange={(e: any) => setLessons(e, index, 'datetime')}
                locale='ru'
                customInput={<TextField autoComplete='off' />}
              />
              <CustomSearchableSelect
                items={[{ name: 'Нет', value: null }, ...users]}
                setSelectItem={(e: any) => setLessons(e, index, 'teacherId')}
                value={!lessons[index].teacherId ? undefined : Number(lessons[index].teacherId)}
                setLoading={() => {}}
                loading={false}
                setSearchValue={(e: any) => setLessons(e, index, 'teacherName')}
                searchValue={!lessons[index].teacher ? '' : `${lessons[index]?.teacher?.lastName ? lessons[index]?.teacher?.lastName : ''}${lessons[index]?.teacher?.firstName ? ` ${lessons[index]?.teacher?.firstName}` : ''}${lessons[index]?.teacher?.middleName ? ` ${lessons[index]?.teacher?.middleName}` : ''}`}
                setValue={(value: any) => getUsers(value, i.type === 'PILOT_RUN' ? 'PILOT_RUN' : i.type === 'RUN' ? 'RUN' : 'THEORY')}
                placeholder={i.type === 'PILOT_RUN' ? 'Выберите инструктора' : i.type === 'RUN' ? 'Выберите инструктора' : 'Выберите преподавателя'}
              />
              <Button
                size='medium'
                color='success'
                startIcon={<TaskAltIcon />}
                className='lesson-modal__button'
                onClick={() => updateLesson(i.id ,lessons[index].datetime, lessons[index]?.teacherId)}
                disabled={loading.loading && Number(loading.id) === i.id}
              ></Button>
            </div>
          </React.Fragment>
        ))}
      </div>

    </ModalTemplate>
  )
})

export default LessonModal