import appConfig from 'config/appConfig';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import AppRoutes from './routes/AppRoutes';

appConfig();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Router>
    <App>
      <AppRoutes />
    </App>
  </Router>
);
