import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Template from 'layout/Template/Template';
import Title from 'components/Title/Title';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import regionsStore from 'refs/regionsStore';
import UpdateBranchPageStore from './UpdateBranchPageStore';
import showNotification from 'helpers/showNotification';
import { branchServices } from 'api/services';
import { NotificationStatus } from 'components/Notification/types';
import ConfirmationStore from 'components/Confirmation/ConfirmationStore';
import BranchesPageStore from 'pages/BranchesPage/BranchesPageStore';
import './UpdateBranchPage.sass';

export const UpdateBranchPage: React.FC = observer(() => {
  const {
    name,
    regionId,
    contractPrefix,
    hasError,
    setName,
    setRegionId,
    setContractPrefix,
    setHasError,
    getBranchData,
  } = UpdateBranchPageStore;
  const history = useNavigate();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (id) getBranchData(Number(id));
  }, [getBranchData, id]);

  const updateBranch = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!id) return;
    if (!name.trim() || !regionId || !contractPrefix.trim()) {
      showNotification('Заполните поля');
      setHasError(true);
      return;
    }
    try {
      const data = { name, regionId: Number(regionId), contractPrefix };
      await branchServices.updateBranch(Number(id), data);
      showNotification('Филиал успешно изменен', NotificationStatus.SUCCESS);
      setName('');
      setRegionId('');
      setContractPrefix('');
      setHasError(false);
      BranchesPageStore.getBranches();
      history('/branches');
    } catch (e: any) {
      if (
        e.response.data.statusCode === 409 &&
        e.response.data.message === 'branch_name'
      ) {
        showNotification('Филиал с таким названием уже существует');
        return;
      }
      if (
        e.response.data.statusCode === 409 &&
        e.response.data.message === 'branch_contract_prefix'
      ) {
        showNotification('Филиал с таким префиксом контракта уже существует');
        return;
      }
      showNotification('Ошибка попробуйте позже');
    }
  };

  const confirmDeleteBranch = () => {
    if (!id) return;
    const message = 'Вы действительно хотите удалить этот филиал?';
    const deleteBranch = async () => {
      try {
        await branchServices.deleteBranch(Number(id));
        showNotification('Филиал удален', NotificationStatus.INFO);
        BranchesPageStore.getBranches();
        history('/branches');
      } catch {
        showNotification('Ошибка попробуйте позже');
      }
    };
    ConfirmationStore.showConfirm(message, deleteBranch);
  };

  return (
    <Template>
      <form className='update-branch-page' onSubmit={updateBranch}>
        <Title title='Изменить филиал' className='update-branch-page__title' />
        <TextField
          className='update-branch-page__field'
          value={name}
          onChange={(e) => setName(e.target.value)}
          autoComplete='off'
          placeholder='Имя'
          error={hasError && !name.trim()}
        />
        <FormControl
          fullWidth
          className='update-branch-page__field'
          error={hasError && !regionId}
        >
          <InputLabel variant='outlined'>Выберите регион</InputLabel>
          <Select
            label={'Выберите регион'}
            value={regionId}
            error={hasError && !regionId}
            onChange={(e) => setRegionId(e.target.value)}
          >
            {regionsStore.regions?.map((i) => (
              <MenuItem key={i.id} value={i.id}>
                {i.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          className='update-branch-page__field'
          value={contractPrefix}
          onChange={(e) => setContractPrefix(e.target.value)}
          placeholder='Префик контракта'
          autoComplete='off'
          error={hasError && !contractPrefix.trim()}
        />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button color='primary' size='medium' type='submit'>
              Сохранить
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Link to='/branches'>
              <Button color='inherit'>Назад</Button>
            </Link>
          </Grid>
          <Grid item xs={3}>
            <Button
              color='inherit'
              className='delete-button'
              onClick={confirmDeleteBranch}
            >
              Удалить
            </Button>
          </Grid>
        </Grid>
      </form>
    </Template>
  );
});
