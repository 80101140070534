import { makeAutoObservable } from 'mobx';

class ConfirmationStore {
  showConfirmation = false;
  message = '';
  confirm: any = null;

  constructor() {
    makeAutoObservable(this);
  }

  setShowConfirmation = (value: boolean) => (this.showConfirmation = value);
  setMessage = (value: string) => (this.message = value);
  setConfirm = (value: () => void) => (this.confirm = value);

  showConfirm = (message: string, func: () => void) => {
    this.setMessage(message);
    this.setShowConfirmation(true);
    this.confirm = func;
  };

  confirmation = () => {
    this.confirm();
    this.setMessage('');
    this.setShowConfirmation(false);
    this.confirm = null;
  };
}

export default new ConfirmationStore();
