import { makeAutoObservable } from "mobx"
import clone from 'lodash/clone'


class CreateTariffPageStore {
  isActive = false
  cost = ''
  titleRu = ''
  titleUz = ''
  loading = false
  hasError = false
  coursesData = [{
    count: '' as any,
    courseId: '' as any
  }]

  constructor (){
    makeAutoObservable(this)
  }

  setIsActive = (e: any) => {
    this.isActive = e
  }
  setCost = (e: any) => {
    this.cost = e
  }
  setTitleRu = (e: any) => {
    this.titleRu = e
  }
  setTitleUz = (e: any) => {
    this.titleUz = e
  }
  setLoading = (e: any) => {
    this.loading = e
  }
  setHasError = (e: any) => {
    this.hasError = e
  }

  addCourses = () => {
    this.coursesData = [...this.coursesData, {count: '', courseId: ''}]
  }

  courseChange = (e: any, index: number, type: string) => {
    const arr = clone(this.coursesData)
    let findIndex = arr?.findIndex(
      (i: any, currenIndex: any) => currenIndex === index
    );
    let currentObj = arr?.find(
      (i: any, currenIndex: any) => currenIndex === index
    );
    if(currentObj){
      if(type === 'text'){
        currentObj.count = Number(e)
      }
      if(type === 'select'){
        currentObj.courseId = Number(e);
      }
      arr.splice(findIndex, 1, currentObj);
    }
    this.coursesData = [...arr]
  }

  deleteHandler = (index: number) => {
    const arr = clone(this.coursesData)
    let findIndex = arr?.findIndex(
      (i: any, currenIndex: any) => currenIndex === index
    );
    arr.splice(findIndex, 1);
    this.coursesData = [...arr]
  }

  clearFields = () => {
    this.setIsActive(false)
    this.setCost('')
    this.setTitleRu('')
    this.setTitleUz('')
    this.setLoading(false)
    this.setHasError(false)
    this.coursesData = []

  }
}

export default new CreateTariffPageStore()