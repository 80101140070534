import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { genders } from 'refs/genders';
import { ROLE } from 'refs/roles';
import { contractsServices } from 'api/services';
import { CustomSearchableSelect } from 'components/CustomSearchableSelect/CustomSearchableSelect';
import { NotificationStatus } from 'components/Notification/types';
import Title from 'components/Title/Title';
import showNotification from 'helpers/showNotification';
import Template from 'layout/Template/Template';
import AuthPageStore from 'pages/AuthPage/AuthPageStore';
import BranchesPageStore from 'pages/BranchesPage/BranchesPageStore';
import TariffsPageStore from 'pages/TariffsPage/TariffsPageStore';
import CreateContractPageStore from './CreateContractPageStore';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import './CreateContractPage.sass';

const CreateContractPage: React.FC = observer(() => {
  const {
    branchId,
    setBranchId,
    tariffId,
    setTariffId,
    lang,
    setLang,
    instructorGender,
    setInstructorGender,
    searchGroup,
    setSearchGroup,
    groupId,
    setGroupId,
    individual,
    setIndividual,
    teacherId,
    setTeacherId,
    searchTeacher,
    setSearchTeacher,
    users,
    instructorId,
    setInstructorId,
    searchInstructor,
    setSearchInstructor,
    instructors,
    getUsers,
    groups,
    getGroups,
    referralUserId,
    setReferralUserId,
    referralUserSearch,
    setReferralUserSearch,
    refs,
    getRefs,
    clearFields,
    loading,
    setLoading,
    hasError,
    setHasError,
  } = CreateContractPageStore;
  const { user } = AuthPageStore;
  const { branches } = BranchesPageStore;
  const { tariffs } = TariffsPageStore;
  const { studentId } = useParams<{ studentId: string }>();
  const history = useNavigate();

  useEffect(() => {
    return () => {
      clearFields();
    };
  }, [clearFields]);

  useEffect(() => {
    if (!branchId) return;
    getUsers('', 'INSTRUCTOR');
    getUsers('', 'TEACHER');
  }, [branchId, getUsers]);

  useEffect(() => {
    if (!user?.branchId) return;
    setBranchId(user.branchId);
  }, [user?.branchId, setBranchId]);

  useEffect(() => {
    getRefs();
  }, [getRefs]);

  useEffect(() => {
    if (!branchId || individual) return;
    getGroups();
  }, [branchId, individual, getGroups]);

  useEffect(() => {
    tariffs.map((i) => {
      const findTarrif = i.id === Number(tariffId) ? i : null;
      if (findTarrif?.tariffCourses?.every((i) => i.course.individual)) {
        setIndividual(true);
      }
    });
  }, [tariffId, setIndividual]);

  const createContract = async (e: React.FormEvent) => {
    e.preventDefault();
    let validation = true;

    if (
      !studentId ||
      !tariffId ||
      !lang.trim() ||
      !branchId ||
      !instructorGender
    ) {
      validation = false;
    }
    if (!validation) {
      setHasError(true);
      return;
    }

    const data = {
      studentId: Number(studentId),
      tariffId: Number(tariffId),
      lang,
      instructorGender: instructorGender ? instructorGender : '',
      branchId: Number(branchId),
      groupId: groupId ? Number(groupId) : undefined,
      instructorId: instructorId ? Number(instructorId) : undefined,
      teacherId: teacherId ? Number(teacherId) : undefined,
      referralUserId: referralUserId ? Number(referralUserId) : null,
    };

    try {
      setLoading(true);
      const res = await contractsServices
        .createContract(data)
        .then((res) => res.data);
      clearFields();
      showNotification('Контракт успешно создан', NotificationStatus.SUCCESS);
      history(`/contracts/${res.id}`);
    } catch (e: any) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Template>
      <div className='create-contract-page'>
        <Title
          title='Заключить контракт'
          className='create-contract-page__title'
        />
        <form onSubmit={createContract}>
          {(user?.role === ROLE.ADMIN || user?.role === ROLE.SU) && (
            <FormControl fullWidth className='create-contract-page__field'>
              <InputLabel variant='outlined'>Выберите филиал</InputLabel>
              {!!branches.length && (
                <Select
                  label={'Выберите филиал'}
                  value={branchId}
                  onChange={(e) => setBranchId(e.target.value)}
                  error={hasError && !branchId}
                >
                  {branches?.map((i) => (
                    <MenuItem key={i.id} value={i.id}>
                      {i.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </FormControl>
          )}
          <FormControl fullWidth className='create-contract-page__field'>
            <InputLabel variant='outlined'>Выберите тариф</InputLabel>
            {!!tariffs.length && (
              <Select
                label={'Выберите тариф'}
                value={tariffId}
                onChange={(e) => setTariffId(e.target.value)}
                error={hasError && !tariffId}
              >
                {tariffs?.map((i) => (
                  <MenuItem key={i.id} value={i.id}>
                    {i.titleRu}
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormControl>
          {branchId && (
            <div className='create-contract-page__field'>
              <CustomSearchableSelect
                items={[
                  { name: 'Нет', value: null },
                  ...users.map((i) => ({
                    ...i,
                    value: i.id,
                    name: `${i.lastName} ${i.firstName} ${
                      i.middleName ? i.middleName : ''
                    }`,
                  })),
                ]}
                setSelectItem={setTeacherId}
                value={Number(teacherId)}
                setLoading={() => {}}
                loading={false}
                setSearchValue={setSearchTeacher}
                searchValue={searchTeacher}
                setValue={(e) => getUsers(e, 'TEACHER')}
                placeholder='Выберите преподавателя'
              />
            </div>
          )}
          {branchId && (
            <FormControl fullWidth className='create-contract-page__field'>
              <InputLabel variant='outlined'>
                Выберите пол инструктора
              </InputLabel>
              <Select
                label={'Выберите пол инструктора'}
                value={instructorGender}
                onChange={(e) => setInstructorGender(e.target.value)}
                error={hasError && !instructorGender}
              >
                {genders?.map((i) => (
                  <MenuItem key={i.value} value={i.value}>
                    {i.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {branchId && (
            <div className='create-contract-page__field'>
              <CustomSearchableSelect
                items={[
                  { name: 'Нет', value: null },
                  ...instructors.map((i: any) => ({
                    ...i,
                    value: i.id,
                    name: `${i.lastName} ${i.firstName} ${
                      i.middleName ? i.middleName : ''
                    }`,
                  })),
                ]}
                setSelectItem={setInstructorId}
                value={Number(instructorId)}
                setLoading={() => {}}
                loading={false}
                setSearchValue={setSearchInstructor}
                searchValue={searchInstructor}
                setValue={(e) => getUsers(e, 'INSTRUCTOR')}
                placeholder='Выберите инструктора'
              />
            </div>
          )}
          {branchId && !individual && (
            <div className='create-contract-page__field'>
              <CustomSearchableSelect
                items={[
                  { name: 'Нет', value: null },
                  ...groups.map((i) => ({
                    ...i,
                    value: i.id,
                    name: `Группа №${i.id} от ${moment(i.createdAt).format(
                      'DD.MM.YYYY HH:mm'
                    )}`,
                  })),
                ]}
                setSelectItem={setGroupId}
                value={groupId}
                setLoading={() => {}}
                loading={false}
                setSearchValue={setSearchGroup}
                searchValue={searchGroup}
                setValue={getGroups}
                placeholder='Выбор группы'
              />
            </div>
          )}
          <FormControl fullWidth className='create-contract-page__field'>
            <InputLabel variant='outlined'>Выберите язык</InputLabel>
            <Select
              label={'Выберите язык'}
              value={lang}
              onChange={(e) => setLang(e.target.value)}
              error={hasError && !lang.trim()}
            >
              <MenuItem value='RU'>RU</MenuItem>
              <MenuItem value='UZ'>UZ</MenuItem>
            </Select>
          </FormControl>
          <div className='create-contract-page__field'>
            <CustomSearchableSelect
              items={[
                { name: 'Нет', value: null },
                ...refs.map((i) => ({
                  ...i,
                  value: i.id,
                  name: `${i.lastName} ${i.firstName} ${i.middleName}`,
                })),
              ]}
              setSelectItem={setReferralUserId}
              value={referralUserId}
              setLoading={() => {}}
              loading={false}
              setSearchValue={setReferralUserSearch}
              searchValue={referralUserSearch}
              setValue={getRefs}
              placeholder='Реферальный пользователь'
            />
          </div>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                type='submit'
                color='primary'
                size='medium'
                disabled={loading}
              >
                Сохранить
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Link to='/contracts'>
                <Button color='inherit'>Назад</Button>
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Template>
  );
});

export default CreateContractPage;
