import { makeAutoObservable } from "mobx";
import clone from 'lodash/clone';
import cloneDeep from "lodash/cloneDeep";
import { attendanceServices, contractsServices, lessonsServices } from "api/services";
import { IResAttendance } from "api/services/attendance/types";
import { IResContract } from "api/services/contracts/types";
import { IResLesson } from "api/services/lessons/types";
import showNotification from "helpers/showNotification";


class UpdateLessonIndividualStore {
  loading = false
  lessons = [] as IResLesson[]
  contract = {} as IResContract
  contractId = undefined
  lessonType = '' as any
  attendance = [] as IResAttendance[]
 
  constructor (){
    makeAutoObservable(this)
  }

  setContractId = (e: any) => {
    this.contractId = e
  }
  clearFields = () => {
    this.loading = false
    this.lessons = [] as IResLesson[]
    this.contract = {} as IResContract
    this.contractId = undefined
    this.lessonType = ''
    this.attendance = []
  }

  getLessonById = async (id: number) => {
    try {
      const res = await lessonsServices.getLessonById(id).then((res) => res.data)
      this.lessonType = res?.type
      this.contract = res.contract
      if(res.contractId && res.type){
        this.getContractById(res.contractId, res.type)
      }
      // this.teacher = res.teacher
    }catch {

    }
  }

  getContractById = async (id: number, type: ILessonType) => {
    const res = await contractsServices.getContractById(id).then((res) => res.data)
    this.lessons = !!res.lessons?.length ? res.lessons.filter((i) => i.type === type) : []
    this.attendance = !!res?.attendance?.length ? res.attendance : []
  }

  attendanceHandler = (value?: boolean | null, lessonId?: number, contractId?: number, attendanceId?: number) => {
    if(value === undefined && lessonId && contractId){
      this.createAttendance(true, lessonId, contractId)
    }
    if(value === false && attendanceId && contractId){
      this.deleteAttendance(attendanceId, contractId)
    }
    if(value === true && attendanceId){
      this.updateAttendance(attendanceId, false)
    }
  }
  createAttendance = async (value: boolean, lessonId: number, contractId: number) => {
    const requestData = {
      lessonId,
      contractId,
      value
    }
    try {
      this.loading = true
      const res = await attendanceServices.createAttendance(requestData).then((res) => res.data)
      let cloneAttendance = clone(this.attendance)
      cloneAttendance.push({
        contractId: res.contractId,
        id: res.id,
        lessonId: res.lessonId,
        value: true
      })
      this.attendance = cloneAttendance
    } catch (e: any){
      if(e?.response?.data?.message === 'Contract status NEW'){
        showNotification('Контракт не оплачен')
      }
      if(e?.response?.data?.message === 'Contract status FINISHED'){
        showNotification('Контракт окончен')
      }
      if(e?.response?.data?.message === 'Contract is not active'){
        showNotification('Контракт не активен')
      }
    } finally {
      this.loading = false
    }
  }
  updateAttendance = async (id: number, value: boolean) => {
    const requestData = {
      value
    }
    try {
      this.loading = true
      await attendanceServices.updateAttendance(id, requestData).then((res) => res.data)
      let cloneAttendance = clone(this.attendance)
      let findIndex = cloneAttendance?.findIndex((i) => i.id === id)
      let findAttendance = cloneAttendance?.find((i) => i.id === id)
      let deep = cloneDeep(findAttendance)
      if(deep && findIndex !== undefined){
        deep.value = value
        cloneAttendance.splice(findIndex, 1, deep)
        this.attendance = cloneAttendance
        
      }

    } catch(e: any){
      if(e?.response?.data?.message === 'Contract status NEW'){
        showNotification('Контракт не оплачен')
      }
      if(e?.response?.data?.message === 'Contract status FINISHED'){
        showNotification('Контракт окончен')
      }
      if(e?.response?.data?.message === 'Contract is not active'){
        showNotification('Контракт не активен')
      }
    } finally {
      this.loading = false
    }
  }
  deleteAttendance = async (id: number, contractId: number) => {
    try {
      this.loading = true
      await attendanceServices.deleteAttendance(id).then((res) => res.data)
      let cloneAttendance = clone(this.attendance)
      let final = cloneAttendance?.filter((i) => i.id !== id)
      this.attendance = final
    } catch (e: any) {
      if(e?.response?.data?.message === 'Contract status NEW'){
        showNotification('Контракт не оплачен')
      }
      if(e?.response?.data?.message === 'Contract status FINISHED'){
        showNotification('Контракт окончен')
      }
      if(e?.response?.data?.message === 'Contract is not active'){
        showNotification('Контракт не активен')
      }
    } finally {
      this.loading = false
    }
  }
}

export default new UpdateLessonIndividualStore