import instance from "api/instance";
import qs from 'query-string';
import { IGetUsers } from "../users/types";
import { ICreateRef, IDeleteRef, IGetRefById, IGetRefs, IUpdateRef } from "./types";



export const createRef: ICreateRef = (data) => instance.post('/referralUsers', data)

export const updateRef: IUpdateRef = (id, data) => instance.put(`/referralUsers/${id}`, data)

export const deleteRef: IDeleteRef = (id) => instance.delete(`/referralUsers/${id}`)

export const getRefs: IGetRefs = (params) => instance.get(`/referralUsers?${qs.stringify(params, { skipEmptyString: true })}`);

export const getRefById: IGetRefById = (id) => instance.get(`/referralUsers/${id}`)


