import { useEffect } from "react";
import { useParams } from "react-router";
import { observer } from "mobx-react-lite";
import moment from 'moment'
import { genders } from "refs/genders";
import showNotification from "helpers/showNotification";
import { IResContract } from "api/services/contracts/types";
import { contractsServices } from "api/services";
import ModalTemplate from "layout/ModalTemplate/ModalTemplate";
import ContractModalStore from './ContractModalStore'
import BranchesPageStore from "pages/BranchesPage/BranchesPageStore";
import { CustomSearchableSelect } from "components/CustomSearchableSelect/CustomSearchableSelect";
import CreateContractPageStore from "pages/CreateContractPage/CreateContractPageStore";
import TariffsPageStore from "pages/TariffsPage/TariffsPageStore";
import { NotificationStatus } from "components/Notification/types";
import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, Switch } from "@mui/material";
import './ContractModal.sass'
import AuthPageStore from "pages/AuthPage/AuthPageStore";

interface Props {
  hide: () => void
  data?: IResContract
}
const ContractModal: React.FC<Props> = observer(({
  hide,
  data
}) => {
  const { 
    isActive,
    setIsActive,
    groupId, 
    setGroupId, 
    groups,
    getGroups,
    searchGroup,
    setSearchGroup,
    branchId, 
    setBranchId, 
    instructorId, 
    setInstructorId, 
    searchInstructors,
    setSearchInstructors,
    instructorGender, 
    teacherId,
    setTeacherId,
    searchTeacher,
    setSearchTeacher,
    setInstructorGender,
    lang,
    setLang,
    tariffId,
    setTariffId,
    individual,
    setIndividual,
    loading,
    setLoading,
    hasError,
    setHasError,
    clearFields,
    referralUserId,
    setReferralUserId,
    referralUserSearch,
    setReferralUserSearch,
    getRefs,
    refChecked,
    setRefChecked,
    refs
  } = ContractModalStore
  const { branches } = BranchesPageStore
  const { tariffs } = TariffsPageStore
  const { getUsers, instructors, users } = CreateContractPageStore
  const { user } = AuthPageStore
  const { contractId } = useParams<{ contractId: string }>();
  
  useEffect(() => {
    if(!data) return
    setTariffId(data.tariffId)
    setGroupId(data.groupId ? data.groupId : null)
    setBranchId(data.branchId ? data.branchId : '')
    setInstructorId(data.instructorId ? data.instructorId : '')
    setSearchInstructors(data.instructor ? `${data.instructor?.lastName} ${data.instructor?.firstName} ${data.instructor?.middleName ? data.instructor?.middleName : ''}` : '')
    setInstructorGender(data.instructorGender ? data.instructorGender : '')
    setLang(data.lang)
    setTeacherId(data.teacherId ? data.teacherId : '')
    setSearchTeacher(data.teacher ? `${data.teacher?.lastName} ${data.teacher?.firstName} ${data.teacher?.middleName ? data.teacher?.middleName : ''}` : '')
    setIsActive(data.isActive)
    setReferralUserId(data?.referralUserId ? data?.referralUserId : undefined)
    setReferralUserSearch(data?.referralUser ? `${data?.referralUser.lastName} ${data?.referralUser.firstName} ${data?.referralUser.middleName}` : '')
    setRefChecked(data?.refChecked)
  }, [
    data,
    setGroupId,
    setBranchId,
    setInstructorId,
    setSearchInstructors,
    setInstructorGender,
    setLang,
    setTeacherId,
    setSearchTeacher,
    setIsActive,
    setReferralUserId,
    setReferralUserSearch,
    setRefChecked,
    setTariffId
  ])

  useEffect(() => {
    if(!branchId) return
    getUsers('', 'INSTRUCTOR')
    getUsers('', 'TEACHER')
  }, [branchId])

  useEffect(() => {
    getRefs()
  }, [getRefs])

  useEffect(() => {
    if (!branchId || individual) return;
    getGroups();
  }, [branchId, individual]);
  
  useEffect(() => {
    tariffs.map((i) => {
      const findTarrif = i.id === Number(tariffId) ? i : null;
      if (findTarrif?.tariffCourses?.every((i) => i.course.individual)) {
        setIndividual(true);
      }
    });
  }, [tariffId]);

  const updateContract = async (e: React.FormEvent) => {
    e.preventDefault()
    if(!contractId) return
    if(!branchId) {
      setHasError(true)
      return
    }
    const requestData = {
      groupId: groupId ? Number(groupId) : null,
      branchId: branchId ? Number(branchId) : undefined,
      instructorId: instructorId ? Number(instructorId) : undefined,
      teacherId: teacherId ? Number(teacherId) : undefined,
      instructorGender,
      lang,
      isActive,
      referralUserId: Number(referralUserId),
      refChecked
    }
    try {
      setLoading(true)
      await contractsServices.updateContract(Number(contractId), requestData)
      showNotification('Контракт обновлен', NotificationStatus.SUCCESS)
      hideModal()
    } catch (e: any) {
      
    } finally {
      setLoading(false)
    }
  }

  const hideModal = () => {
    clearFields()
    hide()
  }

  return (
    <ModalTemplate title="Изменить контракт" hide={hideModal}>
      <form className="contract-modal" onSubmit={updateContract}>
        <div className="contract-modal__field">
          <FormGroup>
            <FormControlLabel
              label='Активный'
              control={
                <Switch
                  onChange={(e) => setIsActive(e.target.checked)}
                  checked={isActive}
                />
              }
            />
          </FormGroup>
        </div>
        <FormControl fullWidth className='contract-modal__field'>
          <InputLabel variant='outlined'>Выберите филиал</InputLabel>
          {!!branches.length && (
            <Select
              label={'Выберите филиал'}
              value={branchId}
              onChange={(e) => setBranchId(e.target.value)}
              error={hasError && !branchId}
            >
              {branches?.map((i) => (
                <MenuItem key={i.id} value={i.id}>
                  {i.name}
                </MenuItem>
              ))}
            </Select>
          )}
        </FormControl>
        {branchId && (
          <div className="contract-modal__field">
            <CustomSearchableSelect
              items={[
                { name: 'Нет', value: null },
                ...users.map((i) => ({
                  ...i,
                  value: i.id,
                  name: `${i.lastName} ${i.firstName} ${i.middleName ? i.middleName : ''}`,
                })),
              ]}
              setSelectItem={setTeacherId}
              value={Number(teacherId)}
              setLoading={() => {}}
              loading={false}
              setSearchValue={setSearchTeacher}
              searchValue={searchTeacher}
              setValue={(e) => getUsers(e, 'TEACHER')}
              placeholder='Выберите преподавателя'
            />
          </div>
        )}
        {branchId && !individual && (
          <div className="contract-modal__field">
            <CustomSearchableSelect
              items={[
                { name: 'Нет', value: null },
                ...groups.map((i) => ({
                  ...i,
                  value: i.id,
                  name: `Группа №${i.id} от ${moment(i.createdAt).format(
                    'DD.MM.YYYY HH:mm'
                  )}`,
                })),
              ]}
              setSelectItem={setGroupId}
              value={Number(groupId)}
              setLoading={() => {}}
              loading={false}
              setSearchValue={setSearchGroup}
              searchValue={searchGroup}
              setValue={getGroups}
              placeholder='Выбор группы'
            />
          </div>
        )}
        {branchId && (
          <FormControl fullWidth className='contract-modal__field'>
            <InputLabel variant='outlined'>Выберите пол инструктора</InputLabel>
            <Select
              label={'Выберите пол инструктора'}
              value={instructorGender}
              onChange={(e) => setInstructorGender(e.target.value)}
            >
              {genders?.map((i) => (
                <MenuItem key={i.value} value={i.value}>
                  {i.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {branchId && (
          <div className="contract-modal__field">
            <CustomSearchableSelect
              items={[
                { name: 'Нет', value: null },
                ...instructors.map((i) => ({
                  ...i,
                  value: i.id,
                  name: `${i.lastName} ${i.firstName} ${i.middleName ? i.middleName : ''}`,
                })),
              ]}
              setSelectItem={setInstructorId}
              value={Number(instructorId)}
              setLoading={() => {}}
              loading={false}
              setSearchValue={setSearchInstructors}
              searchValue={searchInstructors}
              setValue={(e) => getUsers(e, 'INSTRUCTOR')}
              placeholder='Выберите инструктора'
            />
          </div>
        )}
        <div className="contract-modal__field contract-modal__field--flex">
          <CustomSearchableSelect
            items={[
              { name: 'Нет', value: null },
              ...refs.map((i) => ({
                ...i,
                value: i.id,
                name: `${i.lastName} ${i.firstName} ${i.middleName}`,
              })),
            ]}
            setSelectItem={setReferralUserId}
            value={referralUserId}
            setLoading={() => {}}
            loading={false}
            setSearchValue={setReferralUserSearch}
            searchValue={referralUserSearch}
            setValue={getRefs}
            placeholder='Реферальный пользователь'
          />
          {((user?.role === 'SU') || (user?.role === 'ADMIN')) && (
            <Checkbox size="medium" checked={refChecked} onChange={(e) => setRefChecked(e.target.checked)}/>
          )}
        </div>
        <div className="contract-modal__row">
          <Button
            color='inherit'
            size='medium'
            onClick={hideModal}
          >
            Назад
          </Button>
          <Button
            type='submit'
            color='primary'
            size='medium'
            disabled={loading}
          >
            Сохранить
          </Button>
        </div>
      </form>
    </ModalTemplate>
  )
})

export default ContractModal