import { authServices } from 'api/services';
import { IResUser } from 'api/services/users/types';
import { NotificationStatus } from 'components/Notification/types';
import showNotification from 'helpers/showNotification';
import { makeAutoObservable } from 'mobx';

class AuthPageStore {
  login: string = '';
  password: string = '';
  accessToken: string = '';
  user?: IResUser = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  setLogin = (value: string) => {
    this.login = value;
  };

  setPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.password = e.target.value;
  };

  setProfile = (user: IResUser, token?: string) => {
    this.user = user;
    this.login = '';
    this.password = '';
    if (token) {
      this.accessToken = token;
      localStorage.setItem('accessToken', token);
    }
  };

  getProfile = async (token: string) => {
    const res = await authServices
      .getProfile({ accessToken: token })
      .then((res) => res.data);
    this.setProfile(res);
  };

  signOut = async () => {
    showNotification('Вы вышли из системы', NotificationStatus.INFO);
    localStorage.removeItem('accessToken');
    this.accessToken = '';
    this.user = undefined;
  };
}

export default new AuthPageStore();
