import {
  EventClickArg,
  EventContentArg,
  EventSourceInput,
} from '@fullcalendar/core';
import ru from '@fullcalendar/core/locales/ru';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import { IResLesson } from 'api/services/lessons/types';
import cn from 'classnames';
import { translateCourseType } from 'helpers/translate';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useEffect, useRef } from 'react';
import { ReactComponent as AutoIcon } from 'assets/images/auto.svg';
import { ReactComponent as TheoryIcon } from 'assets/images/theory.svg';
import './Calendar.sass';
import { ReactComponent as TimeIcon } from 'assets/images/time-icon.svg';
import CalendarStore, { CalendarView } from './CalendarStore';
import { CourseType } from 'api/services/courses/types';
import LessonsPageStore from 'pages/LessonsPage/LessonsPageStore';

interface Props {
  items: IResLesson[];
  getMonth: (data: { from: string; to: string }) => void;
  setDatetime: (date: Date) => void;
  showUpdateModal: (data: IResLesson) => void;
  updateLesson: () => void;
  setLesson: (lesson: IResLesson) => void;
  getLessonsCount: () => void;
}

export const Calendar: React.FC<Props> = observer(
  ({ items, getMonth, setDatetime, showUpdateModal, getLessonsCount }) => {
    const calendarRef = useRef<any>();
    const { lessonsCount } = LessonsPageStore;
    const {
      grid,
      setGrid,
      calendarItemsDay,
      setCalendarItemsDay,
      setCalendarItemsWeek,
      calendarItemsWeek,
    } = CalendarStore;

    useEffect(() => {
      setCalendarItemsDay(
        items.map((i) => ({
          item: { ...i, calendarType: CalendarView.DAY },
          title: i.contractId
            ? `Контракт ${i.contract.contractNumber}`
            : `Группа №${i.groupId}`,
          start: new Date(i.datetime),
          end: new Date(i.datetime).setMinutes(
            new Date(i.datetime).getMinutes() + (i.course?.duration || 0)
          ),
          className: i.group?.isFinished ? 'calendar__item--not-active' : '',
          backgroundColor: i.contractId ? '#326C89' : '#003956',
        })) as EventSourceInput
      );
    }, [items.length]);

    useEffect(() => {
      setCalendarItemsWeek(
        lessonsCount.map((i) => ({
          calendarType: CalendarView.WEEK,
          title: `Количество уроков ${i.count}`,
          start: new Date(i.d).setHours(new Date(i.d).getHours() + 12),
          backgroundColor: '#326C89',
        }))
      );
    }, [JSON.stringify(lessonsCount)]);

    const getDate = async (data: any) => {
      const { startStr, endStr } = data;
      const checkWeek = data.view.type === CalendarView.WEEK;
      getMonth({
        from: moment(startStr).format('YYYY-MM-DD'),
        to: moment(checkWeek ? endStr : startStr).format('YYYY-MM-DD'),
      });
      if (checkWeek) getLessonsCount();
    };

    const changeView = (view: CalendarView) => {
      if (view !== grid) {
        setGrid(view);
        calendarRef.current.getApi().changeView(view);
        return;
      }
    };

    const updateItem = (data: EventClickArg) => {
      const lesson = data.event.extendedProps?.item;
      if (!lesson) {
        calendarRef.current.getApi().gotoDate(data.event.start);
        changeView(CalendarView.DAY);
        return;
      }
      setDatetime(new Date(lesson.datetime));
      showUpdateModal(lesson);
    };

    return (
      <div
        className={cn('calendar', {
          'calendar--week': grid === CalendarView.WEEK,
        })}
      >
        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
          initialView={CalendarView.DAY}
          weekends={true}
          timeZone='local'
          allDaySlot={false}
          views={{
            dayGrid: {
              selectable: false,
            },
            timeGrid: {
              selectable: false,
              slotDuration: '00:20:00',
            },
          }}
          headerToolbar={{
            left: 'timeGridWeek,timeGridDay',
            center: 'title',
            right: 'prev,next',
          }}
          customButtons={{
            timeGridWeek: {
              click: () => changeView(CalendarView.WEEK),
              text: 'Неделя',
            },
            timeGridDay: {
              click: () => changeView(CalendarView.DAY),
              text: 'День',
            },
          }}
          slotEventOverlap={false}
          navLinkWeekClick={(info) => console.log(info)}
          locale={ru}
          datesSet={getDate}
          eventClick={updateItem}
          height={800}
          events={
            grid === CalendarView.DAY ? calendarItemsDay : calendarItemsWeek
          }
          dayMaxEvents={true}
          eventContent={(eventInfo: EventContentArg) => {
            if (
              eventInfo.event.extendedProps.calendarType === CalendarView.WEEK
            ) {
              return (
                <div className='calendar__item-week'>
                  {eventInfo.event.title}
                </div>
              );
            }
            return (
              <div className='calendar__item'>
                <span className='calendar__item-top'>
                  {eventInfo.event.extendedProps.item?.course?.type ===
                  CourseType.THEORY ? (
                    <TheoryIcon />
                  ) : (
                    <AutoIcon />
                  )}
                  <i>
                    {translateCourseType(
                      eventInfo.event.extendedProps.item?.course?.type
                    )}
                  </i>
                  <i className='calendar__item-title'>
                    {eventInfo.event.title}
                  </i>
                </span>
                <span className='calendar__item-bottom'>
                  <TimeIcon />
                  <b>{`${eventInfo.timeText} `}</b>
                </span>
              </div>
            );
          }}
        />
      </div>
    );
  }
);
