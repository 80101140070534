import { tranactionsServices } from 'api/services';
import FileSaver from 'file-saver';
import {
  IResTransaction,
  ITransactionParams,
} from 'api/services/transactions/types';
import { sumAmount } from 'helpers/sumAmount';
import { makeAutoObservable } from 'mobx';
import { TransactionType } from 'refs/transactionTypes';

export class TransactionsPageStore {
  params: ITransactionParams = {
    branchId: '',
    from: undefined,
    to: undefined,
    operatorId: undefined,
    skip: 0,
    take: 25,
    type: undefined,
  };
  totalSum: string = '';
  transactions: IResTransaction[] = [];
  totalCount = 0;

  constructor() {
    makeAutoObservable(this);
  }

  setSkip = (value: number) => (this.params.skip = value);
  setTake = (value: number) => (this.params.take = value);
  setBranchId = (value: number | string) => (this.params.branchId = value);
  setTo = (value?: Date) => (this.params.to = value);
  setFrom = (value?: Date) => (this.params.from = value);
  setTotalCount = (value: number) => (this.totalCount = value);
  setTransactions = (data: IResTransaction[]) => (this.transactions = data);
  setOperatorId = (id?: number) => (this.params.operatorId = id);
  setTotalSum = (sum: string | number) =>
    (this.totalSum = sumAmount(Number(sum)));
  setType = (type?: TransactionType) => (this.params.type = type);

  getTransactions = async () => {
    const { branchId, from, to, operatorId, skip, take, type } = this.params;
    const res = await tranactionsServices
      .getTransactions({
        branchId,
        ...(from ? { from: new Date(from).toISOString() } : {}),
        ...(to ? { to: new Date(to).toISOString() } : {}),
        operatorId,
        skip: this.params.take * this.params.skip,
        take,
        type,
      })
      .then((res) => res.data);
    this.setTotalCount(res.count);
    this.setTransactions(res.data);
    this.setTotalSum(res.sum);
  };

  getExport = async () => {
    const { branchId, from, to, operatorId, skip, take, type } = this.params;
    const params = {
      branchId: branchId ? Number(branchId) : undefined,
      ...(from ? { from: new Date(from).toISOString() } : {}),
      ...(to ? { to: new Date(to).toISOString() } : {}),
      operatorId,
      skip,
      take,
      type,
    };
    const res = (await tranactionsServices
      .getTransactionsFile(params)
      .then((res) => res.data)) as any;
    const blob = new Blob(['\ufeff', res], { type: 'text/csv' });
    FileSaver.saveAs(blob, 'filename.csv');
  };

  clearFields = (isButton?: boolean) => {
    this.setBranchId('');
    this.setFrom(undefined);
    this.setTo(undefined);
    this.setOperatorId(undefined);
    this.setSkip(0);
    this.setTotalSum('');
    this.setTotalCount(0);
    this.setType(undefined);
    if (isButton) this.getTransactions();
  };
}

export default new TransactionsPageStore();
