import { courseServices } from 'api/services';
import { CourseType } from 'api/services/courses/types';
import { makeAutoObservable } from 'mobx';

class UpdateCoursePageStore {
  titleRu = '';
  titleUz = '';
  isActive = false;
  individual = false;
  categoryId?: number = undefined;
  type: CourseType = CourseType.THEORY;
  hasError = false;
  duration = '';
  loading = false;

  constructor() {
    makeAutoObservable(this);
  }

  setTitleRu = (value: string) => (this.titleRu = value);
  setTitleUz = (value: string) => (this.titleUz = value);
  setIsActive = (value: boolean) => (this.isActive = value);
  setIndividual = (value: boolean) => (this.individual = value);
  setType = (value: CourseType) => (this.type = value);
  setCategoryId = (value?: number) => (this.categoryId = value);
  setHasError = (value: boolean) => (this.hasError = value);
  setDuration = (value: string) => (this.duration = value);
  setLoading = (e: boolean) => (this.loading = false);

  clearFields = () => {
    this.setTitleRu('');
    this.setTitleUz('');
    this.setIsActive(false);
    this.setIndividual(false);
    this.setType(CourseType.THEORY);
    this.setCategoryId(undefined);
    this.setHasError(false);
    this.setDuration('');
    this.setLoading(false);
  };

  getCourseData = async (id: number) => {
    const res = await courseServices.getCourseById(id).then((res) => res.data);
    this.setTitleRu(res.titleRu);
    this.setTitleUz(res.titleUz);
    this.setIsActive(res.isActive);
    this.setIndividual(res.individual);
    this.setType(res.type);
    this.setCategoryId(res.categoryId);
    this.setDuration(res.duration.toString());
  };
}

export default new UpdateCoursePageStore();
