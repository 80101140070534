import React from 'react';
import { useNavigate } from 'react-router';
import cn from 'classnames'
import sidebarStore from '../../components/Sidebar/SidebarStore';
import Header from 'components/Header/Header';
import Sidebar from 'components/Sidebar/Sidebar';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import './Template.sass';
import { observer } from 'mobx-react-lite';

interface Props {
  children: React.ReactNode;
  btnUrl?: string;
  roleGuard?: boolean | null;
}

const Template: React.FC<Props> = observer(({ children, btnUrl, roleGuard }) => {
  const { isShowed } = sidebarStore
  const history = useNavigate();

  return (
    <>
      <Header />
      <div className='middle'>
        <div className='container middle__container'>
          <Sidebar />
          <div className={cn ('middle__content', {
            'middle__content--fluid' : isShowed
          })}>
            {btnUrl && roleGuard && (
              <div className='middle__button' onClick={() => history(btnUrl)}>
                <AddCircleIcon />
              </div>
            )}
            {children}
          </div>
        </div>
      </div>
    </>
  );
});

export default Template;
