export interface IIndividual {
  title: string;
  value: string;
}
export const individuals = [
  {
    title: 'Индивидуальные',
    value: 'true',
  },
  {
    title: 'Групповые',
    value: 'false',
  },
];
