import { contractsServices, studentsServices } from 'api/services';
import { IResContract } from 'api/services/contracts/types';
import { IResStudent } from 'api/services/students/types';
import { makeAutoObservable } from 'mobx';

class StudentContractsPageStore {
  studentContracts: IResContract[] = [];
  totalCount: number = 0;
  student?: IResStudent = undefined;
  params: {
    skip: number;
    take: number;
    studentId?: number;
  } = {
    skip: 0,
    take: 25,
  };
  constructor() {
    makeAutoObservable(this);
  }

  setStudentContracts = (contracts: IResContract[]) =>
    (this.studentContracts = contracts);

  setTake = (value: number) => (this.params.take = value);
  setSkip = (value: number) => (this.params.skip = value);
  setTotalCount = (value: number) => (this.totalCount = value);
  setStudentId = (id: number) => (this.params.studentId = id);
  setStudent = (student?: IResStudent) => (this.student = student);

  getStudent = async (id: number) => {
    const res = await studentsServices
      .getStudentById(id)
      .then((res) => res.data);
    this.setStudent(res);
  };

  getStudentContracts = async () => {
    if (!this.params.studentId) return;
    const res = await contractsServices
      .getContracts({
        ...this.params,
        skip: this.params.take * this.params.skip,
      })
      .then((res) => res.data);
    this.setStudentContracts(res.data);
    this.setTotalCount(res.count);
  };
}

export default new StudentContractsPageStore();
