import { CourseType } from 'api/services/courses/types';

export const translateCourseType = (type: CourseType) => {
  switch (type) {
    case CourseType.PILOT_RUN:
      return 'Пробный заезд';
    case CourseType.RUN:
      return 'Заезд';
    default:
      return 'Теория';
  }
};
