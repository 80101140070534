import { groupsServises } from 'api/services';
import { IResGroup } from 'api/services/groups/types';
import { makeAutoObservable } from 'mobx';
import ContractReportsPageStore from 'pages/ContractReportsPage/ContractReportsPageStore';
import CreateContractPageStore from 'pages/CreateContractPage/CreateContractPageStore';
import LessonsPageStore from 'pages/LessonsPage/LessonsPageStore';
import UpdateContractPageStore from 'pages/UpdateContractPage/UpdateContractPageStore';

class GroupsPageStore {
  groups = [] as IResGroup[];
  totalCount = 0;
  params = {
    branchId: '',
    teacherId: '',
    isFinished: '',
    page: 0,
    take: 25,
  };
  filters = {
    branchId: '',
    teacherId: '',
    isFinished: '',
    page: 0,
    take: 25,
  };

  setBranchId = (e: any) => {
    this.params.branchId = e;
  };
  setTeacherId = (e: any) => {
    this.params.teacherId = e;
  };
  setIsFinished = (e: any) => {
    this.params.isFinished = e;
  };
  setTake = (e: any) => {
    this.params.take = e;
  };
  setPage = (e: any) => {
    this.params.page = e;
  };
  constructor() {
    makeAutoObservable(this);
  }

  searchHandler = () => {
    this.filters = {
      branchId: this.params.branchId,
      teacherId: this.params.teacherId,
      isFinished: this.params.isFinished,
      page: 0,
      take: 25,
    };
  };

  clearHandler = () => {
    this.filters = {
      branchId: '',
      teacherId: '',
      isFinished: '',
      page: 0,
      take: 25,
    };
    this.params.branchId = '';
    this.params.teacherId = '';
    this.params.isFinished = '';
    this.params.page = 0;
    this.params.take = 25;
  };

  getGroups = async (id?: number, tariffId?: number, teacherId?: number) => {
    const search =
      CreateContractPageStore.searchGroup ||
      LessonsPageStore.searchGroup ||
      ContractReportsPageStore.searchGroup ||
      '';

    const params = {
      branchId: id
        ? id
        : !!this.params.branchId
        ? Number(this.params.branchId)
        : undefined,
      teacherId: teacherId
        ? Number(teacherId)
        : !!this.params.teacherId
        ? Number(this.params.teacherId)
        : undefined,
      isFinished:
        this.params.isFinished === 'false'
          ? false
          : this.params.isFinished === 'true'
          ? true
          : undefined,
      search: search.replace(/[^+\d]/g, ''),
      take: this.params.take,
      skip: this.params.take * this.params.page,
      tariffId: tariffId ? Number(tariffId) : undefined,
    };
    const res = await groupsServises.getGroups(params).then((res) => res.data);
    this.groups = res.data;
    this.totalCount = res.count;
  };
}

export default new GroupsPageStore();
