import cn from 'classnames';
import './Title.sass';

interface Props {
  title: string;
  className: string;
}

const Title: React.FC<Props> = ({ title, className }) => {
  return (
    <div
      className={cn('title', {
        [className]: className,
      })}
    >
      {title}
    </div>
  );
};

export default Title;
