import { CourseType } from 'api/services/courses/types';
import { translateCourseType } from 'helpers/translate';

export interface ICourseType {
  value: CourseType;
  title: string;
}
export const coursesTypes: ICourseType[] = [
  {
    value: CourseType.THEORY,
    title: translateCourseType(CourseType.THEORY),
  },
  {
    value: CourseType.RUN,
    title: translateCourseType(CourseType.RUN),
  },
  {
    value: CourseType.PILOT_RUN,
    title: translateCourseType(CourseType.PILOT_RUN),
  },
];
