import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import cn from 'classnames'
import './Modal.sass'

interface Props {
  children: React.ReactNode
  onClick?: () => void
  type?: 'white'
  onClose?: (count: number) => void
}

interface PublicFunctions {
  onShow(): void
  onHide(): void
}

const Modal: React.ForwardRefRenderFunction<PublicFunctions, Props> = (
  props,
  ref
) => {
  const [visibility, setVisibility] = useState(false)
  const [count, setCount] = useState(300)

  useEffect(() => {
    window.addEventListener('keydown', hideByKey)
    return () => window.removeEventListener('keydown', hideByKey)
  }, [])

  useImperativeHandle(ref, () => ({
    onShow,
    onHide,
  }))

  const onShow = () => {
    setVisibility(true)
  }

  const onHide = () => {
    setCount((prev) => prev + 1)
    props.onClose && props.onClose(count)
    setVisibility(false)
  }

  const hideByKey = (e: KeyboardEvent) => {
    setCount((prev) => prev + 1)
    if (e.keyCode === 27) {
      props.onClose && props.onClose(count)
      onHide()
    }
  }

  if (!visibility) return null

  return (
    <div className={cn ('modal', {
      'modal--white': props.type === 'white'
    })} onClick={props.onClick}>
      {props.children}
    </div>
  )
}

export default forwardRef(Modal)
