import { makeAutoObservable } from 'mobx';
import { GENDER } from 'refs/genders';

class CreateStudentPageStore {
  firstName = '';
  lastName = '';
  middleName = '';
  phone = '';
  gender = GENDER.MALE;
  passportSerial = '';
  passportNumber = '';
  pinfl = '';
  dateOfBirth = new Date();
  note = ''
  traffic = ''
  passportIssue = ''
  address = ''
  hasError = false;

  constructor() {
    makeAutoObservable(this);
  }

  setFirstName = (value: string) => (this.firstName = value);
  setLastName = (value: string) => (this.lastName = value);
  setMiddleName = (value: string) => (this.middleName = value);
  setPhone = (value: string) => (this.phone = value);
  setGender = (value: GENDER) => (this.gender = value);
  setPinfl = (value: string) => (this.pinfl = value);
  setPassportSerial = (value: string) => (this.passportSerial = value);
  setPassportNumber = (value: string) => (this.passportNumber = value);
  setHasError = (value: boolean) => (this.hasError = value);
  setDateOfBirth = (value: Date) => (this.dateOfBirth = value);
  setTraffic = (e: any) => {
    this.traffic = e
  }
  setNote = (value: string) => {
    this.note = value
  }
  setAddress = (e: string) => {
    this.address = e
  }
  setPassportIssue = (e: string) => {
    this.passportIssue = e
  }

  clearFields = () => {
    this.setFirstName('');
    this.setLastName('');
    this.setMiddleName('');
    this.setPhone('');
    this.setGender(GENDER.MALE);
    this.setPinfl('');
    this.setPassportSerial('');
    this.setPassportNumber('');
    this.setHasError(false);
    this.setDateOfBirth(new Date());
    this.setTraffic('')
    this.setNote('')
    this.setPassportIssue('')
    this.setAddress('')
  };
}

export default new CreateStudentPageStore();
