import showNotification from 'helpers/showNotification';
import { observer } from 'mobx-react-lite';
import AuthPageStore from 'pages/AuthPage/AuthPageStore';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import './NotFoundPage.sass';
import {
  isAccountant,
  isAdmin,
  isCashier,
  isGarage,
  isInstructor,
  isReferral,
  isRegistrator,
  isRegistratorWidthCashier,
  isSU,
  isTeacher,
} from 'helpers/checkRole';

export const NotFoundPage = observer(() => {
  const token = localStorage.getItem('accessToken');
  const { user } = AuthPageStore;
  const history = useNavigate();

  useEffect(() => {
    if (!token && !user) {
      showNotification('Необходима авторизация');
      history('/auth');
    }
  }, [token, user]);

  useEffect(() => {
    if (!user) return;
    if (
      isSU(user) ||
      isAdmin(user) ||
      isRegistrator(user) ||
      isGarage(user) ||
      isTeacher(user) ||
      isInstructor(user) ||
      isRegistratorWidthCashier(user) ||
      isReferral(user)
    ) {
      history('/lessons');
      return;
    }
    if (isCashier(user)) {
      history('/contracts');
      return;
    }
    if (isAccountant(user)) {
      history('/transactions');
      return;
    }
  }, [user]);

  return <></>;
});
