import { Routes, Route } from 'react-router-dom';
import profile from 'pages/AuthPage/AuthPageStore';
import { navigation } from './navigation';
import { observer } from 'mobx-react-lite';
import { NotFoundPage } from 'pages/NotFoundPage/NotFoundPage';

const AppRoutes: React.FC = observer(() => {
  return (
    <Routes>
      {navigation.map(
        (i) =>
          (i.roles?.some((ii) => ii === profile?.user?.role) ||
            (!profile.user && i.path === '/auth')) && (
            <Route path={i.path} element={i.component} key={i.path} />
          )
      )}
      <Route path='*' element={<NotFoundPage />} />
    </Routes>
  );
});

export default AppRoutes;
