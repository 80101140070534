import { contractsServices, tranactionsServices } from 'api/services';
import { IResBranch } from 'api/services/branches/types';
import { IResContract } from 'api/services/contracts/types';
import { IResGroup } from 'api/services/groups/types';
import { IResLesson } from 'api/services/lessons/types';
import { NotificationStatus } from 'components/Notification/types';
import showNotification from 'helpers/showNotification';
import clone from 'lodash/clone';
import { makeAutoObservable } from 'mobx';
import { contractStatuses } from 'refs/contractStatus';
import TariffsPageStore from 'pages/TariffsPage/TariffsPageStore'


class UpdateContractPageStore {
  contract = {} as IResContract
  lang = '';
  instructorGender = '';
  id = '';
  createdAt = '';
  cost = 0;
  totalSum = 0;
  value = '';
  type = '';
  loading = false;
  count = 0;
  user = '' as any;
  contractNumber = '';
  contractPrefix = '';
  status = '';
  statusIndex = '' as any;
  groupId?: number = undefined;
  group?: IResGroup = undefined;
  branch?: IResBranch = undefined;
  contractNum = ''
  cardNumber = ''
  tariffType = false

  individual = false
  lessons = [] as IResLesson[]
  lessonsInitial = [] as IResLesson[]

  constructor() {
    makeAutoObservable(this);
  }

  setGroup = (group: IResGroup) => (this.group = group);
  setBranch = (branch: IResBranch) => (this.branch = branch);
  setIndividual = (e: boolean) => {
    this.setIndividual(e)
  }
  setCardNumber = (e: any) => {
    this.cardNumber = e
  }

  getContractById = async (id: number) => {
    if(!id) return
    const res = await contractsServices
      .getContractById(id)
      .then((res) => res.data);
    this.contract = res
    this.lessons = !!res.lessons?.length ? res.lessons : []

    this.lang = res.lang;
    this.instructorGender = res.instructorGender ? res.instructorGender : '';
    this.createdAt = res.createdAt.toString();
    this.id = res.id.toString();
    this.cost = Number(res.tariff.cost) / 100;
    this.user = res.student;
    this.contractNumber = res.contractNumber.toString();
    this.contractNum = res.contractNumber.toString()
    this.contractPrefix = res.branch.contractPrefix;
    this.status = res.status;
    this.statusIndex = contractStatuses?.find(
      (i) => i.value === res.status
    )?.ind;
    this.setBranch(res.branch);
    let findTariff = TariffsPageStore?.tariffs?.find((i => i.id === Number(res.tariffId)))
    if(findTariff?.tariffCourses?.every((ii) => ii.course.individual)) {
      this.individual = true
    }
    if(findTariff?.tariffCourses?.every((ii) => ii.course.type === 'PILOT_RUN')) {
      this.tariffType = true
    }
    if (!!res.transactions.length) {
      this.totalSum = (res.tariff.cost - res.transactions[0].balance) / 100;
    } else {
      this.totalSum = res.tariff.cost / 100;
    }
    if (res.group) {
      this.setGroup(res.group);
      this.groupId = res.groupId
    }
  };

  paymentHandler = async () => {
    if(this.type === 'HUMO' && this.cardNumber.length !== 4){
      return
    }
    if(this.type === 'UZCARD' && this.cardNumber.length !== 4){
      return
    }
    const data = {
      amount: Number(this.value) * 100,
      type: this.type,
      contractId: Number(this.id),
      cardNumber: Number(this.cardNumber)
    };
    try {
      this.setLoading(true);
      await tranactionsServices.createTransaction(data);
      showNotification('Оплата прошла успешно', NotificationStatus.SUCCESS);
      this.clearFields();
      this.setCount();
    } catch (e: any) {
      showNotification('Ошибка, попроуйте позже');
    } finally {
      this.setLoading(false);
    }
  };

  setLessons = (e: any, index: number, type: 'teacherId' | 'datetime' | 'teacherName') => {
    const arr = clone(this.lessons);
    let findIndex = arr?.findIndex(
      (i: any, findIndex: number) => findIndex === index
    );
    let currentObj = arr?.find(
      (i: any, findIndex: number) => findIndex === index
    );
    if (currentObj && type === 'datetime') {
      currentObj.datetime = e;
      arr.splice(findIndex, 1, currentObj);
    }
    if (currentObj && type === 'teacherId') {
      currentObj.teacherId = e;
      arr.splice(findIndex, 1, currentObj);
    }
    if (currentObj && type === 'teacherName') {
      let teacher = {
        lastName: e
      }
      currentObj.teacher = teacher;
      arr.splice(findIndex, 1, currentObj);
    }
    this.lessons = [...arr];
  }

  setCount = () => {
    this.count = this.count + 1;
  };

  setType = (type: string) => {
    this.type = type;
  };

  setValue = (e: any) => {
    this.value = e;
  };

  sumHandler = () => {
    this.setValue(this.totalSum);
  };

  setLoading = (e: any) => {
    this.loading = e;
  };

  clearFields = () => {
    this.lang = '';
    this.instructorGender = '';
    this.id = '';
    this.createdAt = '';
    this.cost = 0;
    this.user = '';
    this.contractNumber = '';
    this.contractPrefix = '';
    this.status = '';
    this.statusIndex = '';
    this.totalSum = 0;
    this.value = '';
    this.type = '';
    this.loading = false;
    this.contractNum = ''
    this.contractNumber = ''
  };
}

export default new UpdateContractPageStore();
