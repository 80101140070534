import CloseIcon from '@mui/icons-material/CancelPresentation';
import CheckIcon from '@mui/icons-material/Check';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import FinalEvalModal from 'components/Modal/FinalEvalModal/FinalEvalModal';
import InitEvalModal from 'components/Modal/InitEvalModal/InitEvalModal';
import Modal from 'components/Modal/Modal';
import Statuses from 'components/Statuses/Statuses';
import Title from 'components/Title/Title';
import Template from 'layout/Template/Template';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import AuthPageStore from 'pages/AuthPage/AuthPageStore';
import { useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import UpdateLessonPageStore from './UpdateLessonPageStore';
import './UpdateLessonPage.sass';

const UpdateLessonPage: React.FC = observer(() => {
  const {
    getLessonById,
    attendanceHandler,
    contracts,
    lessons,
    loading,
    contractId,
    setContractId,
    clearFields,
    lessonType,
  } = UpdateLessonPageStore;
  const { user } = AuthPageStore;
  const { lessonId } = useParams<{ lessonId: string }>();
  const initEval = useRef<any>();
  const finalEval = useRef<any>();

  useEffect(() => {
    return () => {
      clearFields();
    };
  }, [clearFields]);

  useEffect(() => {
    if (!lessonId) return;
    getLessonById(Number(lessonId));
  }, [lessonId, getLessonById]);

  const openInitEval = (id: number) => {
    if (
      user?.role === 'SU' ||
      user?.role === 'TEACHER' ||
      user?.role === 'ADMIN'
    ) {
      setContractId(id);
      initEval.current.onShow();
    }
  };
  const openFinalEval = (id: number) => {
    if (
      user?.role === 'SU' ||
      user?.role === 'TEACHER' ||
      user?.role === 'ADMIN'
    ) {
      setContractId(id);
      finalEval.current.onShow();
    }
  };
  const hideEval = () => {
    setContractId(undefined);
    initEval.current.onHide();
    finalEval.current.onHide();
    if (!lessonId) return;
    getLessonById(Number(lessonId));
  };

  return (
    <>
      <Template>
        <div className='update-lesson-page'>
          <div className='update-lesson-page__row'>
            <Title
              title='Информация об уроке'
              className='update-lesson-page__title'
            />
            <Paper className='update-lesson-page__paper'>
              <Table className='update-lesson-page__table'>
                <TableHead>
                  <TableRow></TableRow>
                  <TableRow>
                    <TableCell>Тип урока: </TableCell>
                    <TableCell>
                      {lessonType === 'RUN'
                        ? 'Вождение'
                        : lessonType === 'PILOT_RUN'
                        ? 'Тестовый заезд'
                        : 'Теория'}
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </Paper>
          </div>
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Ф.И.О</TableCell>
                  <TableCell>Cтатус</TableCell>
                  <TableCell>Начальная оценка</TableCell>
                  {lessons.map((i) => (
                    <TableCell key={i.id}>
                      <div>{i.datetime ? moment(i.datetime).format('DD.MM.YYYY hh:mm') : '-'}</div>
                    </TableCell>
                  ))}
                  <TableCell>Финальная оценка</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {contracts.map((i) => (
                  <TableRow key={i.id}>
                    <TableCell className='update-lesson-page__td'>
                      {i.student.lastName} {i.student.firstName}{' '}
                      {i.student.middleName}
                    </TableCell>
                    <TableCell>
                      <Statuses status={i.status}></Statuses>
                    </TableCell>
                    <TableCell onClick={() => openInitEval(i.id)}>
                      {i.initEval ? `${i.initEval} %` : null}
                    </TableCell>
                    {lessons.map((ii) => (
                      <TableCell
                        key={ii.id}
                        onClick={() =>
                          attendanceHandler(
                            i?.attendance?.find((iii) => iii.lessonId === ii.id)
                              ?.value,
                            ii.id,
                            i.id,
                            i?.attendance?.find((iii) => iii.lessonId === ii.id)
                              ?.id
                          )
                        }
                      >
                        {i?.attendance?.find((iii) => iii.lessonId === ii.id)
                          ?.value === true && !loading ? (
                          <CheckIcon
                            sx={{ fontSize: 25, color: 'green' }}
                            className='update-lesson-page__checked-btn'
                            fontSize='large'
                          />
                        ) : i?.attendance?.find((iii) => iii.lessonId === ii.id)
                            ?.value === false && !loading ? (
                          <CloseIcon
                            sx={{ fontSize: 25, color: '#ff0000' }}
                            fontSize='large'
                            className='update-lesson-page__statues-btn'
                          />
                        ) : (
                          <></>
                        )}
                        {loading &&
                          i?.attendance?.some(
                            (iiii) => iiii.lessonId === ii.id
                          ) && <CircularProgress color='success' value={10} />}
                      </TableCell>
                    ))}
                    <TableCell onClick={() => openFinalEval(i.id)}>
                      {i.finalEval ? `${i.finalEval} %` : null}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </div>
      </Template>
      <Modal onClick={hideEval} ref={initEval}>
        <InitEvalModal hide={hideEval} id={contractId} />
      </Modal>
      <Modal onClick={hideEval} ref={finalEval}>
        <FinalEvalModal hide={hideEval} id={contractId} />
      </Modal>
    </>
  );
});

export default UpdateLessonPage;
