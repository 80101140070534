import { makeAutoObservable } from 'mobx';

class SidebarStore {
  isShowed = true;

  constructor() {
    makeAutoObservable(this);
  }

  handleVisibleSidebar = (showMenu: boolean) => {
    this.isShowed = showMenu;
    localStorage.setItem('showMenu', `${showMenu}`);
  };
}

export default new SidebarStore();
