import { makeAutoObservable } from "mobx"


class CreateCategoryPageStore {
  name = ''
  loading = undefined
  hasError = undefined

  constructor(){
    makeAutoObservable(this)
  }

  setName = (e: any) => {
    this.name = e
  }

  setLoading = (e: any) => {
    this.loading = e
  }

  setHasError = (e: any) => {
    this.hasError = e
  }

  clearFields = () => {
    this.setName('')
    this.setLoading(undefined)
    this.setHasError(undefined)
  }
}

export default new CreateCategoryPageStore()