export const contractStatuses = [
  {
    title: 'Новый',
    value: 'NEW',
    ind: 1,
    isActive: true
  },
  {
    title: 'Оплачен',
    value: 'PAYED',
    ind: 2,
    isActive: true

  },
  {
    title: 'Успешно окончен',
    value: 'FINISHED',
    ind: 3,
    isActive: true
  },
];
