import { contractsServices, refsServices } from 'api/services';
import {
  IContractReportFilter,
  IResContractReport,
} from 'api/services/contracts/types';
import { IResRef } from 'api/services/refs/types';
import showNotification from 'helpers/showNotification';
import { makeAutoObservable } from 'mobx';

class ContractReportsPageStore {
  contractReports: IResContractReport[] = [];
  params: IContractReportFilter = { skip: 0, take: 25 };
  totalCount = 0;
  referralUsers: IResRef[] = [];
  searchReferralUser: string = '';
  hasErrorFilter: boolean = false;
  searchGroup: string = '';

  constructor() {
    makeAutoObservable(this);
  }
  setContractReports = (reports: IResContractReport[]) =>
    (this.contractReports = reports);
  setParams = (params: IContractReportFilter) =>
    (this.params = { ...this.params, ...params });
  setTotalCount = (count: number) => (this.totalCount = count);
  setReferralUsers = (users: IResRef[]) => (this.referralUsers = users);
  setTake = (count: number) => (this.params.take = count);
  setSkip = (page: number) => (this.params.skip = page);
  setSearchReferralUser = (name: string) => (this.searchReferralUser = name);
  setHasErrorFilter = (value: boolean) => (this.hasErrorFilter = value);
  setSearchGroup = (value: string) => (this.searchGroup = value);

  clearFilter = (isButton?: boolean) => {
    this.setSearchReferralUser('');
    this.setParams({
      referralUserId: undefined,
      skip: 0,
      take: 25,
      to: '',
      from: '',
      trafficSource: '',
      gender: undefined,
      yearOfBirth: undefined,
      branchId: undefined,
      tariffId: undefined,
      categoryId: undefined,
      teacherId: undefined,
      status: undefined,
      groupId: undefined,
    });
    isButton && this.getContractReports();
  };

  getReferralUsers = async (value?: string) => {
    const res = await refsServices
      .getRefs({
        skip: 0,
        take: 25,
        search: value,
      })
      .then((res) => res.data);
    this.setReferralUsers(res.data);
  };

  getContractReports = async () => {
    if (
      (this.params.to && !this.params.from) ||
      (!this.params.to && this.params.from)
    ) {
      this.setHasErrorFilter(true);
      showNotification('Необходимо заполнить "от и до" поля');
      return;
    }
    const res = await contractsServices
      .getContractReports({
        ...this.params,
        skip: this.params.take * this.params.skip,
      })
      .then((res) => res.data);
    this.setContractReports(res.data);
    this.setTotalCount(res.count);
  };
}

export default new ContractReportsPageStore();
