export interface INotification {
  message: string;
  status: NotificationStatus;
  id?: string;
}

export enum NotificationStatus {
  WARNING = 'WARNING',
  SUCCESS = 'SUCCESS',
  INFO = 'INFO',
}
