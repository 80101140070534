import { useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router';
import { observer } from 'mobx-react-lite';
import cn from 'classnames';
import moment from 'moment';
import Template from 'layout/Template/Template';
import ContractModal from 'components/Modal/ContractModal/ContractModal';
import LessonModal from 'components/Modal/LessonModal/LessonModal';
import Modal from 'components/Modal/Modal';
import ScheduleModal from 'components/Modal/ScheduleModal/ScheduleModal';
import Title from 'components/Title/Title';
import AuthPageStore from 'pages/AuthPage/AuthPageStore';
import TariffsPageStore from 'pages/TariffsPage/TariffsPageStore';
import { contractStatuses } from 'refs/contractStatus';
import UpdateContractPageStore from './UpdateContractPageStore';
import NoteModal from 'components/Modal/NoteModal/NoteModal';
import PrintModal from 'components/Modal/PirntModal/PirntModal'
import SettingsIcon from '@mui/icons-material/Settings';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import PrintIcon from '@mui/icons-material/Print';
import { Button, Grid, TextField } from '@mui/material';
import './UpdateContractPage.sass';

const UpdateContractPage: React.FC = observer(() => {
  const {
    contract,
    contractNumber,
    contractPrefix,
    lang,
    instructorGender,
    createdAt,
    totalSum,
    status,
    value,
    setValue,
    type,
    setType,
    sumHandler,
    paymentHandler,
    loading,
    count,
    clearFields,
    groupId,
    user,
    statusIndex,
    group,
    branch,
    contractNum,
    cardNumber,
    setCardNumber,
    tariffType,
    individual,
    lessons,
    setLessons,
    getContractById,
  } = UpdateContractPageStore;
  const { tariffs } = TariffsPageStore;
  const { user: profile } = AuthPageStore;
  const { contractId } = useParams<{ contractId: string }>();
  const history = useNavigate();
  const ref = useRef<any>();
  const contractRef = useRef<any>();
  const scheduleRef = useRef<any>();
  const noteModalref = useRef<any>();

  useEffect(() => {
    return () => {
      clearFields();
    };
  }, [clearFields]);

  useEffect(() => {
    if (!contractId) return;
    getContractById(Number(contractId));
  }, [getContractById, contractId, count]);

  const showLessonModal = () => {
    ref.current.onShow();
  };

  const showNoteModal = () => {
    noteModalref.current.onShow();
  };

  const hideNoteModal = () => {
    noteModalref.current.onHide();
  };

  const hideLessonModal = () => {
    if (!contractId) return;
    getContractById(Number(contractId));
    ref.current.onHide();
  };

  const showModal = () => {
    contractRef.current.onShow();
  };

  const hideModal = () => {
    if (!contractId) return;
    getContractById(Number(contractId));
    contractRef.current.onHide();
  };

  const showSchedule = () => {
    scheduleRef.current.onShow();
  };

  const hideSchedule = () => {
    scheduleRef.current.onHide();
  };

  const showPrint = () => {
    setTimeout(() => {
      window.print()
    }, 0)
  }

  return (
    <>
      <Template>
        <div className='update-contract-page'>
          <div className='update-contract-page__top'>
            <Title
              title={`Контракт № ${contractNum}`}
              className='update-contract-page__title'
            />
            <div className='update-contract-page__wrap'>
              <SettingsIcon className='update-contract-page__settings' />
              {(profile?.role === 'ADMIN' ||
                profile?.role === 'SU' ||
                profile?.role === 'REGISTRATOR' ||
                profile?.role === 'REGISTRATOR_WITH_CASHIER' ||
                profile?.role === 'REFERRAL') && (
                <div className='update-contract-page__window'>
                  <div
                    className='update-contract-page__item'
                    onClick={showModal}
                  >
                    Изменить контракт
                  </div>
                  {!!lessons.length && (
                    <div
                      className='update-contract-page__item'
                      onClick={showLessonModal}
                    >
                      Изменить расписание
                    </div>
                  )}
                  <div
                    className='update-contract-page__item'
                    onClick={showSchedule}
                  >
                    Просмотреть расписание
                  </div>
                </div>
              )}
            </div>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <div className='update-contract-page__statuses'>
                {contractStatuses.map((i, index) => (
                  <div
                    className={cn('update-contract-page__statuses-item', {
                      'update-contract-page__statuses-item--active':
                        status === i.value,
                      'update-contract-page__statuses-item--completed':
                        statusIndex > i.ind,
                    })}
                    key={i.value}
                  >
                    <div className='update-contract-page__statuses-number'>
                      {index + 1}
                    </div>
                    <div className='update-contract-page__statuses-text'>
                      {i.title}
                    </div>
                  </div>
                ))}
                {!!contract && !contract.isActive && (
                  <div className='update-contract-page__statuses-item update-contract-page__statuses-item--disabled'>
                    <div className='update-contract-page__statuses-number'>
                      4
                    </div>
                    <div className='update-contract-page__statuses-text'>
                      Не активен
                    </div>
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={8}>
              <div className='update-contract-page__inner'>
                <div className='update-contract-page__row'>
                  <div className='update-contract-page__subtitle'>Оплатить</div>
                  <div>
                    <PrintIcon
                      onClick={showPrint}
                      sx={{ color: '#4acbce', fontSize: 35 }}
                      className='update-contract-page__note'
                    />
                    <NoteAddIcon
                      onClick={showNoteModal}
                      sx={{ color: '#4acbce', fontSize: 35 }}
                      className='update-contract-page__note'
                    />

                  </div>
                </div>
                <div className='update-contract-page__in'>
                  <table>
                    <thead>
                      {branch && (
                        <tr>
                          <td>Филиал :</td>
                          <td>{branch?.name}</td>
                        </tr>
                      )}
                      <tr>
                        <td>Номер контракта :</td>
                        <td>{contractNumber}</td>
                      </tr>
                      <tr>
                        <td>Префикс контракта :</td>
                        <td>{contractPrefix}</td>
                      </tr>
                      <tr>
                        <td>Дата создания :</td>
                        <td>{moment(createdAt).format('DD.MM.YYYY')}</td>
                      </tr>
                      <tr>
                        <td>Ф.И.О :</td>
                        <td>
                          {user?.firstName} {user?.lastName} {user?.middleName}
                        </td>
                      </tr>
                      <tr>
                        <td>Серия/Номер паспорта/ПИНФЛ :</td>
                        <td>
                          {user?.passportSerial} / {user?.passportNumber} /{' '}
                          {user?.pinfl}
                        </td>
                      </tr>
                      <tr>
                        <td>Тариф :</td>
                        <td>
                          {
                            tariffs?.find(
                              (i) => i.id === Number(contract.tariffId)
                            )?.titleRu
                          }
                        </td>
                      </tr>
                      <tr>
                        <td>Язык :</td>
                        <td>{lang}</td>
                      </tr>
                      <tr>
                        <td>Пол инструктора :</td>
                        <td>
                          {instructorGender === 'MALE' ? 'Мужчина' : 'Женщина'}
                        </td>
                      </tr>
                      {!!group && (
                        <tr>
                          <td>Группа :</td>
                          <td>{`№${groupId} от ${moment(group.createdAt).format(
                            'DD.MM.YYYY HH:mm'
                          )} `}</td>
                        </tr>
                      )}
                    </thead>
                  </table>
                  {(profile?.role === 'SU' ||
                    profile?.role === 'CASHIER' ||
                    profile?.role === 'REGISTRATOR_WITH_CASHIER') && (
                    <Grid
                      container
                      spacing={2}
                      className='update-contract-page__inputs'
                    >
                      <Grid item xs={6}>
                        <TextField
                          value={value}
                          onChange={(e) => setValue(e.target.value)}
                          placeholder='Введите сумму'
                          size='small'
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          value={cardNumber}
                          onChange={(e) => setCardNumber(e.target.value)}
                          placeholder='Номер карты'
                          size='small'
                          inputProps={{ maxLength: 4 }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Button size='small' onClick={sumHandler}>
                          Вся сумма
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </div>
                {(profile?.role === 'SU' ||
                  profile?.role === 'CASHIER' ||
                  profile?.role === 'REGISTRATOR_WITH_CASHIER') && (
                  <>
                    <div className='update-contract-page__label'>
                      Выбирите тип оплаты
                    </div>
                    <div className='update-contract-page__actions'>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <div className='update-contract-page__methods'>
                            <div
                              className={cn('update-contract-page__pay', {
                                'update-contract-page__pay--active':
                                  type === 'CASH',
                              })}
                              onClick={() => setType('CASH')}
                            >
                              НАЛИЧНЫЕ
                            </div>
                            <div
                              className={cn('update-contract-page__pay', {
                                'update-contract-page__pay--active':
                                  type === 'UZCARD',
                              })}
                              onClick={() => setType('UZCARD')}
                            >
                              UZCARD
                            </div>
                            <div
                              className={cn('update-contract-page__pay', {
                                'update-contract-page__pay--active':
                                  type === 'HUMO',
                              })}
                              onClick={() => setType('HUMO')}
                            >
                              HUMO
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </>
                )}
              </div>
              {(profile?.role === 'SU' ||
                profile?.role === 'CASHIER' ||
                profile?.role === 'REGISTRATOR_WITH_CASHIER') && (
                <Grid
                  container
                  spacing={2}
                  className='update-contract-page__buttons'
                >
                  <Grid item xs={6}>
                    <Button
                      color='inherit'
                      size='small'
                      onClick={() => history(-1)}
                    >
                      Назад
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      onClick={paymentHandler}
                      color='success'
                      size='small'
                      disabled={
                        Number(value) === 0 ||
                        Number(value) > totalSum ||
                        !type.trim() ||
                        loading
                      }
                    >
                      Оплатить
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </div>
      </Template>
      {!!lessons.length && (
        <Modal onClick={hideLessonModal} ref={ref}>
          <LessonModal
            lessons={lessons}
            setLessons={setLessons}
            hide={hideLessonModal}
          />
        </Modal>
      )}
      <Modal onClick={hideModal} ref={contractRef}>
        <ContractModal data={contract} hide={hideModal} />
      </Modal>
      <Modal onClick={hideSchedule} ref={scheduleRef}>
        <ScheduleModal data={contract} hide={hideSchedule} />
      </Modal>
      <Modal ref={noteModalref} onClick={hideNoteModal}>
        <NoteModal studentId={user?.id} data={user.note} hide={hideNoteModal} />
      </Modal>
      <PrintModal oneTime={tariffType} issued={user.passportIssue} address={user.address} passport={`${user.passportSerial}/${user.passportNumber}`} name={`${user.firstName} ${user.lastName} ${user.middleName? user.middleName : ''}`}/>
    </>
  );
});

export default UpdateContractPage;
