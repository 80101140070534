import { categoriesServises } from 'api/services';
import { IResCategory } from 'api/services/categories/types';
import { makeAutoObservable } from 'mobx';

class CategoriesPageStore {
  categories = [] as IResCategory[];

  constructor() {
    makeAutoObservable(this);
  }

  getData = async () => {
    if(this.categories.length) return
    try {
      const res = await categoriesServises
        .getCategories()
        .then((res) => res.data.sort((a, b) => a.name.localeCompare(b.name)));
      this.categories = res;
    } catch (e: any) {}
  };

  getCategoryBydId = (id: number) => {
    return this.categories.find((i) => i.id === id)?.name || ''; 
  };
}

export default new CategoriesPageStore();
