import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import Title from 'components/Title/Title';
import Template from 'layout/Template/Template';
import BranchesPageStore from './BranchesPageStore';
import regionsStore from 'refs/regionsStore';
import showNotification from 'helpers/showNotification';
import { isAdmin, isSU } from 'helpers/checkRole';
import AuthPageStore from 'pages/AuthPage/AuthPageStore';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import './BranchesPage.sass';

export const BranchesPage: React.FC = observer(() => {
  const {
    branches,
    params,
    setRegionId,
    setSearchValue,
    getBranches,
    clearFields,
  } = BranchesPageStore;
  const { regions, getRegionNameById } = regionsStore;
  const { user } = AuthPageStore;
  const history = useNavigate();

  useEffect(() => {
    return () => {
      clearFields();
    };
  }, [clearFields]);

  useEffect(() => {
    getBranches()
  }, [getBranches])

  const formSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!!params.searchValue?.trim() && params.searchValue?.length < 3) {
      showNotification('Для поиска введите 3 или более символов');
      return;
    }
    getBranches();
  };

  return (
    <Template
      btnUrl='/branches/create'
      roleGuard={(user && isSU(user)) || (user && isAdmin(user))}
    >
      <div className='branches-page'>
        <Title title='Филиалы' className='branches-page__title' />
        <form onSubmit={formSubmit}>
          <Grid container spacing={2} justifyContent='flex-end'>
            <Grid item xs={4}>
              <TextField
                value={params.searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                size='small'
                label='Поиск'
                autoComplete='off'
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth size='small'>
                <InputLabel variant='outlined'>Выберите регион</InputLabel>
                <Select
                  label={'Выберите регион'}
                  value={params.regionId}
                  onChange={(e) => setRegionId(Number(e.target.value))}
                  size='small'
                >
                  {regions?.map((i) => (
                    <MenuItem key={i.id} value={i.id}>
                      {i.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4} justifyContent='flex-end'>
              <div className='row'>
                <Button
                  className='branches-page__button'
                  color='primary'
                  size='small'
                  type='submit'
                >
                  Найти
                </Button>
                <Button
                  className='branches-page__button'
                  color='error'
                  size='small'
                  type='button'
                  onClick={() => clearFields(true)}
                >
                  Очистить
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>

        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Название</TableCell>
                <TableCell>Регион</TableCell>
                <TableCell>Префикс контракта</TableCell>
                <TableCell>Дата изменения</TableCell>
                {(user?.role === 'ADMIN') || (user?.role === 'SU') && (
                  <TableCell>
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {branches.map((i) => (
                <TableRow
                  key={i.id}
                >
                  <TableCell>{i.id}</TableCell>
                  <TableCell>{i.name}</TableCell>
                  <TableCell>{getRegionNameById(i.regionId)}</TableCell>
                  <TableCell>{i.contractPrefix}</TableCell>
                  <TableCell>
                    {moment(i.updatedAt).format('DD.MM.YYYY')}
                  </TableCell>
                  {(user?.role === 'ADMIN') || (user?.role === 'SU') && (
                    <TableCell>
                      <Link to={`/branches/${i.id}/update`} className="go-to">
                        <ArrowForwardIcon />
                      </Link>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
    </Template>
  );
});
