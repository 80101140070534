import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { observer } from 'mobx-react-lite';
import { Confirmation } from 'components/Confirmation/Confirmation';
import { Notification } from 'components/Notification/Notification';
import AuthPageStore from 'pages/AuthPage/AuthPageStore';
import CategoriesPageStore from 'pages/CategoriesPage/CategoriesPageStore';
import BranchesPageStore from 'pages/BranchesPage/BranchesPageStore';
import TariffsPageStore from 'pages/TariffsPage/TariffsPageStore';
import 'assets/styles/main.sass';

interface Props {
  children: React.ReactNode;
}

const App: React.FC<Props> = observer(({ children }) => {
  const history = useNavigate();
  const { pathname } = useLocation();

  const token = localStorage.getItem('accessToken');
  const { user } = AuthPageStore;
  useEffect(() => {
    if (!token && !user) {
      history('/auth');
    }
  }, [token, user]);

  useEffect(() => {
    if (token && user && !CategoriesPageStore.categories.length)
      CategoriesPageStore.getData();
  }, [CategoriesPageStore.categories.length, token, user?.id]);
  useEffect(() => {
    if (token && user && !BranchesPageStore.branches.length)
      BranchesPageStore.getBranches();
  }, [user]);

  useEffect(() => {
    if (token && user && !TariffsPageStore.tariffs.length) {
      TariffsPageStore.getTariffs();
    }
  }, [user, token]);

  return (
    <>
      <Notification />
      <Confirmation />
      {children}
    </>
  );
});

export default App;
