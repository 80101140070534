import { observer } from 'mobx-react-lite';
import Template from 'layout/Template/Template';
import Title from 'components/Title/Title';
import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import CreateCourseStore from './CreateCoursePageStore';
import showNotification from 'helpers/showNotification';
import { courseServices } from 'api/services';
import { NotificationStatus } from 'components/Notification/types';
import { ICourse, CourseType } from 'api/services/courses/types';
import { Stack } from '@mui/system';
import CategoriesPageStore from 'pages/CategoriesPage/CategoriesPageStore';
import { coursesTypes } from 'refs/courseTypes';
import './CreateCoursePage.sass';

export const CreateCoursePage: React.FC = observer(() => {
  const {
    titleRu,
    setTitleRu,
    titleUz,
    setTitleUz,
    isActive,
    setIsActive,
    type,
    setType,
    individual,
    setIndividual,
    categoryId,
    setCategoryId,
    hasError,
    setHasError,
    clearFields,
    duration,
    setDuration,
  } = CreateCourseStore;
  const history = useNavigate();

  const createCourse = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!titleRu.trim() || !titleUz.trim()) {
      showNotification('Заполните поля');
      setHasError(true);
      return;
    }
    if (type !== CourseType.THEORY && !categoryId) {
      showNotification('Укажите категорию');
      setHasError(true);
      return;
    }
    try {
      const data: ICourse = {
        titleRu,
        titleUz,
        isActive,
        individual,
        type,
        duration: Number(duration),
        ...(categoryId ? { categoryId } : {}),
      };
      await courseServices.createCourse(data);
      showNotification('Курс успешно создан', NotificationStatus.SUCCESS);
      clearFields();
      history('/courses');
    } catch (e: any) {
      showNotification('Ошибка попробуйте позже');
    }
  };

  const selectType = (value: CourseType) => {
    if (CourseType.THEORY === value) setCategoryId(undefined);
    setType(value);
  };

  const back = () => {
    clearFields();
    history('/courses');
  };

  return (
    <Template>
      <form className='create-course-page' onSubmit={createCourse}>
        <Title title='Создать курс' className='create-course-page__title' />
        <TextField
          className='create-course-page__field'
          value={titleRu}
          onChange={(e) => setTitleRu(e.target.value)}
          autoComplete='off'
          placeholder='Название (ру)'
          error={hasError && !titleRu.trim()}
        />
        <TextField
          className='create-course-page__field'
          value={titleUz}
          onChange={(e) => setTitleUz(e.target.value)}
          autoComplete='off'
          placeholder='Название (уз)'
          error={hasError && !titleUz.trim()}
        />
        <div className='create-course-page__flex'>
          <FormGroup>
            <FormControlLabel
              label='Активный'
              control={
                <Switch
                  onChange={(e) => setIsActive(e.target.checked)}
                  checked={isActive}
                />
              }
            />
          </FormGroup>
          <FormGroup className='create-course-page__switch'>
            <Stack direction='row' spacing={1} alignItems='center'>
              <span
                className='create-course-page__switch-text'
                onClick={() => setIndividual(true)}
              >
                Индивидуальный
              </span>
              <Switch
                onChange={(e) => setIndividual(!e.target.checked)}
                checked={!individual}
              />
              <span
                className='create-course-page__switch-text'
                onClick={() => setIndividual(false)}
              >
                Групповой
              </span>
            </Stack>
          </FormGroup>
        </div>
        <FormControl
          fullWidth
          size='small'
          className='create-course-page__field'
        >
          <InputLabel variant='outlined'>Тип курса</InputLabel>
          <Select
            label={'Тип курса'}
            value={type}
            onChange={(e) => selectType(e.target.value as CourseType)}
            size='small'
          >
            {coursesTypes?.map((i) => (
              <MenuItem key={i.value} value={i.value}>
                {i.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {type !== CourseType.THEORY && (
          <FormControl
            fullWidth
            size='small'
            className='create-course-page__field'
            error={hasError && !categoryId}
          >
            <InputLabel variant='outlined'>Выберите категорию</InputLabel>
            <Select
              label={'Выберите категорию'}
              value={categoryId || ''}
              onChange={(e) => setCategoryId(Number(e.target.value))}
              size='small'
            >
              {CategoriesPageStore.categories?.map((i) => (
                <MenuItem key={i.id} value={i.id}>
                  {i.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <TextField
          className='create-course-page__field'
          value={duration}
          onChange={(e) => setDuration(e.target.value)}
          autoComplete='off'
          placeholder='Длительность (мин)'
          type='number'
          error={hasError && !duration.trim()}
        />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button color='primary' size='medium' type='submit'>
              Сохранить
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button color='inherit' onClick={back}>
              Назад
            </Button>
          </Grid>
        </Grid>
      </form>
    </Template>
  );
});
