import { makeAutoObservable } from "mobx"


class LessonModalStore {
  users = []
  loading = {
    loading: false,
    id: ''
  }

  setUsers = (e: any) => {
    this.users = e
  }
  setLoading = (e: any, id: number | string) => {
    this.loading = {
      loading: e,
      id: id.toString()
    }
  }

  constructor () {
    makeAutoObservable(this)
  }
}

export default new LessonModalStore