export enum GENDER {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export interface IGender {
  value: GENDER;
  title: string;
}

export const genders: IGender[] = [
  {
    value: GENDER.FEMALE,
    title: 'Женщина',
  },
  {
    value: GENDER.MALE,
    title: 'Мужчина',
  },
];
