import AssessmentIcon from '@mui/icons-material/Assessment';
import ArticleIcon from '@mui/icons-material/Article';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CategoryIcon from '@mui/icons-material/Category';
import CommentBankIcon from '@mui/icons-material/CommentBank';
import Groups2Icon from '@mui/icons-material/Groups2';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import SchoolIcon from '@mui/icons-material/School';
import TableChartIcon from '@mui/icons-material/TableChart';
import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { AuthPage } from 'pages/AuthPage/AuthPage';
import { BranchesPage } from 'pages/BranchesPage/BranchesPage';
import CategoriesPage from 'pages/CategoriesPage/CategoriesPage';
import ContractsPage from 'pages/ContractsPage/ContractsPage';
import { CoursesPage } from 'pages/CoursesPage/CoursesPage';
import { CreateBranchPage } from 'pages/CreateBranchPage/CreateBranchPage';
import CreateCategoryPage from 'pages/CreateCategoryPage/CreateCategoryPage';
import CreateContractPage from 'pages/CreateContractPage/CreateContractPage';
import { CreateCoursePage } from 'pages/CreateCoursePage/CreateCoursePage';
import CreateGroupPage from 'pages/CreateGroupPage/CreateGroupPage';
import CreateRefPage from 'pages/CreateRefPage/CreateRefPage';
import { CreateStudentPage } from 'pages/CreateStudentPage/CreateStudentPage';
import CreateTariffPage from 'pages/CreateTariffPage/CreateTariffPage';
import CreateUserPage from 'pages/CreateUserPage/CreateUserPage';
import GroupsPage from 'pages/GroupsPage/GroupsPage';
import { LessonsPage } from 'pages/LessonsPage/LessonsPage';
import RefsPage from 'pages/RefsPage/RefsPage';
import StudentContractsPage from 'pages/StudentContractsPage/StudentContractsPage';
import { StudentsPage } from 'pages/StudentsPage/StudentsPage';
import StudentTimeline from 'pages/StudentTimeline/StudentTimeline';
import TariffsPage from 'pages/TariffsPage/TariffsPage';
import { TransactionsPage } from 'pages/TransactionsPage/TransactionsPage';
import { UpdateBranchPage } from 'pages/UpdateBranchPage/UpdateBranchPage';
import UpdateCategoryPage from 'pages/UpdateCategoryPage/UpdateCategoryPage';
import UpdateContractPage from 'pages/UpdateContractPage/UpdateContractPage';
import { UpdateCoursePage } from 'pages/UpdateCoursePage/UpdateCoursePage';
import UpdateGroupPage from 'pages/UpdateGroupPage/UpdateGroupPage';
import UpdateLessonIndividual from 'pages/UpdateLessonIndividual/UpdateLessonIndividual';
import UpdateLessonPage from 'pages/UpdateLessonPage/UpdateLessonPage';
import UpdateRefsPage from 'pages/UpdateRefsPage/UpdateRefsPage';
import { UpdateStudentPage } from 'pages/UpdateStudentPage/UpdateStudentPage';
import UpdateTariffPage from 'pages/UpdateTariffPage/UpdateTariffPage';
import UpdateUserPage from 'pages/UpdateUserPage/UpdateUserPage';
import UsersPage from 'pages/UsersPage/UsersPage';
import React from 'react';
import { ROLE } from '../refs/roles';
import { ContractReportsPage } from 'pages/ContractReportsPage/ContractReportsPage';

interface INavigation {
  path: string;
  component: React.ReactNode;
  icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  roles?: ROLE[];
  label?: string;
}

export const navigation: INavigation[] = [
  //dev
  // {
  //   path: '/',
  //   component: <LessonsPage />,
  //   roles: [
  //     ROLE.SU,
  //     ROLE.ADMIN,
  //     ROLE.REGISTRATOR,
  //     ROLE.GARAGE,
  //     ROLE.TEACHER,
  //     ROLE.INSTRUCTOR,
  //     ROLE.REGISTRATOR_WITH_CASHIER,
  //   ],
  // },
  {
    path: '/lessons',
    component: <LessonsPage />,
    roles: [
      ROLE.SU,
      ROLE.ADMIN,
      ROLE.REGISTRATOR,
      ROLE.GARAGE,
      ROLE.TEACHER,
      ROLE.INSTRUCTOR,
      ROLE.REGISTRATOR_WITH_CASHIER,
      ROLE.REFERRAL
    ],
    label: 'Расписание',
    icon: CalendarMonthIcon,
  },
  {
    path: '/lessons/:lessonId',
    component: <UpdateLessonIndividual />,
    roles: [
      ROLE.SU,
      ROLE.ADMIN,
      ROLE.REGISTRATOR,
      ROLE.GARAGE,
      ROLE.TEACHER,
      ROLE.INSTRUCTOR,
      ROLE.REGISTRATOR_WITH_CASHIER,
      ROLE.REFERRAL,
    ],
  },
  {
    path: '/lessons/:lessonId/group',
    component: <UpdateLessonPage />,
    roles: [
      ROLE.SU,
      ROLE.ADMIN,
      ROLE.REGISTRATOR,
      ROLE.GARAGE,
      ROLE.TEACHER,
      ROLE.INSTRUCTOR,
      ROLE.REGISTRATOR_WITH_CASHIER,
      ROLE.REFERRAL,
    ],
  },
  //test courses path
  {
    path: '/courses',
    component: <CoursesPage />,
    roles: [
      ROLE.SU,
      ROLE.ADMIN,
    ],
    icon: LocalLibraryIcon,
    label: 'Курсы',
  },
  {
    path: '/courses/create',
    component: <CreateCoursePage />,
    roles: [ROLE.SU, ROLE.ADMIN],
  },
  {
    path: '/courses/:id/update',
    component: <UpdateCoursePage />,
    roles: [
      ROLE.SU,
      ROLE.ADMIN,
      ROLE.REGISTRATOR,
      ROLE.GARAGE,
      ROLE.TEACHER,
      ROLE.INSTRUCTOR,
    ],
  },
  {
    path: '/auth',
    component: <AuthPage />,
  },
  {
    path: '/users',
    component: <UsersPage />,
    roles: [ROLE.SU, ROLE.ADMIN],
    label: 'Пользователи',
    icon: PersonSearchIcon,
  },
  {
    path: '/users/create',
    component: <CreateUserPage />,
    roles: [ROLE.SU, ROLE.ADMIN],
    icon: AssessmentIcon,
  },
  {
    path: '/users/:userId',
    component: <UpdateUserPage />,
    roles: [ROLE.SU, ROLE.ADMIN],
    icon: AssessmentIcon,
  },
  {
    path: '/branches',
    component: <BranchesPage />,
    roles: [ROLE.SU, ROLE.ADMIN],
    icon: TableChartIcon,
    label: 'Филиалы',
  },
  {
    path: '/branches/create',
    component: <CreateBranchPage />,
    roles: [ROLE.SU, ROLE.ADMIN],
  },
  {
    path: '/branches/:id/update',
    component: <UpdateBranchPage />,
    roles: [ROLE.SU, ROLE.ADMIN],
  },
  {
    path: '/categories',
    component: <CategoriesPage />,
    roles: [ROLE.SU],
    icon: CategoryIcon,
    label: 'Категориии',
  },
  {
    path: '/categories/create',
    component: <CreateCategoryPage />,
    roles: [ROLE.SU],
  },
  {
    path: '/categories/:categoryId',
    component: <UpdateCategoryPage />,
    roles: [ROLE.SU],
  },
  {
    path: '/tariffs',
    component: <TariffsPage />,
    roles: [
      ROLE.SU,
      ROLE.ADMIN,
      ROLE.REGISTRATOR,
      ROLE.GARAGE,
      ROLE.TEACHER,
      ROLE.INSTRUCTOR,
      ROLE.REGISTRATOR_WITH_CASHIER,
    ],
    label: 'Тарифы',
    icon: PersonSearchIcon,
  },
  {
    path: '/tariffs/create',
    component: <CreateTariffPage />,
    roles: [ROLE.SU, ROLE.ADMIN],
    icon: PersonSearchIcon,
  },
  {
    path: '/tariffs/:tariffId',
    component: <UpdateTariffPage />,
    roles: [
      ROLE.SU,
      ROLE.ADMIN,
      ROLE.REGISTRATOR,
      ROLE.GARAGE,
      ROLE.TEACHER,
      ROLE.INSTRUCTOR,
      ROLE.REGISTRATOR_WITH_CASHIER,
    ],
    icon: PersonSearchIcon,
  },
  {
    path: '/contracts',
    component: <ContractsPage />,
    roles: [
      ROLE.SU,
      ROLE.ADMIN,
      ROLE.REGISTRATOR,
      ROLE.REGISTRATOR_WITH_CASHIER,
      ROLE.REFERRAL,
      ROLE.CASHIER,
    ],
    label: 'Контракты',
    icon: NoteAltIcon,
  },
  {
    path: '/contracts/:contractId',
    component: <UpdateContractPage />,
    roles: [
      ROLE.SU,
      ROLE.ADMIN,
      ROLE.REGISTRATOR,
      ROLE.REGISTRATOR_WITH_CASHIER,
      ROLE.REFERRAL,
      ROLE.CASHIER,
    ],
    icon: PersonSearchIcon,
  },
  {
    path: '/contract-reports',
    label: 'Отчеты по контрактам',
    component: <ContractReportsPage />,
    roles: [ROLE.SU, ROLE.ADMIN],
    icon: ArticleIcon,
  },
  {
    path: '/students',
    component: <StudentsPage />,
    roles: [
      ROLE.SU,
      ROLE.ADMIN,
      ROLE.REGISTRATOR,
      ROLE.REGISTRATOR_WITH_CASHIER,
    ],
    icon: SchoolIcon,
    label: 'Студенты',
  },
  {
    path: '/students/create',
    component: <CreateStudentPage />,
    roles: [
      ROLE.SU,
      ROLE.ADMIN,
      ROLE.REGISTRATOR,
      ROLE.REGISTRATOR_WITH_CASHIER,
    ],
  },
  {
    path: '/students/:studentId/create-contract',
    component: <CreateContractPage />,
    roles: [
      ROLE.SU,
      ROLE.ADMIN,
      ROLE.REGISTRATOR,
      ROLE.REGISTRATOR_WITH_CASHIER,
    ],
    icon: PersonSearchIcon,
  },
  {
    path: '/students/:studentId/update',
    component: <UpdateStudentPage />,
    roles: [
      ROLE.SU,
      ROLE.ADMIN,
      ROLE.REGISTRATOR,
      ROLE.REGISTRATOR_WITH_CASHIER,
    ],
  },
  {
    path: '/students/:studentId/contracts',
    component: <StudentContractsPage />,
    roles: [
      ROLE.SU,
      ROLE.ADMIN,
      ROLE.REGISTRATOR,
      ROLE.REGISTRATOR_WITH_CASHIER,
      ROLE.REFERRAL,
    ],
  },
  {
    path: '/students/:studentId/timeline',
    component: <StudentTimeline />,
    roles: [
      ROLE.SU,
      ROLE.ADMIN,
      ROLE.REGISTRATOR,
      ROLE.REGISTRATOR_WITH_CASHIER,
      ROLE.REFERRAL,
    ],
  },
  {
    path: '/transactions',
    component: <TransactionsPage />,
    roles: [
      ROLE.SU,
      ROLE.ADMIN,
      ROLE.ACCOUNTANT,
    ],
    icon: CommentBankIcon,
    label: 'Транзакции',
  },
  {
    path: '/groups',
    component: <GroupsPage />,
    roles: [
      ROLE.SU,
      ROLE.ADMIN,
      ROLE.REGISTRATOR,
      ROLE.TEACHER,
      ROLE.REGISTRATOR_WITH_CASHIER,
    ],
    icon: Groups2Icon,
    label: 'Группы',
  },
  {
    path: '/groups/create',
    component: <CreateGroupPage />,
    roles: [
      ROLE.SU,
      ROLE.ADMIN,
      ROLE.REGISTRATOR,
      ROLE.REGISTRATOR_WITH_CASHIER,
    ],
    icon: Groups2Icon,
  },
  {
    path: '/groups/:groupId',
    component: <UpdateGroupPage />,
    roles: [
      ROLE.SU,
      ROLE.ADMIN,
      ROLE.REGISTRATOR,
      ROLE.REGISTRATOR_WITH_CASHIER,
    ],
    icon: Groups2Icon,
  },
  {
    path: '/refs',
    component: <RefsPage />,
    roles: [ROLE.SU, ROLE.ADMIN, ROLE.REFERRAL],
    label: 'Реферальные пользователи',
    icon: CalendarMonthIcon,
  },
  {
    path: '/refs/create',
    component: <CreateRefPage />,
    roles: [ROLE.SU, ROLE.ADMIN, ROLE.REFERRAL],
  },
  {
    path: '/refs/:refId',
    component: <UpdateRefsPage />,
    roles: [ROLE.SU, ROLE.ADMIN, ROLE.REFERRAL],
  },
];
