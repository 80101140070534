import instance from 'api/instance';
import {
  ICreateCourse,
  IDeleteCourse,
  IGetCourse,
  IGetCourseById,
  IUpdateCourse,
} from './types';
import qs from 'query-string';

export const getCourses: IGetCourse = (params) =>
  instance.get(`/courses?${qs.stringify(params, { skipEmptyString: true })}`);

export const getCourseById: IGetCourseById = (id) =>
  instance.get(`/courses/${id}`);

export const createCourse: ICreateCourse = (data) =>
  instance.post('/courses', data);

export const updateCourse: IUpdateCourse = (id, data) =>
  instance.put(`/courses/${id}`, data);

export const deleteCourse: IDeleteCourse = (id) =>
  instance.delete(`/courses/${id}`);
