import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Template from 'layout/Template/Template';
import Title from 'components/Title/Title';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import showNotification from 'helpers/showNotification';
import InputMask from 'react-input-mask';
import { studentsServices } from 'api/services';
import { NotificationStatus } from 'components/Notification/types';
import { IStudent } from 'api/services/students/types';
import UpdateStudentPageStore from './UpdateStudentPageStore';
import DatePicker, { registerLocale } from 'react-datepicker';
import { GENDER, genders } from 'refs/genders';
import ru from 'date-fns/locale/ru';
import 'react-datepicker/dist/react-datepicker.css';
import ConfirmationStore from 'components/Confirmation/ConfirmationStore';
import { trafficSource } from 'refs/trafficSource';
import './UpdateStudentPage.sass';

export const UpdateStudentPage: React.FC = observer(() => {
  registerLocale('ru', ru);
  const {
    middleName,
    firstName,
    lastName,
    pinfl,
    gender,
    passportSerial,
    passportNumber,
    phone,
    dateOfBirth,
    setDateOfBirth,
    setMiddleName,
    setLastName,
    setFirstName,
    setPinfl,
    setGender,
    setPhone,
    setPassportNumber,
    setPassportSerial,
    note,
    setNote,
    traffic,
    setTraffic,
    address,
    setAddress,
    passportIssue,
    setPassportIssue,
    hasError,
    setHasError,
    getStudentById,
    clearFields,
  } = UpdateStudentPageStore;
  const history = useNavigate();
  const { studentId } = useParams<{ studentId: string }>();

  useEffect(() => {
    if(!studentId) return
    getStudentById(Number(studentId));
  }, [studentId, getStudentById]);

  const updateStudent = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!studentId) return;
    if (
      !firstName.trim() ||
      !lastName.trim() ||
      !middleName.trim() ||
      !pinfl.trim() ||
      !passportSerial.trim() ||
      !passportNumber.trim() ||
      !phone.trim() || 
      !address.trim() || 
      !passportIssue.trim()
    ) {
      showNotification('Необходимо заполнить все поля');
      setHasError(true);
      return;
    }
    try {
      const data: IStudent = {
        firstName,
        lastName,
        middleName,
        phone: phone.replaceAll(' ', '').replace('+', ''),
        pinfl,
        gender,
        passportSerial: passportSerial.toUpperCase(),
        passportNumber,
        dateOfBirth: new Date(dateOfBirth),
        note,
        trafficSource: traffic,
        passportIssue,
        address
      };
      await studentsServices
        .updateStudent(Number(studentId), data)
        .then((res) => res.data);
      showNotification('Студен успешно изменен', NotificationStatus.SUCCESS);
      clearFields();
      history(`/students`);
    } catch (e: any) {
      showNotification('Ошибка попробуйте позже');
    }
  };
  const back = () => {
    clearFields();
    history('/students');
  };

  const confirmDeleteStudent = () => {
    const message = 'Вы действительно хотите удалить данные этого студента?';
    const deleteStudent = async () => {
      if (!studentId) return;
      clearFields();
      try {
        await studentsServices.deleteStudent(Number(studentId));
        history('/students/');
      } catch {
        showNotification('Ошибка попробуйте позже');
      }
    };
    ConfirmationStore.showConfirm(message, deleteStudent);
  };

  const changePinfl = (value: string) => {
    if (pinfl.length >= 14 && value.length >= 14) return;
    setPinfl(value);
  };

  return (
    <Template>
      <form className='update-student-page' onSubmit={updateStudent}>
        <Title
          title='Редактировать данные студента'
          className='update-student-page__title'
        />
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Link to={`/students/${studentId}/create-contract`}>
              <Button
                className='update-student-page__contract'
                size='small'
                color='success'
              >
                Создать контракт
              </Button>
            </Link>
          </Grid>
          <Grid item xs={4}>
            <Link
              to={`/students/${studentId}/contracts`}
            >
              <Button
                size='small'
              >
                Просмотреть контракты
              </Button>
            </Link>
          </Grid>
          <Grid item xs={4}>
            <Link
              to={`/students/${studentId}/timeline`}
            >
              <Button
                size='small'
              >
                Журнал
              </Button>
            </Link>
          </Grid>
        </Grid>
        <TextField
          className='update-student-page__field'
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          autoComplete='off'
          placeholder='Фамилия'
          error={hasError && !lastName.trim()}
        />
        <TextField
          className='update-student-page__field'
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          autoComplete='off'
          placeholder='Имя'
          error={hasError && !firstName.trim()}
        />
        <TextField
          className='update-student-page__field'
          value={middleName}
          onChange={(e) => setMiddleName(e.target.value)}
          autoComplete='off'
          placeholder='Отчество'
          error={hasError && !middleName.trim()}
        />
        <DatePicker
          selected={dateOfBirth}
          dateFormat='dd.MM.yyyy'
          locale='ru'
          autoComplete='off'
          calendarClassName='date-picker'
          onChange={(date: any) => setDateOfBirth(date)}
          customInput={
            <TextField
              className='update-student-page__field'
              autoComplete='off'
            />
          }
        />
        <TextField
          className='update-student-page__field'
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          autoComplete='off'
          placeholder='Адрес'
          error={hasError && !address.trim()}
        />
        <TextField
          className='update-student-page__field'
          value={pinfl}
          onChange={(e) => changePinfl(e.target.value)}
          autoComplete='off'
          type='number'
          placeholder='ПИНФЛ'
          error={hasError && !pinfl.trim()}
        />
        <div className='update-student-page__flex'>
          <TextField
            value={passportSerial}
            onChange={(e) => setPassportSerial(e.target.value)}
            autoComplete='off'
            placeholder='Серия паспорта'
            error={hasError && !passportSerial.trim()}
          />
          <TextField
            value={passportNumber}
            onChange={(e) => setPassportNumber(e.target.value)}
            autoComplete='off'
            placeholder='Номер паспорта'
            error={hasError && !passportNumber.trim()}
          />
        </div>
        <TextField
          className='update-student-page__field'
          value={passportIssue}
          onChange={(e) => setPassportIssue(e.target.value)}
          autoComplete='off'
          placeholder='Кем выдан'
          error={hasError && !passportIssue.trim()}
        />
        <FormControl
          fullWidth
          size='small'
          className='update-student-page__field'
        >
          <InputLabel variant='outlined'>Пол</InputLabel>
          <Select
            label={'Пол'}
            value={gender}
            onChange={(e) => setGender(e.target.value as GENDER)}
            size='small'
          >
            {genders?.map((i) => (
              <MenuItem key={i.value} value={i.value}>
                {i.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <InputMask
          mask='+\9\9\8 ** *** ** **'
          value={phone}
          onChange={(e: any) => setPhone(e.target.value)}
          placeholder='Номер телефона'
        >
          {(inputProps: any) => (
            <TextField
              className='update-student-page__field'
              autoComplete='off'
              {...inputProps}
              error={hasError && !phone.trim()}
            />
          )}
        </InputMask>
        <FormControl fullWidth className='contract-modal__field'>
          <InputLabel variant='outlined'>Как узнали об экпр. курсах</InputLabel>
          <Select
            label={'Как узнали об экпр. курсах'}
            value={traffic}
            onChange={(e) => setTraffic(e.target.value)}
          >
            {trafficSource?.map((i) => (
              <MenuItem key={i.value} value={i.value}>
                {i.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <textarea
          className="create-student-page__field"
          value={note}
          onChange={(e) => setNote(e.target.value)}
          autoComplete='off'
          placeholder='Примечание'
        />
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Button color='primary' size='medium' type='submit'>
              Сохранить
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button color='inherit' onClick={back}>
              Назад
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              color='inherit'
              onClick={confirmDeleteStudent}
              type='button'
              className='delete-button'
            >
              Удалить
            </Button>
          </Grid>
        </Grid>
      </form>
    </Template>
  );
});
