import { ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import notification from './NotificationStore';
import { NotificationStatus } from './types';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import './Notification.sass';

export const Notification: React.FC = observer(() => {
  const { message, status, isShow } = notification;

  if (!isShow) return null;

  const checkNotificationStatus = (): ReactNode => {
    switch (status) {
      case NotificationStatus.INFO:
        return <InfoIcon />;
      case NotificationStatus.SUCCESS:
        return <CheckCircleIcon />;
      default:
        return <CancelIcon />;
    }
  };

  return (
    <div className={`notification notification--${status.toLocaleLowerCase()}`}>
      <div className='notification__message'>
        {checkNotificationStatus()}
        <span>{message}</span>
      </div>
    </div>
  );
});
