import { IResLesson } from 'api/services/lessons/types';
import { makeAutoObservable } from 'mobx';

class LessonsStore {
  isHidden: boolean = false;
  lessonsWithoutDatetime: IResLesson[] = [];
  selectedFilterId?: number;
  filteredLessons: IResLesson[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setIsHidden = () => (this.isHidden = !this.isHidden);
  setSelectedFilterId = (id?: number) => (this.selectedFilterId = id);
  setFilteredLessons = (lessons: IResLesson[]) =>
    (this.filteredLessons = lessons);
  setLessonsWithoutDatetime = (lessons: IResLesson[]) =>
    (this.lessonsWithoutDatetime = lessons);
}

export default new LessonsStore();
