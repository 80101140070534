import { regionServices } from 'api/services';
import { IResRegion } from 'api/services/regions/types';
import { makeAutoObservable } from 'mobx';

class RegionsStore {
  regions: IResRegion[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setRegions = (value: IResRegion[]) => {
    this.regions = value;
  };

  getRegions = async () => {
    const res = await regionServices.getRegions().then((res) => res.data);
    this.regions = res;
  };

  getRegionNameById = (id: number) =>
    this.regions.find((i) => i.id === id)?.name || '';
}

export default new RegionsStore();
