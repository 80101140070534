import instance from 'api/instance';
import {
  IGetLesson,
  ICreateLesson,
  IUpdateLesson,
  IGetLessonById,
  IGetLessonsCount,
} from './types';
import qs from 'query-string';

export const getLessons: IGetLesson = (params) =>
  instance.get(`/lessons?${qs.stringify(params, { skipEmptyString: true })}`);

export const getLessonsCount: IGetLessonsCount = (params) =>
  instance.get(
    `/lessons/count?${qs.stringify(params, { skipEmptyString: true })}`
  );

export const getLessonById: IGetLessonById = (id) =>
  instance.get(`/lessons/${id}`);

export const createLesson: ICreateLesson = (data) =>
  instance.post(`lessons`, data);

export const updateLesson: IUpdateLesson = (id, data) =>
  instance.put(`/lessons/${id}`, data);

export const deleteLesson = (id: number) => instance.delete(`/lessons/${id}`);
