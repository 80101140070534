import { groupsServises, refsServices, studentsServices, userServices } from 'api/services';
import { IResGroup } from 'api/services/groups/types';
import { IResUser } from 'api/services/users/types';
import { IResRef } from 'api/services/refs/types';
import { makeAutoObservable } from 'mobx';

class CreateContractPageStore {
  tariffId = '';
  lang = '';
  instructorGender = '';
  branchId = '';
  groupId?: number = undefined;
  individual = false;
  teacherId = ''
  searchTeacher = ''
  instructorId = ''
  searchInstructor = ''
  users = [] as IResUser[]
  instructors = [] as IResUser[]
  groups = [] as IResGroup[]
  searchGroup = '';
  referralUserId = undefined as number | undefined;
  referralUserSearch = ''
  refs = [] as IResRef[]
  loading = false;
  hasError = false;

  constructor() {
    makeAutoObservable(this);
  }

  setTariffId = (e: any) => {
    this.tariffId = e;
  };
  setLang = (e: any) => {
    this.lang = e;
  };
  setBranchId = (e: any) => {
    this.branchId = e;
  };
  setLoading = (e: any) => {
    this.loading = e;
  };
  setHasError = (e: any) => {
    this.hasError = e;
  };
  setTeacherId = (e: any) => {
    this.teacherId = e
  }
  setInstructorId = (e: any) => {
    this.instructorId = e
  } 
  setIndividual = (value: boolean) => (this.individual = value);
  setGroupId = (value?: number) => {
    this.groupId = value
  };
  setReferralUserId = (value: any) => {
    this.referralUserId = value
  }
  setInstructorGender = (e: any) => {
    this.instructorGender = e;
  };
  setSearchInstructor = (e: string) => {
    this.searchInstructor = e
  }
  setSearchGroup = (value: string) => (this.searchGroup = value);
  setSearchTeacher = (e: string) => {
    this.searchTeacher = e
  } 
  setReferralUserSearch = (e: string) => {
    this.referralUserSearch = e
  }

  getRefs = async (value?: string) => {
    const params = {
      skip: 0,
      take: 10,
      search: value
    }
    const res = await refsServices.getRefs(params).then((res) => res.data)
    this.refs = res.data
  }

  getGroups = async (value?: string) => {
    if(!this.branchId) return
    const params = {
      branchId: Number(this.branchId),
      teacherId: this.teacherId ? Number(this.teacherId) : undefined,
      isFinished: false,
      search: value ? value : '',
      take: 15,
      skip: 0,
      tariffId: this.tariffId ? Number(this.tariffId) : undefined,
    };
    const res = await groupsServises.getGroups(params).then((res) => res.data);
    this.groups = res.data
  };

  getUsers = async (value: string, role: string) => {
    const params = {
      skip: 0,
      take: 10,
      search: value,
      role: role === 'TEACHER' ? 'TEACHER' : 'INSTRUCTOR',
      gender: role === 'TEACHER' ? '' : this.instructorGender,
      isActive: true
    } 
    const res = await userServices.getUsers(params).then((res) => res.data)
    if(role === 'TEACHER'){
      this.users = res.data
    }else {
      this.instructors = res.data
    }
  }

  clearFields = () => {
    this.tariffId = '';
    this.lang = '';
    this.instructorGender = '';
    this.branchId = '';
    this.searchGroup = '';
    this.groupId = undefined;
    this.individual = false;
    this.loading = false;
    this.hasError = false;

    this.teacherId = ''
    this.searchTeacher = ''
    this.instructorId = ''
    this.searchInstructor = ''
    this.users = [] as IResUser[]
    this.instructors = [] as IResUser[]
    this.groups = [] as IResGroup[]
    this.searchGroup = '';
    this.referralUserId = undefined
    this.referralUserSearch = ''
  };
}

export default new CreateContractPageStore();
