import { makeAutoObservable } from 'mobx';
import { userServices } from 'api/services';
import { IResUser } from 'api/services/users/types';

class UsersPageStore {
  data = [] as IResUser[];
  totalCount = 0;
  params = {
    role: '',
    branchId: '',
    search: '',
    gender: '',
    isActive: '' as any,
    page: 0,
    take: 25,
  };
  filters = {
    role: '',
    branchId: '',
    search: '',
    gender: '',
    isActive: '',
    page: 0,
    take: 25,
  };

  constructor() {
    makeAutoObservable(this);
  }

  setRole = (e: any) => {
    this.params.role = e;
  };
  setBranchId = (e: any) => {
    this.params.branchId = e;
  };
  setSearch = (e: any) => {
    this.params.search = e;
  };
  setGender = (e: any) => {
    this.params.gender = e;
  };
  setIsActive = (e: any) => {
    this.params.isActive = e;
  };
  setTake = (e: any) => {
    this.params.take = e;
  };
  setPage = (e: any) => {
    this.params.page = e;
  };

  searchHandler = () => {
    this.filters = {
      role: this.params.role,
      branchId: this.params.branchId,
      search: this.params.search,
      gender: this.params.gender,
      isActive: this.params.isActive,
      page: this.params.page,
      take: this.params.take,
    };
  };

  clearHandler = () => {
    this.filters = {
      role: '',
      branchId: '',
      search: '',
      gender: '',
      isActive: '',
      page: 0,
      take: 25,
    };
    this.setRole('');
    this.setBranchId('');
    this.setSearch('');
    this.setGender('');
    this.setIsActive('');
    this.setPage(0);
  };

  getUsers = async () => {
    const params = {
      role: this.params.role,
      branchId: this.params.branchId,
      search: this.params.search,
      gender: this.params.gender,
      isActive:
        this.params.isActive === 'false'
          ? false
          : this.params.isActive === 'true'
          ? true
          : undefined,
      take: this.params.take,
      skip: this.params.take * this.params.page,
    };
    try {
      const res = await userServices.getUsers(params).then((res) => res.data);
      this.data = res.data;
      this.totalCount = res.count;
    } catch (e) {}
  };
}

export default new UsersPageStore();
