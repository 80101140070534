import instance from 'api/instance';
import qs from 'query-string';
import {
  ICreateBranch,
  IDeleteBranch,
  IGetBranch,
  IGetBranchById,
  IUpdateBranch,
} from './types';

export const getBranches: IGetBranch = (data) =>
  instance.get(`/branches?${qs.stringify(data)}`);

export const getBranchById: IGetBranchById = (id) =>
  instance.get(`/branches/${id}`);

export const createBranch: ICreateBranch = (data) =>
  instance.post('/branches', data);

export const updateBranch: IUpdateBranch = (id, data) =>
  instance.put(`/branches/${id}`, data);

export const deleteBranch: IDeleteBranch = (id) =>
  instance.delete(`/branches/${id}`);
