import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as Logo } from 'assets/images/logo.svg';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import './Header.sass';
import AuthPageStore from 'pages/AuthPage/AuthPageStore';
import ConfirmationStore from 'components/Confirmation/ConfirmationStore';

const Header: React.FC = () => {
  const history = useNavigate();

  const exit = () => {
    const message = 'Вы действительно хотите выйти?';
    const signOut = () => {
      history('/auth');
      AuthPageStore.signOut();
    };
    ConfirmationStore.showConfirm(message, signOut);
  };

  return (
    <header className='header'>
      <div className='container'>
        <div className='header__container'>
          <Link to='/'>
            <Logo className='header__logo' />
          </Link>
          <ExitToAppIcon
            onClick={exit}
            className='header__logout'
            sx={{ fontSize: 40, color: '#4ACBCE' }}
          />
        </div>
      </div>
    </header>
  );
};

export default Header;
