import { ReactComponent as AuthIcon } from 'assets/images/sign-in.svg';
import './AuthTemplate.sass';

interface Props {
  children: any;
  label: string;
}

const AuthTemplate: React.FC<Props> = ({ children, label }) => {
  return (
    <div className='auth-template'>
      <div className='auth-template__window'>
        <AuthIcon className='auth-template__icon' />
        <div className='auth-template__title'>{label}</div>
        {children}
      </div>
    </div>
  );
};

export default AuthTemplate;
