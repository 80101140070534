import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { observer } from "mobx-react-lite";
import { tariffsServises } from "api/services";
import UpdateTariffPageStore from "./UpdateTariffPageStore";
import AuthPageStore from "pages/AuthPage/AuthPageStore";
import CoursesPageStore from "pages/CoursesPage/CoursesPageStore";
import Title from "components/Title/Title";
import Template from "layout/Template/Template";
import { Button, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, Select, Switch, TextField } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import './UpdateTariffPage.sass'


const UpdateTariffPage: React.FC = observer(() => {
  const { loading, setLoading, hasError, setHasError, cost, setCost, isActive, setIsActive, titleRu, setTitleRu, titleUz, setTitleUz, getTariffById, coursesData, courseChange, clearFields, addCourses, deleteHandler } = UpdateTariffPageStore
  const { tariffId } = useParams<{ tariffId: string }>();
  const {
    getCourses,
    courses,
  } = CoursesPageStore;
  const history = useNavigate()
  const { user } = AuthPageStore

  useEffect(() => {
    getCourses()
  }, [getCourses])

  useEffect(() => {
    if(!tariffId) return
    getTariffById(Number(tariffId))
  }, [tariffId, getTariffById])

  const updateTariff = async (e: React.FormEvent) => {
    e.preventDefault()
    let validation = true

    if(!titleRu.trim() || !titleUz.trim() || !cost.trim()){
      validation = false
    }

    if(!validation){
      setHasError(true)
      return
    }
    
    const data = {
      titleUz,
      titleRu,
      cost: Number(cost) * 100,
      isActive,
      courses: coursesData.map((i: any) => ({
        id: i.id,
        count: i.count,
        courseId: i.courseId
      }))
    }

    try {
      setLoading(true)
      await tariffsServises.updateTariff(Number(tariffId), data)
      clearFields();
      history('/tariffs')
    } catch(e) {

    } finally {
      setLoading(false)
    }
  }

  return (
    <Template>
      <div className="update-tariff-page">
        <Title title="Обновить тариф" className="update-tariff-page__title"/>
        <form onSubmit={updateTariff}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  onChange={(e) => setIsActive(e.target.checked)}
                  checked={isActive}
                />
              }
              label='Активность'
            />
          </FormGroup>
          <TextField
            className='update-tariff-page__field'
            value={titleRu}
            onChange={(e) => setTitleRu(e.target.value)}
            placeholder='Название ру'
            error={hasError && !titleRu.trim()}
          />
          <TextField
            className='update-tariff-page__field'
            value={titleUz}
            onChange={(e) => setTitleUz(e.target.value)}
            placeholder='Название уз'
            error={hasError && !titleUz.trim()}
          />
          <TextField
            className='update-tariff-page__field'
            value={cost}
            onChange={(e) => setCost(e.target.value)}
            placeholder='Цена'
            type='number'
            error={hasError && !cost.trim()}
          />
          <div className="update-tariff-page__add-wrap">
            <div className="update-tariff-page__add-label">Добавить курс</div>
            <Button 
              type="button" 
              color="success" 
              className="update-tariff-page__add" 
              size="small" 
              onClick={addCourses}
              startIcon={<AddCircleIcon/>}
            >
            </Button>
          </div>
          {!!coursesData.length && coursesData.map((i: any, index: number) => (
            <Grid container spacing={2} key={index}>
              <Grid item xs={5}>
                <TextField
                  className='create-tariff-page__field'
                  value={coursesData[index].count}
                  onChange={(e) => courseChange(e.target.value, index, 'text')}
                  placeholder='Кол-во'
                />
              </Grid>
              {!!courses.length && (
                <Grid item xs={5}>
                  <FormControl fullWidth className='create-tariff-page__field'>
                    <InputLabel variant='outlined'>Выберите курс</InputLabel>
                    <Select
                      label={'Выберите курс'}
                      value={coursesData[index].courseId}
                      onChange={(e) => courseChange(e.target.value, index, 'select')}
                    >
                      {courses?.map((ii) => (
                        <MenuItem key={ii.id} value={ii.id}>
                          {ii.titleRu}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={2}>
                <Button startIcon={<DeleteForeverIcon/>} className="create-tariff-page__delete" size="small" color="error" onClick={() => deleteHandler(index)}></Button>
              </Grid>
            </Grid>
          ))}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button type="submit" color='primary' size='medium' disabled={(user?.role !== 'ADMIN' && user?.role !== 'SU') || loading }>
                Сохранить
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Link to='/tariffs'>
                <Button color='inherit'>Назад</Button>
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Template>
  )
})

export default UpdateTariffPage