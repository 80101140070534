import React from 'react'
import { observer } from 'mobx-react-lite'
import showNotification from 'helpers/showNotification'
import { contractsServices } from 'api/services'
import InitEvalModalStore from './InitEvalModalStore'
import ModalTemplate from 'layout/ModalTemplate/ModalTemplate'
import { NotificationStatus } from 'components/Notification/types'
import { Button, TextField } from '@mui/material'
import './InitEvalModal.sass'

interface Props {
  hide: () => void
  id?: number
}

const InitEvalModal: React.FC<Props> = observer(({hide, id}) => {
  const { initEval, setInitEval, loading, setLoading, hasError, setHasError  } = InitEvalModalStore

  const createEval = async (e: React.FormEvent) => {
    e.preventDefault()
    if(!id) return
    if(!initEval.trim()){
      setHasError(true)
      showNotification('Поля не заполнены')
      return
    }
    const data = {
      initEval: Math.round((Number(initEval) * 100) / 20)
    }

    try { 
      setLoading(true)
      await contractsServices.updateContractEval(id, data)
      setHasError(false)
      showNotification('Оценка успешно выставлена', NotificationStatus.SUCCESS);
      hide()
    } catch (e: any) {
      showNotification('Ошибка попробуйте позже');
    } finally {
      setLoading(false)
    }
  }

  return(
    <ModalTemplate title="Текущая оценка" hide={hide}>
      <form className="init-eval-modal" onSubmit={createEval}>
        <TextField 
          className="init-eval-modal__field"
          value={initEval}
          type="number"
          onChange={(e) => setInitEval(e.target.value)}
          placeholder='Кол-во правильных ответов из 20'
          error={hasError && !initEval.trim()}
        />
        <TextField 
          className="init-eval-modal__field init-eval-modal__field--disabled"
          value={'Из 20'}
          type="number"
          placeholder='Из 20'
        />
        <Button type="submit" color='primary' size='medium' disabled={loading}>
          Сохранить
        </Button>
      </form>
    </ModalTemplate>
  )
})

export default InitEvalModal