import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import Template from 'layout/Template/Template';
import Title from 'components/Title/Title';
import ContractReportsPageStore from './ContractReportsPageStore';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import './ContractReportsPage.sass';
import { trafficSource, translateTrafficSource } from 'refs/trafficSource';
import { genders } from 'refs/genders';
import { IResTariffCourse } from 'api/services/tariffs/types';
import { contractStatuses } from 'refs/contractStatus';
import { IResContractReport } from 'api/services/contracts/types';
import { IResTransaction } from 'api/services/transactions/types';
import { sumAmount } from 'helpers/sumAmount';
import { CustomSearchableSelect } from 'components/CustomSearchableSelect/CustomSearchableSelect';
import TariffsPageStore from 'pages/TariffsPage/TariffsPageStore';
import CategoriesPageStore from 'pages/CategoriesPage/CategoriesPageStore';
import { ContractStatus } from 'refs/contractStatuses';
import BranchesPageStore from 'pages/BranchesPage/BranchesPageStore';
import UsersPageStore from 'pages/UsersPage/UsersPageStore';
import { ROLE } from 'refs/roles';
import GroupsPageStore from 'pages/GroupsPage/GroupsPageStore';
import { contractsServices } from 'api/services';
import FileSaver from 'file-saver';
import { TransactionType, transactionTypes } from 'refs/transactionTypes';
import CheckIcon from '@mui/icons-material/Check';
import { IResLesson } from 'api/services/lessons/types';
import { CourseType } from 'api/services/courses/types';
import _ from 'lodash';

export const getYimStatusText = (statusName: string): string => {
  switch (statusName) {
    case 'CREATED':
      return 'Создан';
    case 'NOT_VERIFIED':
      return 'Не верефицирован';
    case 'NEED_PAYMENT_FOR_THEORY':
      return 'Оплатите экзамен (теория)';
    case 'PASS_THEORY_EXAM':
      return 'Сдайте экзамен (теория)';
    case 'THEORY_EXAM_FAILED':
      return 'Вы провалили экзамен (теория)';
    case 'NEED_PAYMENT_FOR_PRACTICE':
      return 'Оплатите экзамен (практика)';
    case 'PASS_PRACTICE_EXAM':
      return 'Сдайте экзамен (практика)';
    case 'PRACTICE_EXAM_FAILED':
      return 'Вы провалили экзамен (практика)';
    case 'SUCCESSFULLY_COMPLETED':
      return 'Успешно окончен';
    default:
      return '';
  }
};

const referrals: {
  title: string;
  value: string;
}[] = [
  {
    title: 'Есть',
    value: 'true',
  },
  {
    title: 'Нет',
    value: 'false',
  },
];

const confrimReferrals: {
  title: string;
  value: string;
}[] = [
  {
    title: 'Подтвержден',
    value: 'true',
  },
  {
    title: 'Не подтвержден',
    value: 'false',
  },
];

export const ContractReportsPage: React.FC = observer(() => {
  const {
    contractReports,
    params,
    totalCount,
    clearFilter,
    setSkip,
    setTake,
    setParams,
    getContractReports,
    referralUsers,
    getReferralUsers,
    searchReferralUser,
    setSearchReferralUser,
    hasErrorFilter,
    setSearchGroup,
    searchGroup,
  } = ContractReportsPageStore;
  const {
    tariffs,
    params: tariffParams,
    setSearch,
    getTariffs,
  } = TariffsPageStore;

  const { categories } = CategoriesPageStore;
  const { branches } = BranchesPageStore;
  const {
    data: users,
    getUsers,
    params: usersParams,
    setSearch: setUsersSearch,
    setRole,
  } = UsersPageStore;
  const { groups, getGroups } = GroupsPageStore;

  useEffect(() => {
    getContractReports();
  }, [params.skip, params.take]);

  useEffect(() => {
    getReferralUsers();
    setRole(ROLE.TEACHER);
    getUsers();
    getGroups();
    return () => {
      setSearch('');
      setUsersSearch('');
      setRole(undefined);
      setSearchGroup('');
      clearFilter();
    };
  }, []);

  const mergeCategories = (tarifCourses?: IResTariffCourse[]) => {
    if (!tarifCourses) return '';
    return tarifCourses
      .filter((i) => i.course?.category?.name)
      .map((i) => i.course?.category?.name)
      .join(', ');
  };

  const checkRun = (lessons: IResLesson[]) => {
    const practiceAttendances = lessons
      .filter(
        (ii) =>
          (ii.type === CourseType.RUN || ii.type === CourseType.PILOT_RUN) &&
          ii.attendance
      )
      .map((ii) => ii.attendance);
    if (!practiceAttendances?.length || !practiceAttendances) return;
    return `Всего: (${practiceAttendances.length})<br> ${practiceAttendances
      ?.map(
        (ii: any) =>
          `${ii?.value ? '!' : 'X'} ${moment(ii.createdAt).format(
            'DD.MM.YYYY'
          )}`
      )
      .join(',</br>')}`;
  };

  const yimHistory = (report: IResContractReport) => {
    const parsedStudentContracts =
      report.student.contracts?.map((ii) => ({
        date: ii.createdAt,
        text: ii.tariff.titleRu,
      })) || [];
    const parsedYimSH =
      report.student.yimStatusHistory?.map((ii) => ({
        date: ii.shCreatedAt,
        text: `${getYimStatusText(ii.oldStatus)}->${getYimStatusText(
          ii.newStatus
        )} (${ii.categoryName})`,
      })) || [];
    const history = _.sortBy(
      [...parsedStudentContracts, ...parsedYimSH],
      (o) => o.date
    );
    return history
      .map((i) => `${moment(i.date).format('DD.MM.YYYY')}: ${i.text}`)
      .join(',</br>');
  };

  const clearAllFilter = () => {
    setSearch('');
    setUsersSearch('');
    setSearchGroup('');
    clearFilter(true);
  };

  const exportReport = async () => {
    const res = await contractsServices
      .exportContractReports(params)
      .then((res) => res.data);
    const blob = new Blob(['\ufeff', res], { type: 'text/csv' });
    FileSaver.saveAs(blob, 'filename.csv');
  };

  const mainAmount = (transactions: IResTransaction[]) => {
    return sumAmount(
      transactions.reduce((a, b) => {
        return a + b.amount;
      }, 0)
    );
  };
  return (
    <Template>
      <div className='contract-reports-page'>
        <Grid container>
          <Grid item xs={10}>
            <Title
              title='Отчет по контрактам'
              className='contract-reports-page__title'
            />
          </Grid>
          <Grid item xs={2}>
            <Button size='small' onClick={exportReport}>
              Выгрузить отчет
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2} className='contract-reports-page__filter'>
          <Grid item xs={3}>
            <TextField
              onChange={(e) => setParams({ ...params, from: e.target.value })}
              value={params.from || ''}
              size='small'
              InputLabelProps={{ shrink: true }}
              type='date'
              autoComplete='off'
              label='От'
              error={hasErrorFilter && !!params.to && !params.from}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              onChange={(e) => setParams({ ...params, to: e.target.value })}
              value={params.to || ''}
              label='До'
              size='small'
              InputLabelProps={{ shrink: true }}
              type='date'
              autoComplete='off'
              error={hasErrorFilter && !!params.from && !params.to}
            />
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth size='small'>
              <InputLabel variant='outlined'>Источник</InputLabel>
              <Select
                label={'Источник'}
                value={params.trafficSource || ''}
                onChange={(e) =>
                  setParams({
                    ...params,
                    trafficSource: e.target.value as ITrafficSource,
                  })
                }
                size='small'
              >
                {trafficSource?.map((i) => (
                  <MenuItem key={i.value} value={i.value}>
                    {i.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <CustomSearchableSelect
              items={[
                ...referralUsers.map((i) => ({
                  ...i,
                  value: i.id,
                  name: `${i.lastName} ${i.firstName}`,
                })),
              ]}
              setSelectItem={(value: number) =>
                setParams({ ...params, referralUserId: value })
              }
              value={params.referralUserId}
              setLoading={() => {}}
              loading={false}
              setSearchValue={setSearchReferralUser}
              searchValue={searchReferralUser}
              setValue={getReferralUsers}
              placeholder='Ответственный'
              small
            />
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth size='small'>
              <InputLabel variant='outlined'>Пол</InputLabel>
              <Select
                label={'Пол'}
                value={params.gender || ''}
                onChange={(e) =>
                  setParams({
                    ...params,
                    gender: e.target.value as IGender,
                  })
                }
                size='small'
              >
                {genders?.map((i) => (
                  <MenuItem key={i.value} value={i.value}>
                    {i.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth size='small'>
              <InputLabel variant='outlined'>Категория</InputLabel>
              <Select
                label={'Категория'}
                value={params.categoryId || ''}
                onChange={(e) =>
                  setParams({
                    ...params,
                    categoryId: Number(e.target.value),
                  })
                }
                size='small'
              >
                {categories?.map((i) => (
                  <MenuItem key={i.id} value={i.id}>
                    {i.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth size='small'>
              <InputLabel variant='outlined'>Филиал</InputLabel>
              <Select
                label={'Категория'}
                value={params.branchId || ''}
                onChange={(e) =>
                  setParams({
                    ...params,
                    branchId: Number(e.target.value),
                  })
                }
                size='small'
              >
                {branches?.map((i) => (
                  <MenuItem key={i.id} value={i.id}>
                    {i.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <CustomSearchableSelect
              items={[
                ...users.map((i) => ({
                  ...i,
                  value: i.id,
                  name: `${i.lastName} ${i.firstName}`,
                })),
              ]}
              setSelectItem={(value: number) =>
                setParams({ ...params, teacherId: value })
              }
              value={params.teacherId}
              setLoading={() => {}}
              loading={false}
              setSearchValue={setUsersSearch}
              searchValue={usersParams.search}
              setValue={getUsers}
              placeholder='Преподаватель'
              small
            />
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth size='small'>
              <InputLabel variant='outlined'>Статус</InputLabel>
              <Select
                label={'Статус'}
                value={params.status || ''}
                onChange={(e) =>
                  setParams({
                    ...params,
                    status: e.target.value as ContractStatus,
                  })
                }
                size='small'
              >
                {contractStatuses?.map((i) => (
                  <MenuItem key={i.value} value={i.value}>
                    {i.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <CustomSearchableSelect
              items={[
                ...tariffs.map((i) => ({
                  ...i,
                  value: i.id,
                  name: i.titleRu,
                })),
              ]}
              setSelectItem={(value: number) =>
                setParams({ ...params, tariffId: value })
              }
              value={params.tariffId}
              setLoading={() => {}}
              loading={false}
              setSearchValue={setSearch}
              searchValue={tariffParams.search}
              setValue={getTariffs}
              placeholder='Тариф'
              small
            />
          </Grid>
          <Grid item xs={3}>
            <CustomSearchableSelect
              items={[
                ...groups.map((i) => ({
                  ...i,
                  value: i.id,
                  name: `Группа №${i.id}`,
                })),
              ]}
              setSelectItem={(value: number) =>
                setParams({ ...params, groupId: value })
              }
              value={params.groupId}
              setLoading={() => {}}
              loading={false}
              setSearchValue={(value: string) =>
                setSearchGroup(value.replace(/[^+\d]/g, ''))
              }
              searchValue={searchGroup}
              setValue={() => getGroups()}
              placeholder='Группа'
              small
            />
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth size='small'>
              <InputLabel variant='outlined'>
                Реферальный пользователь
              </InputLabel>
              <Select
                label={'Реферальный пользователь'}
                value={params.referral?.toString() || ''}
                onChange={(e) =>
                  setParams({
                    ...params,
                    referral: e.target.value,
                  })
                }
                size='small'
              >
                {referrals.map((i) => (
                  <MenuItem key={i.value} value={i.value}>
                    {i.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth size='small'>
              <InputLabel variant='outlined'>
                Подтвержденный реферальнный пользователь
              </InputLabel>
              <Select
                label={'Подтвержденный реферальнный пользователь'}
                value={params.confirmReferral || ''}
                onChange={(e) =>
                  setParams({
                    ...params,
                    confirmReferral: e.target.value as ContractStatus,
                  })
                }
                size='small'
              >
                {confrimReferrals?.map((i) => (
                  <MenuItem key={i.value} value={i.value}>
                    {i.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <TextField
              onChange={(e) =>
                setParams({ ...params, yearOfBirth: e.target.value })
              }
              value={params.yearOfBirth || ''}
              label='Дата рождения'
              size='small'
              InputLabelProps={{ shrink: true }}
              type='date'
              autoComplete='off'
            />
          </Grid>
          <Grid item xs={4}>
            <div className='row'>
              <Button
                className='users-page__button'
                color='primary'
                size='small'
                onClick={getContractReports}
              >
                Найти
              </Button>
              <Button
                className='users-page__button'
                color='error'
                size='small'
                onClick={clearAllFilter}
              >
                Очистить
              </Button>
            </div>
          </Grid>
        </Grid>
        {
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Номер договора</TableCell>
                  <TableCell style={{ minWidth: '200px' }}>
                    Дата заключения
                  </TableCell>
                  <TableCell style={{ minWidth: '200px' }}>
                    Источник тарифа
                  </TableCell>
                  <TableCell style={{ minWidth: '200px' }}>
                    Ответственное лицо
                  </TableCell>
                  <TableCell style={{ minWidth: '200px' }}>
                    ФИО Клиента
                  </TableCell>
                  <TableCell>Пол</TableCell>
                  <TableCell>Год рождения</TableCell>
                  <TableCell>Номер телефона</TableCell>
                  <TableCell style={{ minWidth: '200px' }}>Пакет</TableCell>
                  <TableCell>Категория</TableCell>
                  <TableCell>Сумма договора</TableCell>
                  <TableCell>Статус</TableCell>
                  <TableCell style={{ minWidth: '200px' }}>
                    Примечание
                  </TableCell>
                  <TableCell>HUMO</TableCell>
                  <TableCell>UZCARD</TableCell>
                  <TableCell>Наличные</TableCell>
                  <TableCell>Payme</TableCell>
                  <TableCell style={{ minWidth: '200px' }}>
                    Транзакции
                  </TableCell>
                  <TableCell>Результат вводного тестирования</TableCell>
                  <TableCell style={{ minWidth: '200px' }}>
                    Филиал обучения
                  </TableCell>
                  <TableCell style={{ minWidth: '200px' }}>
                    Преподаватель теория
                  </TableCell>
                  <TableCell>№ группы теория</TableCell>
                  <TableCell style={{ minWidth: '200px' }}>
                    Даты занятий по вождению кол-во прокатов
                  </TableCell>
                  <TableCell style={{ minWidth: '300px' }}>История</TableCell>
                  <TableCell style={{ minWidth: '200px' }}>Общий чек</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!!contractReports.length &&
                  contractReports.map((i, index) => (
                    <TableRow key={i.id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{i.contractNumber}</TableCell>
                      <TableCell>
                        {moment(i.createdAt).format('DD.MM.YYYY HH:mm')}
                      </TableCell>
                      <TableCell>
                        {translateTrafficSource(i.student.trafficSource)}
                      </TableCell>
                      <TableCell>
                        {`${i.referralUser?.lastName || ''} ${
                          i.referralUser?.firstName || ''
                        }`}
                      </TableCell>
                      <TableCell>{`${i.student.lastName} ${i.student.firstName} ${i.student.middleName}`}</TableCell>
                      <TableCell>
                        {genders.find((ii) => ii.value === i.student.gender)
                          ?.title || ''}
                      </TableCell>
                      <TableCell>
                        {i.student.dateOfBirth
                          ? moment(i.student.dateOfBirth).format('DD.MM.YYYY')
                          : ''}
                      </TableCell>
                      <TableCell>
                        {i.student.phone ? `+${i.student.phone}` : ''}
                      </TableCell>
                      <TableCell>{i.tariff?.titleRu}</TableCell>
                      <TableCell>
                        {mergeCategories(i.tariff.tariffCourses)}
                      </TableCell>
                      <TableCell>0</TableCell>
                      <TableCell>
                        {contractStatuses.find((ii) => ii.value === i.status)
                          ?.title || ''}
                      </TableCell>
                      <TableCell>{i.student?.note}</TableCell>
                      <TableCell>
                        {i.transactions.filter(
                          (ii) => ii.type === TransactionType.HUMO
                        ).length > 0 ? (
                          <CheckIcon />
                        ) : (
                          ''
                        )}
                      </TableCell>
                      <TableCell>
                        {i.transactions.filter(
                          (ii) => ii.type === TransactionType.UZCARD
                        ).length > 0 ? (
                          <CheckIcon />
                        ) : (
                          ''
                        )}
                      </TableCell>
                      <TableCell>
                        {i.transactions.filter(
                          (ii) => ii.type === TransactionType.CASH
                        ).length > 0 ? (
                          <CheckIcon />
                        ) : (
                          ''
                        )}
                      </TableCell>
                      <TableCell>
                        {i.transactions.filter(
                          (ii) => ii.type === TransactionType.PAYME
                        ).length > 0 ? (
                          <CheckIcon />
                        ) : (
                          ''
                        )}
                      </TableCell>
                      <TableCell>
                        {i.transactions.map(
                          (ii) =>
                            `${
                              transactionTypes.find(
                                (iii) => iii.value === ii.type
                              )?.label
                            }: ${sumAmount(ii.amount)}`
                        )}
                      </TableCell>
                      <TableCell>
                        {i.initEval ? `${i.initEval}%` : ''}
                      </TableCell>
                      <TableCell>{i.branch.name}</TableCell>
                      <TableCell>{`${i.group?.teacher?.lastName || ''} ${
                        i.group?.teacher?.firstName || ''
                      }`}</TableCell>
                      <TableCell>{`${i.group?.id || ''}`}</TableCell>
                      <TableCell
                        dangerouslySetInnerHTML={{
                          __html: checkRun(i.lessons) || '',
                        }}
                      ></TableCell>
                      <TableCell
                        dangerouslySetInnerHTML={{ __html: yimHistory(i) }}
                      ></TableCell>
                      <TableCell>{mainAmount(i.transactions)}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Paper>
        }
        {totalCount > params.take && (
          <TablePagination
            count={totalCount}
            rowsPerPageOptions={[10, 25, 50]}
            component='div'
            rowsPerPage={params.take}
            labelRowsPerPage='Количество на странице'
            page={params.skip}
            onRowsPerPageChange={(e) => setTake(Number(e.target.value))}
            onPageChange={(e, newPage) => setSkip(newPage)}
          />
        )}
      </div>
    </Template>
  );
});
