import instance from "api/instance";
import qs from 'query-string'
import { ICreateTarifff, IGetTariff, IGetTariffById, IUpdateTariff } from "./types";


export const getTariffs: IGetTariff = (params) => instance.get(`/tariffs?${qs.stringify(params, { skipEmptyString: true })}`);

export const getTariffById: IGetTariffById = (id) => instance.get(`/tariffs/${id}`)

export const createTariff: ICreateTarifff = (data) => instance.post(`/tariffs`, data)

export const updateTariff: IUpdateTariff = (id, data) => instance.put(`/tariffs/${id}`, data)