import { makeAutoObservable } from "mobx"


class CreateUserStore {
  firstName = '';
  lastName = '';
  middleName = ''
  login = ''
  branchId = null
  gender? = ''
  password = ''
  isActive = false
  role = '';
  categoryIds = [] as number[]
  loading = false
  hasError = false

  constructor (){
    makeAutoObservable(this)
  }

  setFirstName = (e: any) => {
    this.firstName = e
  }
  setLastName = (e: any) => {
    this.lastName = e
  }
  setMiddleName = (e: any) => {
    this.middleName = e
  }
  setLogin = (e: any) => {
    this.login = e
  }
  setBranchId = (e: any) => {
    this.branchId = e
  }
  setGender = (e: any) => {
    this.gender = e
  }
  setPassword = (e: any) => {
    this.password = e
  }
  setIsActive = (e: any) => {
    this.isActive = e
  }
  setRole =  (e: any) => {
    this.role = e
  }
  setCategoryIds = (e: any) => {
    this.categoryIds = e
  }
  setLoading = (e: any) => {
    this.loading = e
  }
  setHasError = (e: any) => {
    this.hasError = e
  }

  clearFields = () => {
    this.setFirstName('')
    this.setLastName('')
    this.setMiddleName('')
    this.setLogin('')
    this.setBranchId(undefined)
    this.setGender('')
    this.setPassword('')
    this.setIsActive(false)
    this.setRole('')
    this.setCategoryIds([])
    this.setLoading(false)
    this.setHasError(false)
  }
  
}

export default new CreateUserStore();