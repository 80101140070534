import instance from "api/instance";
import { ICreateCategory, IDeleteCategory, IGetCategories, IGetCategoryById, IUpdateCategory } from "./types";


export const getCategories: IGetCategories = () => instance.get(`/categories`);

export const getCategoryById: IGetCategoryById = (id) => instance.get(`/categories/${id}`)

export const createCategory: ICreateCategory = (data) => instance.post(`/categories`, data);

export const updateCategory: IUpdateCategory = (id, data) => instance.put(`/categories/${id}`, data);

export const deleteCategory: IDeleteCategory = (id) => instance.delete(`/categories/${id}`)