import { contractsServices } from 'api/services';
import { IResContract } from 'api/services/contracts/types';
import { makeAutoObservable } from 'mobx';
import AuthPageStore from 'pages/AuthPage/AuthPageStore';
import lessonsPageStore from 'pages/LessonsPage/LessonsPageStore';
import { ROLE } from 'refs/roles';

class ContractsPageStore {
  params = {
    page: 0,
    take: 25,
    studentId: '',
    branchId: '',
    finished: '',
    lang: '',
    instructorGender: '',
    status: '',
    tariffId: '',
    operatorId: '',
    refChecked: undefined,
    referrals: undefined,
  };

  filters = {
    page: 0,
    take: 25,
    studentId: '',
    branchId: '',
    finished: '',
    lang: '',
    instructorGender: '',
    status: '',
    tariffId: '',
    operatorId: '',
    refChecked: undefined,
    referrals: undefined,
  };
  totalCount = '' as any;
  contracts = [] as IResContract[];

  constructor() {
    makeAutoObservable(this);
  }

  getContracts = async () => {
    const search = lessonsPageStore.searchContract;

    const searchIndex = search.split('').findIndex((i) => i === '№') + 1;
    const branch =
      AuthPageStore.user?.role === ROLE.SU ||
      AuthPageStore.user?.role === ROLE.ADMIN
        ? this.params.studentId
          ? Number(this.params.studentId)
          : undefined
        : AuthPageStore.user?.branchId || undefined;
    const params = {
      take: this.params.take,
      skip: this.params.page * this.params.take,
      studentId: this.params.studentId
        ? Number(this.params.studentId)
        : undefined,
      branchId: this.params.branchId ? Number(this.params.branchId) : undefined,
      search: searchIndex || '',
      finished:
        this.params.finished === 'true'
          ? true
          : this.params.finished === 'false'
          ? false
          : undefined,
      lang: this.params.lang,
      instructorGender: this.params.instructorGender,
      status: this.params.status,
      tariffId: this.params.tariffId ? Number(this.params.tariffId) : undefined,
      operatorId: this.params.operatorId
        ? Number(this.params.operatorId)
        : undefined,
      referrals: this.params.referrals,
      refChecked: this.params.refChecked,
    };
    const res = await contractsServices
      .getContracts(params)
      .then((res) => res.data);
    this.contracts = res.data;
    this.totalCount = res.count;
  };
  setStudentId = (e: any) => {
    this.params.studentId = e;
  };
  setBranchId = (e: any) => {
    this.params.branchId = e;
  };
  setFinished = (e: any) => {
    this.params.finished = e;
  };
  setLang = (e: any) => {
    this.params.lang = e;
  };
  setInstructorGender = (e: any) => {
    this.params.instructorGender = e;
  };
  setStatus = (e: any) => {
    this.params.status = e;
  };
  setTariffId = (e: any) => {
    this.params.tariffId = e;
  };
  setOperatorId = (e: any) => {
    this.params.operatorId = e;
  };
  setPage = (e: any) => {
    this.params.page = e;
  };
  setTake = (e: any) => {
    this.params.page = e;
  };
  setRefferals = (e: any) => (this.params.referrals = e);
  setRefChecked = (e: any) => (this.params.refChecked = e);
  searchHandler = () => {
    this.filters = {
      studentId: this.params.studentId,
      branchId: this.params.branchId,
      finished: this.params.finished,
      lang: this.params.lang,
      instructorGender: this.params.instructorGender,
      status: this.params.status,
      tariffId: this.params.tariffId,
      operatorId: this.params.operatorId,
      page: this.params.page,
      take: this.params.take,
      refChecked: this.params.refChecked,
      referrals: this.params.referrals,
    };
  };
  clearHandler = () => {
    this.filters = {
      studentId: '',
      branchId: '',
      finished: '',
      lang: '',
      instructorGender: '',
      status: '',
      tariffId: '',
      operatorId: '',
      page: 0,
      take: 25,
      refChecked: undefined,
      referrals: undefined,
    };
    this.params.page = 0;
    this.params.take = 25;
    this.params.operatorId = '';
    this.params.tariffId = '';
    this.params.status = '';
    this.params.instructorGender = '';
    this.params.lang = '';
    this.params.finished = '';
    this.params.branchId = '';
    this.params.studentId = '';
    this.params.refChecked = undefined;
    this.params.referrals = undefined;
  };
}

export default new ContractsPageStore();
