import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import moment from "moment";
import { isSU } from "helpers/checkRole";
import CategoriesPageStore from "./CategoriesPageStore";
import AuthPageStore from "pages/AuthPage/AuthPageStore";
import Template from "layout/Template/Template";
import Title from "components/Title/Title";
import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import './CategoriesPage.sass'


const CategoriesPage: React.FC = observer(() => {
  const { categories, getData } = CategoriesPageStore
  const { user } = AuthPageStore

  useEffect(() => {
    getData()
  }, [getData])

  return (
    <Template btnUrl='/categories/create' roleGuard={user && isSU(user)}>
      <div className="cateogires-page">
        <Title title="Категории" className="categories-page__title"/>
        {!!categories.length && (
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Название</TableCell>
                  <TableCell>Дата изменения</TableCell>
                  {user?.role === 'SU' && (
                    <TableCell></TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {categories.map((i, index) => (
                  <TableRow key={i.id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{i.name}</TableCell>
                    <TableCell>{moment(i.updatedAt).format('DD.MM.YYYY')}</TableCell>
                    {user?.role === 'SU' && (
                      <TableCell>
                        <Link to={`/categories/${i.id}`} className="go-to">
                          <ArrowForwardIcon />
                        </Link>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        )}
      </div>
    </Template>
  )
})

export default CategoriesPage