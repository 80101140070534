import { tariffsServises } from 'api/services';
import { IResTariff } from 'api/services/tariffs/types';
import { makeAutoObservable } from 'mobx';

class TariffsPageStore {
  tariffs = [] as IResTariff[];
  params = {
    isActive: '',
    search: '',
  };
  filters = {
    isActive: '',
    search: '',
  };

  constructor() {
    makeAutoObservable(this);
  }

  searchHandler = () => {
    this.filters = {
      search: this.params.search,
      isActive: this.params.isActive,
    };
  };

  clearHandler = () => {
    this.filters = {
      search: '',
      isActive: '',
    };
    this.setSearch('');
    this.setIsActive('');
  };

  setIsActive = (e: any) => {
    this.params.isActive = e;
  };
  setSearch = (e: any) => {
    this.params.search = e;
  };

  getTariffs = async () => {
    const params = {
      search: this.params.search,
      isActive:
        this.params.isActive === 'notactive'
          ? false
          : this.params.isActive === 'active'
          ? true
          : undefined,
    };
    const res = await tariffsServises
      .getTariffs(params)
      .then((res) => res.data);
    this.tariffs = res.sort((a, b) => a.titleRu.localeCompare(b.titleRu));
  };

  getTarifById = (id: number) => {
    return this.tariffs.find((i) => i.id === id)?.titleRu || '';
  };
}

export default new TariffsPageStore();
