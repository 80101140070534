import instance from 'api/instance';
import qs from 'query-string';
import {
  ICreateStudent,
  IDeleteStudent,
  IGetStudent,
  IGetStudentById,
  IGetStudentTimeline,
  IUpdateStudent
} from './types';

export const getStudents: IGetStudent = (params) =>
  instance.get(`/students?${qs.stringify(params, { skipEmptyString: true })}`);

export const getStudentById: IGetStudentById = (id) =>
  instance.get(`/students/${id}`);

export const getStudentTimeline: IGetStudentTimeline = (id) => instance.get(`/students/${id}/timeline`)

export const createStudent: ICreateStudent = (data) =>
  instance.post('/students', data);

export const updateStudent: IUpdateStudent = (id, data) =>
  instance.put(`/students/${id}`, data);

export const updateStudentNote = (id: number, data: any) => instance.patch(`students/${id}/note`, data)

export const deleteStudent: IDeleteStudent = (id) =>
  instance.delete(`/students/${id}`);
