import { observer } from 'mobx-react-lite';
import Template from 'layout/Template/Template';
import Title from 'components/Title/Title';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import regionsStore from 'refs/regionsStore';
import CreateBranchPageStore from './CreateBranchPageStore';
import './CreateBranchPage.sass';
import showNotification from 'helpers/showNotification';
import { branchServices } from 'api/services';
import { NotificationStatus } from 'components/Notification/types';
import BranchesPageStore from 'pages/BranchesPage/BranchesPageStore';

export const CreateBranchPage: React.FC = observer(() => {
  const {
    name,
    regionId,
    contractPrefix,
    hasError,
    setName,
    setRegionId,
    setContractPrefix,
    setHasError,
  } = CreateBranchPageStore;
  const history = useNavigate();

  const createBranch = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!name.trim() || !regionId || !contractPrefix.trim()) {
      showNotification('Заполните поля');
      setHasError(true);
      return;
    }
    try {
      const data = { name, regionId: Number(regionId), contractPrefix };
      await branchServices.createBranch(data);
      showNotification('Филиал успешно создан', NotificationStatus.SUCCESS);
      setName('');
      setRegionId('');
      setContractPrefix('');
      setHasError(false);
      BranchesPageStore.getBranches();
      history('/branches');
    } catch (e: any) {
      if (
        e.response.data.statusCode === 409 &&
        e.response.data.message === 'branch_name'
      ) {
        showNotification('Филиал с таким названием уже существует');
        return;
      }
      if (
        e.response.data.statusCode === 409 &&
        e.response.data.message === 'branch_contract_prefix'
      ) {
        showNotification('Филиал с таким префиксом контракта уже существует');
        return;
      }
      showNotification('Ошибка попробуйте позже');
    }
  };

  return (
    <Template>
      <form className='create-branch-page' onSubmit={createBranch}>
        <Title title='Создать филиал' className='create-branch-page__title' />
        <TextField
          className='create-branch-page__field'
          value={name}
          onChange={(e) => setName(e.target.value)}
          autoComplete='off'
          placeholder='Имя'
          error={hasError && !name.trim()}
        />
        <FormControl
          fullWidth
          className='create-branch-page__field'
          error={hasError && !regionId}
        >
          <InputLabel variant='outlined'>Выберите регион</InputLabel>
          <Select
            label={'Выберите регион'}
            value={regionId}
            error={hasError && !regionId}
            onChange={(e) => setRegionId(e.target.value)}
          >
            {regionsStore.regions?.map((i) => (
              <MenuItem key={i.id} value={i.id}>
                {i.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          className='create-branch-page__field'
          value={contractPrefix}
          onChange={(e) => setContractPrefix(e.target.value)}
          placeholder='Префик контракта'
          autoComplete='off'
          error={hasError && !contractPrefix.trim()}
        />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button color='primary' size='medium' type='submit'>
              Сохранить
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Link to='/branches'>
              <Button color='inherit'>Назад</Button>
            </Link>
          </Grid>
        </Grid>
      </form>
    </Template>
  );
});
