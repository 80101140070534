import cn from 'classnames'
import './Statuses.sass'

interface Props {
  status: IStatus,
  isActive?: boolean
}

const Statuses: React.FC<Props> = ({status, isActive}) => {

  return (
    <div className={cn ('statuses', {
      'statuses--new' : status === 'NEW',
      'statuses--payed' : status === 'PAYED',
      'statuses--finished' : status === 'FINISHED',
      'statuses--closed' : status === 'FINISHED' && isActive === false,
    })}>
      <div className="statuses__in">
        {status === 'NEW' ? 'Новый' : status === 'PAYED' ? 'Оплачен' : status === 'FINISHED' ? 'Успешно окончен' : 'Закрыт'}
      </div>
    </div>
  )
}

export default Statuses