import AuthTemplate from 'layout/AuthTemplate/AuthTemplate';
import { Button } from '@mui/material';
import AuthPageStore from './AuthPageStore';
import { observer } from 'mobx-react-lite';
import { authServices } from 'api/services';
import { useNavigate } from 'react-router';
import showNotification from 'helpers/showNotification';
import './AuthPage.sass';
import { NotificationStatus } from 'components/Notification/types';
import regionsStore from 'refs/regionsStore';
import instance from 'api/instance';

export const AuthPage = observer(() => {
  const history = useNavigate();
  const { login, setLogin, password, setPassword, setProfile } = AuthPageStore;

  const signIn = async (e: React.FormEvent) => {
    e.preventDefault();
    if (password.length < 6) {
      showNotification("Пароль должен быть не менее 6 символов")
      return
    }
    try {
      const res = await authServices
        .auth({ login: login, password: password })
        .then((res) => res.data);
      setProfile(res.user, res.accessToken);
      showNotification('Успешно', NotificationStatus.SUCCESS);
      instance.defaults.headers.common.Authorization = `Bearer ${res.accessToken}`;
      regionsStore.getRegions();
      if (res.user.role === 'ACCOUNTANT') {
        history('/transactions');
      } else if (res.user.role === 'ACCOUNTANT') {
        history('/refs')
      } else if (res.user.role === 'CASHIER') {
        history('/contracts')
      } else {
        history('/lessons');
      }
    } catch (e: any) {
      if (e.response.data.message === "Invalid credentials") {
        showNotification("Неправильный логин или пароль")
        return
      }
      showNotification('Ошибка попробуйте позже');
    }
  };

  return (
    <AuthTemplate label='Вход в аккаунт'>
      <form onSubmit={signIn} className='auth-page__form'>
        <label className='auth-page__field'>
          <div className='auth-page__label'>Логин</div>
          <input
            value={login}
            onChange={(e) => setLogin(e.target.value)}
            className='auth-page__input'
          ></input>
        </label>
        <label className='auth-page__field'>
          <div className='auth-page__label'>Пароль</div>
          <input
            className='auth-page__input'
            value={password}
            type='password'
            onChange={setPassword}
          />
        </label>
        <Button
          color='primary'
          variant='contained'
          fullWidth
          size='large'
          type='submit'
          className='auth-page__button'
        >
          Войти
        </Button>
      </form>
    </AuthTemplate>
  );
});
