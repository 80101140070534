export interface ILessonType {
  label: string;
  value: string;
}

export enum LessonType {
  INDIVIDUAL = 'INDIVIDUAL',
  NOT_INDIVIDUAL = 'NOT_INDIVIDUAL',
}

export const lessonTypes: ILessonType[] = [
  {
    label: 'Индивидуальный',
    value: LessonType.INDIVIDUAL,
  },
  {
    label: 'Групповой',
    value: LessonType.NOT_INDIVIDUAL,
  },
];

export const isIndividual = (value: string): boolean => {
  return value === LessonType.INDIVIDUAL;
};
