import { observer } from "mobx-react-lite";
import { useNavigate } from 'react-router';
import { Link } from "react-router-dom";
import Template from "layout/Template/Template";
import CreateRefPageStore from "./CreateRefPageStore";
import { refsServices } from "api/services";
import Title from "components/Title/Title";
import { Button, Grid, TextField } from "@mui/material";
import './CreateRefPage.sass'


const CreateRefPage: React.FC = observer(() => {
  const { percent, setPercent, lastName, setLastName, firstName, setFirstName, middleName, setMiddleName, loading, setLoading, hasError, setHasError, clearFields } = CreateRefPageStore
  const history = useNavigate()

  const createRef = async (e: React.FormEvent) => {
    e.preventDefault()
    if(!lastName.trim() || !firstName.trim() || !middleName.trim() || !percent.trim()){
      setHasError(true)
      return
    }

    const data = {
      firstName,
      lastName,
      middleName,
      percent: Number(percent)
    }
    try {
      setLoading(true)
      await refsServices.createRef(data)
      clearFields()
      history('/refs')
    } catch (e: any) {

    } finally {
      setLoading(false)
    }
  }

  return (
    <Template>
      <Title
        title='Создать реферального пользователя'
        className='create-ref-page__title'
      />
      <form className="create-ref-page" onSubmit={createRef}>
        <TextField
          className='create-ref-page__field'
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          autoComplete='off'
          placeholder='Фамилия'
          error={hasError && !lastName.trim()}
        />
        <TextField
          className='create-ref-page__field'
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          autoComplete='off'
          placeholder='Имя'
          error={hasError && !firstName.trim()}
        />
        <TextField
          className='create-ref-page__field'
          value={middleName}
          onChange={(e) => setMiddleName(e.target.value)}
          autoComplete='off'
          placeholder='Отчество'
          error={hasError && !middleName.trim()}
        />
        <TextField
          className='create-ref-page__field'
          value={percent}
          onChange={(e) => setPercent(e.target.value)}
          autoComplete='off'
          placeholder='Процент'
          error={hasError && !percent.trim()}
        />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button color='primary' size='medium' type='submit' disabled={loading}>
              Сохранить
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Link to="/refs">
              <Button color='inherit'>
                Назад
              </Button>
            </Link>   
          </Grid>
        </Grid>
      </form>
    </Template>
  )
})

export default CreateRefPage