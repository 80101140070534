import { IResLesson } from 'api/services/lessons/types';
import { makeAutoObservable } from 'mobx';

export enum CalendarView {
  // MONTH = 'dayGridMonth',
  WEEK = 'timeGridWeek',
  DAY = 'timeGridDay',
}

class CalendarStore {
  grid = CalendarView.DAY;
  calendarItemsDay: any = [];
  calendarItemsWeek: any = [];
  constructor() {
    makeAutoObservable(this);
  }
  setCalendarItemsDay = (items: any) => (this.calendarItemsDay = items);
  setCalendarItemsWeek = (items: any) => (this.calendarItemsWeek = items);
  setGrid = (value: CalendarView) => (this.grid = value);
}

export default new CalendarStore();
