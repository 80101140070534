import ModalTemplate from "layout/ModalTemplate/ModalTemplate"
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn'
import './ExamModal.sass'

interface Props {
  hide: () => void
  type: string
  result?: any
}


const ExamModal: React.FC<Props> = ({
  hide,
  type,
  result
}) => {

  const sum = (arr: any) => {
    if (arr.length === 0) return 0
    return arr
      ?.map((i: any) => i?.penalty?.penaltyPoints)
      ?.reduce((sum: number, current: number) => sum + current)
  }

  return (
    <ModalTemplate hide={hide} title={type === 'PRACTICE_EXAM' ? 'Практический экзамен' : 'Теоретический экзамен'}>
      {type === 'PRACTICE_EXAM' && (
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Упражнение</TableCell>
                <TableCell>Название ошибки</TableCell>
                <TableCell>Штрафные баллы</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!!result?.details?.length && result?.details?.map((i: any, index: number) => (
                <TableRow key={index}>
                  <TableCell>{i?.penalty?.practiceExercise?.titleRu}</TableCell>
                  <TableCell>{i?.penalty?.titleRu}</TableCell>
                  <TableCell>{i?.penalty?.penaltyPoints}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <div className="exam-modal__result-wrap">
            <div className="exam-modal__result-text">Итого: </div>
            <div className="exam-modal__result">{sum(result?.details)} штрафных баллов</div>
          </div>
        </>
      )}
      {type === 'THEORY_EXAM' && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Номер билета №</TableCell>
              <TableCell>Вопрос №</TableCell>
              <TableCell>Выбранный ответ</TableCell>
              <TableCell>Результат</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {result?.answers?.map((ii: any, ind: number) => (
              <TableRow key={ind}>
                <TableCell>{ii.split('-')[0]}</TableCell>
                <TableCell>{ii.split('-')[1]}</TableCell>
                <TableCell>{ii.split('-')[2]}</TableCell>
                <TableCell>
                  {Number(ii.split('-')[3]) === 0 ? (
                    <DoNotDisturbOnIcon
                      sx={{ fontSize: 25, color: '#b4b4b5' }}
                      fontSize="large"
                      className="results-modal__icon"
                    />
                  ) : Number(ii.split('-')[3]) === 1 ? (
                    <CancelIcon
                      className="results-modal__icon"
                      sx={{ fontSize: 25, color: '#ff0000' }}
                    />
                  ) : (
                    <CheckCircleIcon
                      className="results-modal__icon"
                      sx={{ fontSize: 25, color: 'green' }}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </ModalTemplate> 
  )
}

export default ExamModal