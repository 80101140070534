export const trafficSource = [
  {
    title: 'INSTAGRAM',
    value: 'INSTAGRAM',
  },
  {
    title: 'FACEBOOK',
    value: 'FB',
  },
  {
    title: 'TELEGRAM',
    value: 'TG',
  },
  {
    title: 'Кол-центр',
    value: 'CALL_CENTER',
  },
  {
    title: 'От сотрудников центра',
    value: 'REFERRAL',
  },
  {
    title: 'Флаер',
    value: 'FLYER',
  },
  {
    title: 'Автошкола',
    value: 'AUTOSCHOOL',
  },
  {
    title: 'Знакомые (друзья)',
    value: 'FRIENDS',
  },
  {
    title: 'Веб-сайт',
    value: 'WEBSITE',
  },
];

export const translateTrafficSource = (type?: ITrafficSource | string) => {
  return trafficSource.find((i) => i.value === type)?.title || '';
};
