import instance from 'api/instance';
import { IGetUsers, IGetUserById, IUpdateuser, ICreateUser } from './types'
import qs from 'query-string';


export const getUsers: IGetUsers = (params) => instance.get(`/users?${qs.stringify(params, { skipEmptyString: true })}`);

export const getUserById: IGetUserById = (id) => instance.get(`/users/${id}`)

export const createUser: ICreateUser = (data) => instance.post(`/users`, data)

export const updateUser: IUpdateuser = (id, data) => instance.put(`/users/${id}`, data)

