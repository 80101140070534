import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { isAdmin, isSU } from 'helpers/checkRole';
import Template from 'layout/Template/Template';
import Title from 'components/Title/Title';
import TariffsPageStore from './TariffsPageStore';
import AuthPageStore from 'pages/AuthPage/AuthPageStore';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/CancelPresentation';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import './TariffsPage.sass';

const TariffsPage: React.FC = observer(() => {
  const {
    getTariffs,
    tariffs,
    params,
    searchHandler,
    clearHandler,
    setIsActive,
    setSearch,
    filters,
  } = TariffsPageStore;
  const history = useNavigate();
  const { user } = AuthPageStore

  useEffect(() => {
    getTariffs();
  }, [filters, getTariffs]);

  return (
    <Template btnUrl='/tariffs/create' roleGuard={(user && isSU(user)) || (user && isAdmin(user))}>
      <div className='tariffs-page'>
        <Title title='Тарифы' className='tariffs-page__title' />
        <Grid container spacing={2} className='tariffs-page__filter'>
          <Grid item xs={4}>
            <FormControl fullWidth size='small'>
              <InputLabel>Выберите активность</InputLabel>
              <Select
                label={'Выберите активность'}
                value={params.isActive}
                onChange={(e) => setIsActive(e.target.value)}
                size='small'
              >
                <MenuItem value={'active'}>Активен</MenuItem>
                <MenuItem value={'notactive'}>Неактивен</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <TextField
              value={params.search}
              onChange={(e) => setSearch(e.target.value)}
              size='small'
              placeholder='Поиск'
            />
          </Grid>
          <Grid item xs={4}>
            <div className='row'>
              <Button
                className='users-page__button'
                color='primary'
                size='small'
                onClick={searchHandler}
              >
                Найти
              </Button>
              <Button
                className='users-page__button'
                color='error'
                size='small'
                onClick={clearHandler}
              >
                Очистить
              </Button>
            </div>
          </Grid>
        </Grid>
        {!!tariffs.length && (
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Название ру</TableCell>
                  <TableCell>Название уз</TableCell>
                  <TableCell>Цена</TableCell>
                  <TableCell>Активность</TableCell>
                  <TableCell>Дата изменения</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tariffs.map((i, index) => (
                  <TableRow key={i.id} >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{i.titleRu}</TableCell>
                    <TableCell>{i.titleUz}</TableCell>
                    <TableCell>
                      {(Number(i.cost) / 100)
                        .toString()
                        .replace(/(\d)(?=(\d{3})+$)/g, '$1 ')}
                    </TableCell>
                    <TableCell>
                      {i.isActive ? (
                        <CheckIcon
                          sx={{ fontSize: 20, color: 'green' }}
                          className='users-page__checked-btn'
                          fontSize='large'
                        />
                      ) : (
                        <CloseIcon
                          sx={{ fontSize: 20, color: '#ff0000' }}
                          fontSize='large'
                          className='users-page__statues-btn'
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      {moment(i.updatedAt).format('DD.MM.YYYY')}
                    </TableCell>
                    <TableCell>
                      <Link to={`/tariffs/${i.id}`} className="go-to">
                        <ArrowForwardIcon />
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        )}
      </div>
    </Template>
  );
});

export default TariffsPage;
