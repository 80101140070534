import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import { IResContract } from "api/services/contracts/types"
import ModalTemplate from "layout/ModalTemplate/ModalTemplate"
import moment from "moment"
import CloseIcon from '@mui/icons-material/CancelPresentation';
import CheckIcon from '@mui/icons-material/Check';

interface Props {
  data?: IResContract
  hide: () => void
}

const ScheduleModal: React.FC<Props> = ({
  data,
  hide
}) => {
  return (
    <ModalTemplate hide={hide} title="Расписание">
      <div className="schedule-modal">
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Начальная оценка</TableCell>
              {data?.lessons?.map((i) => (
                <TableCell key={i.id}>
                  <div>{i.datetime ? moment(i.datetime).format('DD.MM.YYYY hh:mm') : '-'}</div>
                </TableCell>
              ))}
              <TableCell>Финальная оценка</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
              <TableRow>
    
                <TableCell>
                  {data?.initEval ? `${data?.initEval} %` : null}
                </TableCell>
                {data?.lessons?.map((ii) => (
                  <TableCell
                    key={ii.id}
                  >
                    {data?.attendance?.find((iii) => iii.lessonId === ii.id)
                      ?.value === true ? (
                      <CheckIcon
                        sx={{ fontSize: 25, color: 'green' }}
                        className='update-lesson-page__checked-btn'
                        fontSize='large'
                      />
                    ) : data?.attendance?.find((iii) => iii.lessonId === ii.id)
                        ?.value === false ? (
                      <CloseIcon
                        sx={{ fontSize: 25, color: '#ff0000' }}
                        fontSize='large'
                        className='update-lesson-page__statues-btn'
                      />
                    ) : (
                      <></>
                    )}
                  </TableCell>
                ))}
                <TableCell>
                  {data?.finalEval ? `${data?.finalEval} %` : null}
                </TableCell>
              </TableRow>
          </TableBody>
        </Table>
      </Paper>
      </div>
    </ModalTemplate>
  )
}

export default ScheduleModal