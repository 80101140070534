import { useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import AuthPageStore from 'pages/AuthPage/AuthPageStore';
import UpdateLessonIndividualStore from './UpdateLessonIndividualStore';
import Template from 'layout/Template/Template';
import Title from 'components/Title/Title';
import Modal from 'components/Modal/Modal';
import Statuses from 'components/Statuses/Statuses';
import InitEvalModal from 'components/Modal/InitEvalModal/InitEvalModal';
import FinalEvalModal from 'components/Modal/FinalEvalModal/FinalEvalModal';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/CancelPresentation';
import './UpdateLessonIndividual.sass';

const UpdateLessonIndividual: React.FC = observer(() => {
  const {
    getLessonById,
    attendanceHandler,
    contract,
    lessons,
    loading,
    contractId,
    setContractId,
    clearFields,
    lessonType,
    attendance,
  } = UpdateLessonIndividualStore;
  const { user } = AuthPageStore;
  const { lessonId } = useParams<{ lessonId: string }>();
  const initEval = useRef<any>();
  const finalEval = useRef<any>();

  useEffect(() => {
    return () => {
      clearFields();
    };
  }, [clearFields]);

  useEffect(() => {
    if (!lessonId) return;
    getLessonById(Number(lessonId));
  }, [lessonId, getLessonById]);

  const openInitEval = (id: number) => {
    if (
      user?.role === 'SU' ||
      user?.role === 'TEACHER' ||
      user?.role === 'ADMIN'
    ) {
      setContractId(id);
      initEval.current.onShow();
    }
  };
  const openFinalEval = (id: number) => {
    if (
      user?.role === 'SU' ||
      user?.role === 'TEACHER' ||
      user?.role === 'ADMIN'
    ) {
      setContractId(id);
      finalEval.current.onShow();
    }
  };
  const hideEval = () => {
    setContractId(undefined);
    initEval.current.onHide();
    finalEval.current.onHide();
    if (!lessonId) return;
    getLessonById(Number(lessonId));
  };

  return (
    <>
      <Template>
        <div className='update-lesson-individual'>
          <div className='update-lesson-individual__row'>
            <Title
              title='Информация об уроке'
              className='update-lesson-individual__title'
            />
            <Paper className='update-lesson-individual__paper'>
              <Table className='update-lesson-individual__table'>
                <TableHead>
                  <TableRow>
                    {/* <TableCell>Преподаватель: </TableCell>
                    <TableCell>{teacher?.lastName?.[0].toUpperCase() + teacher?.lastName?.slice(1)} {teacher?.firstName?.substr(0, 1)?.toUpperCase() + '.'} {teacher.middleName ? teacher?.middleName?.substr(0, 1).toUpperCase() + '.' : ''}</TableCell> */}
                  </TableRow>
                  <TableRow>
                    <TableCell>Тип урока: </TableCell>
                    <TableCell>
                      {lessonType === 'RUN'
                        ? 'Вождение'
                        : lessonType === 'PILOT_RUN'
                        ? 'Тестовый заезд'
                        : 'Теория'}
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </Paper>
          </div>
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Ф.И.О</TableCell>
                  <TableCell>Cтатус</TableCell>
                  <TableCell>Начальная оценка</TableCell>
                  {!!lessons.length &&
                    lessons.map((i) => (
                      <TableCell key={i.id}>
                        <div>
                          {i.datetime ? moment(i.datetime).format('DD.MM.YYYY hh:mm') : '-'}
                        </div>
                      </TableCell>
                    ))}
                  <TableCell>Финальная оценка</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell className='update-lesson-individual__td'>
                    {contract?.student?.lastName} {contract?.student?.firstName}{' '}
                    {contract?.student?.middleName}
                  </TableCell>
                  <TableCell>
                    <Statuses status={contract.status}></Statuses>
                  </TableCell>
                  <TableCell onClick={() => openInitEval(contract.id)}>
                    {contract.initEval ? `${contract.initEval} %` : null}
                  </TableCell>
                  {!!lessons.length &&
                    lessons.map((ii) => (
                      <TableCell
                        key={ii.id}
                        onClick={() =>
                          attendanceHandler(
                            attendance?.find((iii) => iii.lessonId === ii.id)
                              ?.value,
                            ii.id,
                            contract.id,
                            attendance?.find((iii) => iii.lessonId === ii.id)
                              ?.id
                          )
                        }
                      >
                        {attendance?.find((iii) => iii.lessonId === ii.id)
                          ?.value === true && !loading ? (
                          <CheckIcon
                            sx={{ fontSize: 25, color: 'green' }}
                            className='update-lesson-individual__checked-btn'
                            fontSize='large'
                          />
                        ) : attendance?.find((iii) => iii.lessonId === ii.id)
                            ?.value === false && !loading ? (
                          <CloseIcon
                            sx={{ fontSize: 25, color: '#ff0000' }}
                            fontSize='large'
                            className='update-lesson-individual__statues-btn'
                          />
                        ) : (
                          <></>
                        )}
                        {loading &&
                          attendance?.some(
                            (iiii) => iiii.lessonId === ii.id
                          ) && <CircularProgress color='success' value={10} />}
                      </TableCell>
                    ))}
                  <TableCell onClick={() => openFinalEval(contract.id)}>
                    {contract.finalEval ? `${contract.finalEval} %` : null}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </div>
        <Modal onClick={hideEval} ref={initEval}>
          <InitEvalModal hide={hideEval} id={contractId} />
        </Modal>
        <Modal onClick={hideEval} ref={finalEval}>
          <FinalEvalModal hide={hideEval} id={contractId} />
        </Modal>
      </Template>
    </>
  );
});

export default UpdateLessonIndividual;
