import { observer } from 'mobx-react-lite';
import Template from 'layout/Template/Template';
import { useNavigate } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { studentsServices } from 'api/services';
import { IStudent } from 'api/services/students/types';
import Title from 'components/Title/Title';
import showNotification from 'helpers/showNotification';
import { NotificationStatus } from 'components/Notification/types';
import CreateStudentPageStore from './CreateStudentPageStore';
import DatePicker, { registerLocale } from 'react-datepicker';
import { GENDER, genders } from 'refs/genders';
import ru from 'date-fns/locale/ru';
import { trafficSource } from 'refs/trafficSource';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import './CreateStudentPage.sass';


export const CreateStudentPage: React.FC = observer(() => {
  registerLocale('ru', ru);
  const {
    middleName,
    firstName,
    lastName,
    pinfl,
    gender,
    passportSerial,
    passportNumber,
    phone,
    dateOfBirth,
    setDateOfBirth,
    setMiddleName,
    setLastName,
    setFirstName,
    setPinfl,
    setGender,
    setPhone,
    setPassportNumber,
    setPassportSerial,
    note,
    setNote,
    traffic,
    setTraffic,
    address,
    setAddress,
    passportIssue,
    setPassportIssue,
    hasError,
    setHasError,
    clearFields,
  } = CreateStudentPageStore;
  const history = useNavigate();

  const createStudent = async (e: React.FormEvent) => {
    e.preventDefault();
    if (
      !firstName.trim() ||
      !lastName.trim() ||
      !middleName.trim() ||
      !pinfl.trim() ||
      !passportSerial.trim() ||
      !passportNumber.trim() ||
      !phone.trim() || 
      !address.trim() || 
      !passportIssue.trim()
    ) {
      showNotification('Поля не заполнены');
      setHasError(true);
      return;
    }
    try {
      const data: IStudent = {
        firstName,
        lastName,
        middleName,
        phone: phone.replaceAll(' ', '').replace('+', ''),
        pinfl,
        gender,
        passportSerial,
        passportNumber,
        dateOfBirth: new Date(dateOfBirth),
        note,
        trafficSource: traffic,
        address,
        passportIssue
      };
      const res = await studentsServices
        .createStudent(data)
        .then((res) => res.data);
      showNotification(
        'Студен успешно зарегестрирован',
        NotificationStatus.SUCCESS
      );
      clearFields();
      history(`/students/${res.id}/update`);
    } catch (e: any) {
      showNotification('Ошибка попробуйте позже');
    }
  };
  const back = () => {
    clearFields();
    history('/students');
  };

  const changePinfl = (value: string) => {
    if (pinfl.length >= 14 && value.length >= 14) return;
    setPinfl(value);
  };
  
  return (
    <>
      <Template>
        <form className='create-student-page' onSubmit={createStudent}>
          <Title
            title='Создать студента'
            className='create-student-page__title'
          />
          <TextField
            className='create-student-page__field'
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            autoComplete='off'
            placeholder='Фамилия'
            error={hasError && !lastName.trim()}
          />
          <TextField
            className='create-student-page__field'
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            autoComplete='off'
            placeholder='Имя'
            error={hasError && !firstName.trim()}
          />
          <TextField
            className='create-student-page__field'
            value={middleName}
            onChange={(e) => setMiddleName(e.target.value)}
            autoComplete='off'
            placeholder='Отчество'
            error={hasError && !middleName.trim()}
          />
          <DatePicker
            selected={dateOfBirth}
            dateFormat='dd.MM.yyyy'
            locale='ru'
            autoComplete='off'
            calendarClassName='date-picker'
            onChange={(date: any) => setDateOfBirth(date)}
            customInput={
              <TextField
                className='create-student-page__field'
                autoComplete='off'
              />
            }
          />
          <TextField
            className='create-student-page__field'
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            autoComplete='off'
            placeholder='Адрес'
            error={hasError && !address.trim()}
          />
          <TextField
            className='create-student-page__field'
            value={pinfl}
            onChange={(e) => changePinfl(e.target.value)}
            autoComplete='off'
            type='number'
            placeholder='ПИНФЛ'
            error={hasError && !pinfl.trim()}
          />
          <div className='create-student-page__flex'>
            <TextField
              value={passportSerial}
              onChange={(e) => setPassportSerial(e.target.value)}
              autoComplete='off'
              placeholder='Серия паспорта'
              error={hasError && !passportSerial.trim()}
            />
            <TextField
              value={passportNumber}
              onChange={(e) => setPassportNumber(e.target.value)}
              autoComplete='off'
              placeholder='Номер паспорта'
              error={hasError && !passportNumber.trim()}
            />
          </div>
          <TextField
            className='create-student-page__field'
            value={passportIssue}
            onChange={(e) => setPassportIssue(e.target.value)}
            autoComplete='off'
            placeholder='Кем выдан'
            error={hasError && !passportIssue.trim()}
          />
          <FormControl
            fullWidth
            size='small'
            className='create-student-page__field'
          >
            <InputLabel variant='outlined'>Пол</InputLabel>
            <Select
              label={'Пол'}
              value={gender}
              onChange={(e) => setGender(e.target.value as GENDER)}
              size='small'
            >
              {genders?.map((i) => (
                <MenuItem key={i.value} value={i.value}>
                  {i.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <InputMask
            mask='+\9\9\8 ** *** ** **'
            value={phone}
            onChange={(e: any) => setPhone(e.target.value)}
            placeholder='Номер телефона'
          >
            {(inputProps: any) => (
              <TextField
                className='create-student-page__field'
                autoComplete='off'
                {...inputProps}
                error={hasError && !phone.trim()}
              />
            )}
          </InputMask>
          <FormControl fullWidth className='contract-modal__field'>
            <InputLabel variant='outlined'>Как узнали об экпр. курсах</InputLabel>
            <Select
              label={'Как узнали об экпр. курсах'}
              value={traffic}
              onChange={(e) => setTraffic(e.target.value)}
            >
              {trafficSource?.map((i) => (
                <MenuItem key={i.value} value={i.value}>
                  {i.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <textarea
            className="create-student-page__field"
            value={note}
            onChange={(e) => setNote(e.target.value)}
            autoComplete='off'
            placeholder='Примечание'
          />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button color='primary' size='medium' type='submit'>
                Сохранить
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button color='inherit' onClick={back}>
                Назад
              </Button>
            </Grid>
          </Grid>
        </form>
      </Template>
      
    </>
  );
});
