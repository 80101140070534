export enum TransactionType {
  CASH = 'CASH',
  HUMO = 'HUMO',
  UZCARD = 'UZCARD',
  PAYME = 'PAYME',
}

export interface ITransactionData {
  value: TransactionType;
  label: string;
}

export const transactionTypes: ITransactionData[] = [
  {
    value: TransactionType.CASH,
    label: 'Наличные',
  },
  {
    value: TransactionType.UZCARD,
    label: 'UzCard',
  },
  {
    value: TransactionType.HUMO,
    label: 'Humo',
  },
  {
    value: TransactionType.PAYME,
    label: 'Payme',
  },
];
