import instance from 'api/instance';
import qs from 'query-string';
import {
  ICreateTransaction,
  IDeleteTransaction,
  IGetTransaction,
  IGetTransactionById,
  IGetTransactionsFile,
  IUpdateTransaction,
} from './types';

export const getTransactions: IGetTransaction = (params) =>
  instance.get(
    `/transactions?${qs.stringify(params, { skipEmptyString: true })}`
  );

export const getTransactionById: IGetTransactionById = (id) =>
  instance.get(`/transactions/${id}`);

export const createTransaction: ICreateTransaction = (data) =>
  instance.post('/transactions', data);

export const updateTranaction: IUpdateTransaction = (id, data) =>
  instance.put(`/transactions/${id}`, data);

export const deleteTransaction: IDeleteTransaction = (id) =>
  instance.delete(`/transactions/${id}`);

export const getTransactionsFile: IGetTransactionsFile = (params) => instance.get(`/transactions/export?${qs.stringify(params, { skipEmptyString: true })}`)
