import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import cn from 'classnames';
import AuthPageStore from 'pages/AuthPage/AuthPageStore';
import { navigation } from 'routes/navigation';
import sidebarStore from './SidebarStore';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import './Sidebar.sass';

const Sidebar: React.FC = observer(() => {
  const { user } = AuthPageStore;

  const checkRole = (roles?: IRole[]) => {
    if (!roles) return;
    return roles.some((i) => i === user?.role);
  };

  return (
    <aside
      className={cn('sidebar', { 'sidebar--mini': sidebarStore.isShowed })}
    >
      <div
        className='sidebar__toggle'
        onClick={() =>
          sidebarStore.handleVisibleSidebar(!sidebarStore.isShowed)
        }
      >
        <MenuOpenIcon sx={{ fontSize: 30, color: '#4acbce' }} />
      </div>
      <div className='sidebar__inner'>
        {navigation.map(
          (i, index) =>
            !!i.label &&
            checkRole(i.roles) && (
              <Link className='sidebar__link' key={index} to={i.path}>
                {!!i.icon && (
                  <i.icon
                    className='sidebar__icon'
                    sx={{ fontSize: 30, color: '#4acbce' }}
                  />
                )}
                {!sidebarStore.isShowed && i.label}
              </Link>
            )
        )}
      </div>
    </aside>
  );
});

export default Sidebar;
