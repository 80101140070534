import { CourseType } from 'api/services/courses/types';
import { IResLesson } from 'api/services/lessons/types';
import { ReactComponent as AutoIcon } from 'assets/images/auto.svg';
import { ReactComponent as GroupsIcon } from 'assets/images/groups.svg';
import { ReactComponent as NotCheckedIcon } from 'assets/images/not-checked.svg';
import { ReactComponent as UserIcon } from 'assets/images/person.svg';
import { ReactComponent as SuccessIcon } from 'assets/images/success.svg';
import { ReactComponent as TheoryIcon } from 'assets/images/theory.svg';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { ReactNode, useEffect } from 'react';
import { ContractStatus } from 'refs/contractStatuses';
import './Lessons.sass';
import LessonsStore from './LessonsStore';
import { isAdmin, isGarage, isSU } from 'helpers/checkRole';
import AuthPageStore from 'pages/AuthPage/AuthPageStore';
import CategoriesPageStore from 'pages/CategoriesPage/CategoriesPageStore';

interface Props {
  lessons: IResLesson[];
  showUpdateModal: (lesson: IResLesson) => void;
  lessonId?: number;
}

interface ITab {
  id: number;
  Icon: ReactNode;
  title: string;
}

const tabs: ITab[] = [
  {
    id: 1,
    Icon: <GroupsIcon />,
    title: 'Групповые',
  },
  {
    id: 2,
    Icon: <UserIcon />,
    title: 'Индивидуальные',
  },
  {
    id: 3,
    Icon: <TheoryIcon />,
    title: 'Теория',
  },
  {
    id: 4,
    Icon: <AutoIcon />,
    title: 'Практика',
  },
  {
    id: 5,
    Icon: <SuccessIcon />,
    title: 'Оплачен',
  },
  {
    id: 6,
    Icon: <NotCheckedIcon />,
    title: 'Неоплачен',
  },
];

export const Lessons: React.FC<Props> = observer(
  ({ lessons, showUpdateModal, lessonId }) => {
    const {
      isHidden,
      lessonsWithoutDatetime,
      setLessonsWithoutDatetime,
      setFilteredLessons,
      filteredLessons,
      selectedFilterId,
      setSelectedFilterId,
    } = LessonsStore;
    const { categories } = CategoriesPageStore;

    const { user } = AuthPageStore;

    useEffect(() => {
      sliceLessons();
      setFilteredLessons(lessons);
    }, [lessons.length, setFilteredLessons]);

    useEffect(() => {
      return () => {
        setLessonsWithoutDatetime([]);
        setFilteredLessons([]);
        setSelectedFilterId(undefined);
      };
    }, [setFilteredLessons, setSelectedFilterId, setLessonsWithoutDatetime]);

    const sliceArr = (lessons: IResLesson[]) => {
      return lessons.slice(0, 25);
    };

    const sliceLessons = () => {
      const slicedLessons = sliceArr(lessons);
      setLessonsWithoutDatetime(slicedLessons);
    };

    const handleLessonsVisible = () => {
      if (selectedFilterId) {
        if (filteredLessons.length <= lessonsWithoutDatetime.length) {
          const slicedLessons = sliceArr(filteredLessons);
          setLessonsWithoutDatetime(slicedLessons);
          return;
        }
        setLessonsWithoutDatetime(filteredLessons);
        return;
      }
      if (lessons.length <= lessonsWithoutDatetime.length) {
        sliceLessons();
        return;
      }
      setLessonsWithoutDatetime(lessons);
    };

    const renderIcons = (item: IResLesson) => {
      return (
        <>
          {item.course?.type === CourseType.THEORY && (
            <TheoryIcon className='lessons__item-type-icon lessons__item-type-icon--theory' />
          )}
          {(item.course?.type === CourseType.PILOT_RUN ||
            item.course?.type === CourseType.RUN) && (
            <AutoIcon className='lessons__item-type-icon lessons__item-type-icon--run' />
          )}
        </>
      );
    };

    const filterList = (id: number) => {
      setSelectedFilterId(id);
      switch (id) {
        case 1:
          const individualLessons = lessons.filter(
            (i) => !i.course?.individual
          );
          setLessonsWithoutDatetime(sliceArr(individualLessons));
          setFilteredLessons(individualLessons);
          return;
        case 2:
          const groupLessons = lessons.filter((i) => i.course?.individual);
          setLessonsWithoutDatetime(sliceArr(groupLessons));
          setFilteredLessons(groupLessons);
          return;
        case 3:
          const theoryLessons = lessons.filter(
            (i) => i.course?.type === 'THEORY'
          );
          setLessonsWithoutDatetime(sliceArr(theoryLessons));
          setFilteredLessons(theoryLessons);
          return;
        case 5:
          const payedLessons = lessons.filter(
            (i) => i.contract?.status === ContractStatus.PAYED
          );
          setLessonsWithoutDatetime(sliceArr(payedLessons));
          setFilteredLessons(payedLessons);
          return;
        case 6:
          const newLessons = lessons.filter(
            (i) => i.contract?.status === ContractStatus.NEW
          );
          setLessonsWithoutDatetime(sliceArr(newLessons));
          setFilteredLessons(newLessons);
          return;
        default:
          const practiceLessons = lessons.filter(
            (i) => i.course?.type !== 'THEORY'
          );
          setLessonsWithoutDatetime(sliceArr(practiceLessons));
          setFilteredLessons(practiceLessons);
          return;
      }
    };

    return (
      <div className='lessons'>
        {(isGarage(user) || isSU(user) || isAdmin(user)) && (
          <div className='lessons__tabs'>
            {tabs.map((i) => (
              <div
                className={cn('lessons__tabs-item', {
                  'lessons__tabs-item--active': i.id === selectedFilterId,
                })}
                onClick={() => filterList(i.id)}
                key={i.id}
              >
                {i.Icon}
                {i.title}
              </div>
            ))}
          </div>
        )}
        <div
          className={cn('lessons__container', {
            'lessons__container--hide': isHidden,
            'lessons__container--without-list': !lessonsWithoutDatetime?.length,
          })}
        >
          <ul className='lessons__list'>
            {lessonsWithoutDatetime?.map((i) => (
              <li
                className={cn('lessons__item', {
                  'lessons__item--active': lessonId === i.id,
                })}
                onClick={() => showUpdateModal(i)}
                key={i.id}
              >
                {i.groupId && (
                  <>
                    <GroupsIcon />
                    {renderIcons(i)}
                    {i.course?.categoryId && (
                      <span>
                        "
                        {categories?.find(
                          (ii) => ii.id === i.course?.categoryId
                        )?.name || ''}
                        "
                      </span>
                    )}
                    {` ${i.groupId}`}
                  </>
                )}
                {i.contractId && (
                  <>
                    <UserIcon />
                    {renderIcons(i)}
                    {i.contract?.status === ContractStatus.NEW && (
                      <NotCheckedIcon />
                    )}
                    {i.contract?.status === ContractStatus.PAYED && (
                      <SuccessIcon />
                    )}
                    {i.course?.categoryId && (
                      <span>
                        "
                        {categories?.find(
                          (ii) => ii.id === i.course?.categoryId
                        )?.name || ''}
                        "
                      </span>
                    )}
                    {` ${i.contract.contractNumber}`}
                  </>
                )}
              </li>
            ))}
          </ul>
          {filteredLessons.length > 25 && (
            <button
              onClick={handleLessonsVisible}
              className='lessons__show-button'
            >
              {lessonsWithoutDatetime.length >= filteredLessons.length
                ? 'Скрыть'
                : `Показать все ${filteredLessons.length}`}
            </button>
          )}
        </div>
      </div>
    );
  }
);
