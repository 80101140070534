import { branchServices } from 'api/services';
import { makeAutoObservable } from 'mobx';

class UpdateBranchPageStore {
  name: string = '';
  regionId: string | number = '';
  contractPrefix: string = '';
  hasError = false;

  constructor() {
    makeAutoObservable(this);
  }

  setName = (value: string) => (this.name = value);
  setRegionId = (value: number | string) => (this.regionId = value);
  setContractPrefix = (value: string) => (this.contractPrefix = value);
  setHasError = (value: boolean) => (this.hasError = value);

  getBranchData = async (id: number) => {
    const res = await branchServices.getBranchById(id).then((res) => res.data);
    this.setName(res.name);
    this.setContractPrefix(res.contractPrefix);
    this.setRegionId(res.regionId);
  };
}

export default new UpdateBranchPageStore();
