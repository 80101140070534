import { lessonsServices, userServices } from 'api/services';
import { CourseType } from 'api/services/courses/types';
import {
  ILessonParams,
  IResLesson,
  IResLessonCount,
} from 'api/services/lessons/types';
import { IResUser } from 'api/services/users/types';
import { NotificationStatus } from 'components/Notification/types';
import { LessonType } from 'helpers/lessonType';
import showNotification from 'helpers/showNotification';
import { makeAutoObservable } from 'mobx';
import AuthPageStore from 'pages/AuthPage/AuthPageStore';
import { ROLE } from 'refs/roles';

class LessonsPageStore {
  lessons: Array<IResLesson & { title: string }> = [];
  showModal = false;
  searchGroup = '';
  searchContract = '';
  params?: ILessonParams = undefined;
  hasError = false;
  groupId?: number = undefined;
  contractId?: number = undefined;
  teacherId?: number = undefined;
  datetime?: Date | null = undefined;
  lessonType = LessonType.NOT_INDIVIDUAL;
  courseType = CourseType.RUN;
  isUpdate = false;
  lesson?: IResLesson = undefined;
  lessonsWithoutDatetime: IResLesson[] = [];
  totalCount: number = 0;
  favoriteTeachers: IResUser[] = [];
  lessonsCount: IResLessonCount[] = [];

  constructor() {
    makeAutoObservable(this);
  }
  setGroupId = (value?: number) => (this.groupId = value);
  setSearchGroup = (value: string) => (this.searchGroup = value);
  setParams = (data?: ILessonParams) => (this.params = data);
  setLessons = (data: IResLesson[]) =>
    (this.lessons = data.map((i) => ({ ...i, title: '' })));
  setLessonsWithoutDatetime = (data: IResLesson[]) =>
    (this.lessonsWithoutDatetime = data);
  setHasError = (value: boolean) => (this.hasError = value);
  setShowModal = (value: boolean) => (this.showModal = value);
  setTeacherId = (value?: number) => (this.teacherId = value);
  setContractId = (value?: number) => (this.contractId = value);
  setDatetime = (value?: Date | null) => (this.datetime = value);
  setLessonType = (value: LessonType) => (this.lessonType = value);
  setCourseType = (value: CourseType) => (this.courseType = value);
  setSearchContract = (value: string) => (this.searchContract = value);
  setLesson = (lesson?: IResLesson) => {
    this.lesson = lesson;
    this.courseType = lesson?.course?.type || CourseType.RUN;
  };
  setFavoriteTeachers = (teachers: IResUser[]) =>
    (this.favoriteTeachers = teachers);
  setLessonsCount = (lessons: IResLessonCount[]) =>
    (this.lessonsCount = lessons);

  clearFields = () => {
    this.setGroupId(undefined);
    this.setTeacherId(undefined);
    this.setContractId(undefined);
    this.setSearchGroup('');
    this.setSearchContract('');
    this.setCourseType(CourseType.RUN);
    this.setDatetime(undefined);
  };

  getLessons = async () => {
    if (!this.params) return;
    if (!this.params.to || !this.params.from) return;
    const { from, to } = this.params;
    const res1 = await lessonsServices
      .getLessons({
        ...this.params,
        from,
        to,
      })
      .then((res) => res.data);

    this.setLessons(res1);
  };

  getLessonsWithoutDateTime = async () => {
    const res2 = await lessonsServices
      .getLessons({
        withoutDatetime: true,
        ...this.params,
        from: '',
        to: '',
      })
      .then((res) => res.data);
    this.setLessonsWithoutDatetime(res2);
  };

  getLessonsCount = async () => {
    const res = await lessonsServices
      .getLessonsCount({ ...this.params })
      .then((res) => res.data);
    this.setLessonsCount(res);
  };

  updateLesson = async () => {
    if (!this.lesson) return;
    const {
      datetime,
      teacherId,
      contractId,
      groupId,
      setShowModal,
      setHasError,
      courseType,
    } = this;
    if (!datetime && !teacherId && !contractId && !groupId && !courseType) {
      showNotification('Заполните поля');
      setHasError(true);
      return;
    }

    try {
      await lessonsServices.updateLesson(this.lesson.id, {
        datetime: datetime ? new Date(datetime) : null,
        teacherId,
        contractId,
        groupId,
        type: courseType,
      });
      setShowModal(false);
      this.getLessons();
      this.getLessonsWithoutDateTime();
      this.clearFields();
      this.lesson = undefined;
      showNotification('Запись сохранена', NotificationStatus.SUCCESS);
    } catch {
      showNotification('Ошибка попробуйте позже');
    }
  };

  getFavoriteTeachers = async () => {
    if (!this.lesson) return;
    const res = await userServices
      .getUsers({
        skip: 0,
        take: 25,
        gender: this.lesson?.contract?.instructorGender,
        role: ROLE.INSTRUCTOR,
        branchId: this.params?.branchId,
      })
      .then((res) => res.data);

    this.setFavoriteTeachers(res.data);
  };

  updateLessonTime = async (id: number, time: Date) => {
    try {
      await lessonsServices.updateLesson(id, {
        datetime: time,
      });
    } catch {
      showNotification('Ошибка попробуйте позже');
    }
  };

  deleteLesson = async () => {
    if (!this.lesson) return;
    try {
      await lessonsServices.deleteLesson(this.lesson.id);
      showNotification('Запись удалена', NotificationStatus.INFO);
      this.getLessons();
      this.setShowModal(false);
      this.clearFields();
      this.setLesson(undefined);
    } catch {
      showNotification('Ошибка попробуйте позже');
    }
  };
}

export default new LessonsPageStore();
