import { userServices } from 'api/services';
import { makeAutoObservable } from 'mobx';

class UpdateUserPageStore {
  firstName = '';
  lastName = '';
  middleName = '';
  login = '';
  branchId? = '' as any;
  gender? = '';
  password = '';
  isActive = false;
  role = '';
  categoryIds = [] as any;
  loading = false;
  hasError = false;

  constructor() {
    makeAutoObservable(this);
  }

  setFirstName = (e: any) => {
    this.firstName = e;
  };
  setLastName = (e: any) => {
    this.lastName = e;
  };
  setMiddleName = (e: any) => {
    this.middleName = e;
  };
  setLogin = (e: any) => {
    this.login = e;
  };
  setBranchId = (e: any) => {
    this.branchId = e;
  };
  setGender = (e: any) => {
    this.gender = e;
  };
  setPassword = (e: any) => {
    this.password = e;
  };
  setIsActive = (e: any) => {
    this.isActive = e;
  };
  setRole = (e: any) => {
    this.role = e;
  };
  setCategoryIds = (e: any) => {
    this.categoryIds = e;
  };
  setLoading = (e: any) => {
    this.loading = e;
  };
  setHasError = (e: any) => {
    this.hasError = e;
  };

  getUserById = async (id: number) => {
    const res = await userServices.getUserById(id).then((res) => res.data);
    this.firstName = res.firstName;
    this.lastName = res.lastName;
    this.middleName = res.middleName;
    this.login = res.login;
    this.branchId = res.branchId ? Number(res.branchId) : '';
    this.gender = res.gender ? res.gender : '';
    this.password = res.password ? res.password : '';
    this.isActive = res.isActive;
    this.role = res.role;
    this.categoryIds = !!res.categories?.length
      ? res.categories.map((i) => i.id)
      : [];
  };

  clearFields = () => {
    this.setFirstName('');
    this.setLastName('');
    this.setMiddleName('');
    this.setLogin('');
    this.setBranchId(undefined);
    this.setGender('');
    this.setPassword('');
    this.setIsActive(false);
    this.setRole('');
    this.setCategoryIds([]);
    this.setLoading(false);
    this.setHasError(false);
  };
}

export default new UpdateUserPageStore();
