import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { isAdmin, isRegistrator, isRegistratorWidthCashier, isSU } from 'helpers/checkRole';
import Title from 'components/Title/Title';
import Template from 'layout/Template/Template';
import AuthPageStore from 'pages/AuthPage/AuthPageStore';
import BranchesPageStore from 'pages/BranchesPage/BranchesPageStore';
import GroupsPageStore from './GroupsPageStore';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import './GroupsPage.sass';


const GroupsPage: React.FC = observer(() => {
  const { user } = AuthPageStore;
  const { branches } = BranchesPageStore;
  const {
    params,
    filters,
    totalCount,
    groups,
    setBranchId,
    setTeacherId,
    setIsFinished,
    searchHandler,
    clearHandler,
    setPage,
    setTake,
    getGroups,
  } = GroupsPageStore;
  const history = useNavigate();

  useEffect(() => {
    return () => {
      clearHandler();
    };
  }, []);

  useEffect(() => {
    if (!user?.id) return;
    getGroups(user.branchId ? user.branchId : undefined);
  }, [getGroups, params.take, params.page, filters, user?.id]);

  return (
    <Template
      btnUrl='/groups/create'
      roleGuard={
        (user && isSU(user)) ||
        (user && isAdmin(user)) ||
        (user && isRegistrator(user)) ||
        (user && isRegistratorWidthCashier(user))
      }
    >
      <div className='groups-page'>
        <Title title='Группы' className='groups-page__title' />
        <Grid container spacing={2} className='users-page__filter'>
          {((user && isSU(user)) || (user && isAdmin(user))) && (
            <Grid item xs={4}>
              <FormControl fullWidth size='small'>
                <InputLabel variant='outlined'>Выберите филиал</InputLabel>
                <Select
                  label={'Выберите филиал'}
                  value={params.branchId}
                  onChange={(e) => setBranchId(e.target.value)}
                  size='small'
                >
                  {branches?.map((i) => (
                    <MenuItem key={i.id} value={i.id}>
                      {i.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={4}>
            <FormControl fullWidth size='small'>
              <InputLabel>Выберите активность</InputLabel>
              <Select
                label={'Выберите активность'}
                value={params.isFinished}
                onChange={(e) => setIsFinished(e.target.value)}
                size='small'
              >
                <MenuItem value={'true'}>Активен</MenuItem>
                <MenuItem value={'false'}>Неактивен</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <TextField
              value={params.teacherId}
              onChange={(e) => setTeacherId(e.target.value)}
              size='small'
              placeholder='ID преподавателя'
            />
          </Grid>
          <Grid item xs={4}>
            <div className='row'>
              <Button
                className='users-page__button'
                color='primary'
                size='small'
                onClick={searchHandler}
              >
                Найти
              </Button>
              <Button
                className='users-page__button'
                color='error'
                size='small'
                onClick={clearHandler}
              >
                Очистить
              </Button>
            </div>
          </Grid>
        </Grid>
        {!!groups.length && (
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Филиал</TableCell>
                  <TableCell>Ф.И.О. / Номер контракта</TableCell>
                  <TableCell>Преподаватель</TableCell>
                  <TableCell>Язык</TableCell>
                  <TableCell>Дата изменения</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {groups.map((i, index) => (
                  <TableRow key={i.id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      {branches?.find((ii) => ii.id === i.branchId)?.name}
                    </TableCell>
                    <TableCell>
                      {i?.contracts?.map((ii) => (
                        <div className='' key={ii.id}>
                          {ii.student.lastName} {ii.student.firstName}{' '}
                          {ii.student.middleName} / {ii.contractNumber}
                        </div>
                      ))}
                    </TableCell>
                    <TableCell>
                      {i.teacher
                        ? `${i?.teacher?.lastName} ${i?.teacher?.firstName} ${i?.teacher?.middleName}`
                        : ''}
                    </TableCell>
                    <TableCell>{i.lang}</TableCell>
                    <TableCell>
                      {moment(i.updatedAt).format('DD.MM.YYYY')}
                    </TableCell>
                  <TableCell>
                    <Link to={`/groups/${i.id}`} className="go-to">
                      <ArrowForwardIcon />
                    </Link>
                  </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        )}
        {!!totalCount && (
          <TablePagination
            count={totalCount}
            rowsPerPageOptions={[10, 25, 50]}
            component='div'
            rowsPerPage={params.take}
            labelRowsPerPage='Количество на странице'
            page={params.page}
            onRowsPerPageChange={(e) => setTake(Number(e.target.value))}
            onPageChange={(e, newPage) => setPage(newPage)}
          />
        )}
      </div>
    </Template>
  );
});

export default GroupsPage;
