import cn from 'classnames'
import Title from 'components/Title/Title'
import CloseIcon from 'assets/images/icons/close-icon.svg'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import './ModalTemplate.sass'

interface Props {
  title: string
  hide: () => void
  children?: any
  size?: 'l'
}

const ModalTemplate: React.FC<Props> = ({ children, title, hide, size }) => {
  return (
    <div className={cn ('modal-template', {
      'modal-template--l' : size === 'l'
    })} onClick={(e) => e.stopPropagation()}>
      <Title className="modal-template__title" title={title}/>
      <HighlightOffIcon
        className="modal-template__close"
        onClick={hide}
        fontSize='large'
      />
      {children}
    </div>
  )
}

export default ModalTemplate
