import { groupsServises, refsServices } from "api/services"
import { IResGroup } from "api/services/groups/types"
import { IResRef } from "api/services/refs/types"
import { IResUser } from "api/services/users/types"
import { makeAutoObservable } from "mobx"


class ContractModalStore {
  groupId = ''
  groups = [] as IResGroup[]
  searchGroup = ''
  branchId = ''
  instructorId = ''
  instructors = [] as IResUser[]
  searchInstructors = ''
  instructorGender = ''
  teacherId = ''
  teachers = [] as IResUser[]
  searchTeacher = ''
  lang = ''
  tariffId = ''
  individual = false
  isActive = false
  referralUserId = undefined as number | undefined;
  referralUserSearch = ''
  refs = [] as IResRef[]
  loading = false
  hasError = false
  refChecked = false

  constructor(){
    makeAutoObservable(this)
  }
  setGroupId = (e: any) => {
    this.groupId = e
  }
  setBranchId = (e: any) => {
    this.branchId = e
  }
  setInstructorId = (e: any) => {
    this.instructorId = e
  }
  setTeacherId = (e: any) => {
    this.teacherId = e
  }
  setReferralUserId = (value: any) => {
    this.referralUserId = value
  }
  setLang = (e: any) => {
    this.lang = e
  }
  setInstructorGender = (e: any) => {
    this.instructorGender = e
  }
  setSearchTeacher = (e: any) => {
    this.searchTeacher = e
  }
  setSearchInstructors = (e: any) => {
    this.searchInstructors = e
  }
  setTariffId = (e: any) => {
    this.tariffId = e
  }
  setIndividual = (e: any) => {
    this.individual = e
  }
  setSearchGroup = (e: any) => {
    this.searchGroup = e
  }
  setReferralUserSearch = (e: string) => {
    this.referralUserSearch = e
  }
  setIsActive = (e: any) => {
    this.isActive = e
  }
  setRefChecked = (e: any) => {
    this.refChecked = e
  }
  setLoading = (e: any) => {
    this.loading = e
  }
  setHasError = (e: any) => {
    this.hasError = e
  }
  
  getRefs = async (value?: string) => {
    const params = {
      skip: 0,
      take: 10,
      search: value
    }
    const res = await refsServices.getRefs(params).then((res) => res.data)
    this.refs = res.data
  }

  clearFields = () => {
    this.groupId = ''
    this.groups = [] as IResGroup[]
    this.searchGroup = ''
    this.branchId = ''
    this.instructorId = ''
    this.instructors = [] as IResUser[]
    this.searchInstructors = ''
    this.instructorGender = ''
    this.teacherId = ''
    this.teachers = [] as IResUser[]
    this.searchTeacher = ''
    this.lang = ''
    this.tariffId = ''
    this.individual = false
    this.referralUserId = undefined
    this.referralUserSearch = ''
    this.refChecked = false
    this.isActive = false
    this.loading = false
    this.hasError = false
  }

  getGroups = async (value?: string) => {
    if(!this.branchId) return
    const params = {
      branchId: Number(this.branchId),
      teacherId: this.teacherId ? Number(this.teacherId) : undefined,
      isFinished: false,
      search: value ? value : '',
      take: 15,
      skip: 0,
      tariffId: this.tariffId ? Number(this.tariffId) : undefined,
    };
    const res = await groupsServises.getGroups(params).then((res) => res.data);
    this.groups = res.data
  };
}

export default new ContractModalStore