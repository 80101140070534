export enum ContractStatus {
  NEW = 'NEW',
  PAYED = 'PAYED',
}

export interface IContractStatus {
  label: string;
  value: ContractStatus;
}

export const contractStatuses = [
  {
    label: 'Новый',
    value: ContractStatus.NEW,
  },
  {
    label: 'Оплачено',
    value: ContractStatus.PAYED,
  },
];
