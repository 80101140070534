import { CourseType } from 'api/services/courses/types';
import { makeAutoObservable } from 'mobx';

class CreateCourseStorePage {
  titleRu = '';
  titleUz = '';
  isActive = true;
  individual = true;
  categoryId?: number = undefined;
  type: CourseType = CourseType.THEORY;
  hasError = false;
  duration = '';

  constructor() {
    makeAutoObservable(this);
  }

  setTitleRu = (value: string) => (this.titleRu = value);
  setTitleUz = (value: string) => (this.titleUz = value);
  setIsActive = (value: boolean) => (this.isActive = value);
  setIndividual = (value: boolean) => (this.individual = value);
  setType = (value: CourseType) => (this.type = value);
  setCategoryId = (value?: number) => (this.categoryId = value);
  setHasError = (value: boolean) => (this.hasError = value);
  setDuration = (value: string) => (this.duration = value);

  clearFields = () => {
    this.setTitleRu('');
    this.setTitleUz('');
    this.setIsActive(true);
    this.setIndividual(true);
    this.setType(CourseType.THEORY);
    this.setCategoryId(undefined);
    this.setHasError(false);
    this.setDuration('');
  };
}

export default new CreateCourseStorePage();
