import instance from 'api/instance';
import qs from 'query-string';
import {
  IContractReportFilter,
  ICreateContract,
  IGetContractById,
  IGetContractReport,
  IGetContracts,
  IUpdateContract,
  IUpdateContractEval,
} from './types';

export const getContracts: IGetContracts = (params) =>
  instance.get(`/contracts?${qs.stringify(params, { skipEmptyString: true })}`);

export const createContract: ICreateContract = (data) =>
  instance.post(`/contracts`, data);

export const getContractById: IGetContractById = (id) =>
  instance.get(`/contracts/${id}`);

export const updateContract: IUpdateContract = (id, data) =>
  instance.put(`/contracts/${id}`, data);

export const updateContractEval: IUpdateContractEval = (id, data) =>
  instance.put(`/contracts/${id}/eval`, data);

export const getContractReports: IGetContractReport = (params) =>
  instance.get(
    `/contracts/report?${qs.stringify(params, { skipEmptyString: true })}`
  );

export const exportContractReports = (params: IContractReportFilter) =>
  instance.get(
    `/contracts/report/export?${qs.stringify(params, {
      skipEmptyString: true,
    })}`
  );
