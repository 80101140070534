
import { refsServices } from "api/services";
import { makeAutoObservable } from "mobx";

class UpdateRefsPageStore {
  lastName = ''
  firstName = ''
  middleName = ''
  percent = ''
  loading = false
  hasError = false

  setLastName = (e: string) => {
    this.lastName = e
  }
  setFirstName = (e: string) => {
    this.firstName = e
  }
  setMiddleName = (e: string) => {
    this.middleName = e
  }
  setPercent = (e: any) => {
    this.percent = e
  }
  setLoading = (e: boolean) => {
    this.loading = e
  }
  setHasError = (e: any) => {
    this.hasError = e
  }

  clearFields = () => {
    this.lastName = ''
    this.firstName = ''
    this.middleName = ''
    this.percent = ''
    this.loading = false
    this.hasError = false
  }


  constructor (){
    makeAutoObservable(this)
  }
  getRefById = async (id: number) => {
    const res = await refsServices.getRefById(id).then((res) => res.data)
    this.firstName = res.firstName
    this.lastName = res.lastName
    this.middleName = res.middleName
    this.percent = res.percent.toString()
  }
}

export default new UpdateRefsPageStore