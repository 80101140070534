import { makeAutoObservable } from 'mobx';

class CreateBranchPageStore {
  name: string = '';
  regionId: string | number = '';
  contractPrefix: string = '';
  hasError = false;

  constructor() {
    makeAutoObservable(this);
  }

  setName = (value: string) => (this.name = value);
  setRegionId = (value: number | string) => (this.regionId = value);
  setContractPrefix = (value: string) => (this.contractPrefix = value);
  setHasError = (value: boolean) => (this.hasError = value);
}

export default new CreateBranchPageStore();
