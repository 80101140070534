import { courseServices } from 'api/services';
import { ICourseParam, IResCourse } from 'api/services/courses/types';
import { makeAutoObservable } from 'mobx';

class CoursesPageStore {
  params: ICourseParam = {
    search: '',
    isActive: '',
    type: '',
    individual: '',
    categoryId: '',
    skip: 0,
    take: 25,
  };
  totalCount = 0;
  courses: IResCourse[] = [];
  hasError = false;

  constructor() {
    makeAutoObservable(this);
  }

  setSkip = (value: number) => (this.params.skip = value);
  setTake = (value: number) => (this.params.take = value);
  setSearch = (value: string) => (this.params.search = value);
  setIsActive = (value: string) => (this.params.isActive = value);
  setCategoryId = (value: number | string) => (this.params.categoryId = value);
  setType = (value: string) => (this.params.type = value);
  setIndividual = (value: string) => (this.params.individual = value);
  setHasError = (value: boolean) => (this.hasError = value);
  setCourses = (data: IResCourse[]) => (this.courses = data);
  setTotalCount = (value: number) => (this.totalCount = value);

  getCourses = async () => {
    const res = await courseServices
      .getCourses({ ...this.params, skip: this.params.take * this.params.skip })
      .then((res) => res.data);
    this.setCourses(
      res.data.sort((a, b) => a.titleRu.localeCompare(b.titleRu))
    );
    this.setTotalCount(res.count);
  };

  clearFields = (isButton?: boolean) => {
    this.setSearch('');
    this.setIsActive('');
    this.setIndividual('');
    this.setCategoryId('');
    this.setType('');
    this.setSkip(0);
    if (isButton) this.getCourses();
  };
}

export default new CoursesPageStore();
