import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField
} from '@mui/material';
import { groupsServises, userServices } from 'api/services';
import { CustomSearchableSelect } from 'components/CustomSearchableSelect/CustomSearchableSelect';
import Statuses from 'components/Statuses/Statuses';
import Title from 'components/Title/Title';
import ru from 'date-fns/locale/ru';
import { isAdmin, isSU } from 'helpers/checkRole';
import Template from 'layout/Template/Template';
import { observer } from 'mobx-react-lite';
import AuthPageStore from 'pages/AuthPage/AuthPageStore';
import BranchesPageStore from 'pages/BranchesPage/BranchesPageStore';
import React, { useEffect } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import './UpdateGroupPage.sass';
import UpdateGroupPageStore from './UpdateGroupPageStore';

const UpdateGroupPage: React.FC = observer(() => {
  registerLocale('ru', ru);
  const {
    lang,
    setLang,
    brId,
    setBrId,
    contractId,
    setContractId,
    teacherId,
    setTeacherId,
    loading,
    setLoading,
    hasError,
    setHasError,
    search,
    setSearch,
    searchContracts,
    setSearchContracts,
    users,
    contracts,
    setUsers,
    clearFields,
    getGroupById,
    getContracts,
    contractIds,
    addContracts,
    deleteContracts,
    lessonsChange,
    addLessons,
    deleteLessons,
    setData,
    lessons,
  } = UpdateGroupPageStore;
  const { branches } = BranchesPageStore;
  const { user } = AuthPageStore;
  const { groupId } = useParams<{ groupId: string }>();
  const history = useNavigate();

  useEffect(() => {
    if (!groupId) return;
    getGroupById(Number(groupId));
  }, [getGroupById, groupId]);

  useEffect(() => {
    if(!brId) return
    getContracts();
  }, [getContracts, brId]);

  useEffect(() => {
    if(!brId) return
    getUsers('')
  }, [brId])

  const getUsers = async (value: any) => {
    const params = {
      branchId: Number(brId),
      skip: 0,
      take: 15,
      role: 'TEACHER',
      search: value,
      isActive: true,
    };
    const res = await userServices.getUsers(params).then((res) => res.data);
    setUsers(
      res.data.map((i) => ({
        name: `${i.lastName} ${i.firstName} ${i.middleName}`,
        value: i.id,
      }))
    );
  };

  const updateGroup = async (e: React.FormEvent) => {
    e.preventDefault();
    let validation = true;

    if (!brId || !lang) {
      validation = false;
    }

    // if (!lessons.length) {
    //   showNotification('Добавьте уроки');
    //   validation = false;
    // }

    if (!validation) {
      setHasError(true);
      return;
    }

    const requestData = {
      lang: lang,
      teacherId: Number(teacherId),
      branchId: Number(brId),
      contractIds: contractIds?.map((i: any) => i.id),
      lessons: !!lessons.length
        ? lessons?.map((i: any) => {
            if (!i.value) return {};
            return {
              id: i?.id,
              datetime: new Date(i.value).toISOString(),
            };
          })
        : [],
    };
    try {
      setLoading(true);
      await groupsServises.updateGroup(Number(groupId), requestData);
      clearFields();
      history('/groups');
    } catch (e: any) {
    } finally {
      setLoading(false);
    }
  };

  const goToContract = (id: number) => {
    history(`/contracts/${id}`);
  };

  return (
    <Template>
      <div className='update-group-page'>
        <Title title='Обновить группу' className='update-group-page__title' />
        <form onSubmit={updateGroup}>
          <FormControl fullWidth className='update-group-page__field'>
            <InputLabel>Филиал</InputLabel>
            <Select
              label='Филиал'
              value={brId}
              onChange={(e) => setBrId(e.target.value)}
              error={hasError && !brId}
              disabled={
                user && isSU(user)
                  ? false
                  : user && isAdmin(user)
                  ? false
                  : true
              }
            >
              {branches?.map((i) => (
                <MenuItem key={i.id} value={i.id}>
                  {i.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth className='update-group-page__field'>
            <InputLabel>Язык</InputLabel>
            <Select
              label='Язык'
              value={lang}
              onChange={(e) => setLang(e.target.value)}
              error={hasError && !lang.trim()}
            >
              <MenuItem value={'RU'}>RU</MenuItem>
              <MenuItem value={'UZ'}>UZ</MenuItem>
            </Select>
          </FormControl>
          {brId && (
            <div className='update-group-page__field'>
              <CustomSearchableSelect
                items={[{ name: 'Нет', value: null }, ...users]}
                setSelectItem={setTeacherId}
                value={teacherId}
                setLoading={() => {}}
                loading={false}
                setSearchValue={setSearch}
                searchValue={search}
                setValue={getUsers}
                placeholder='Выбрать учителя'
              />
            </div>
          )}
          <div className='update-group-page__hr'>
            <div className='update-group-page__label'>Добавить контракт</div>
          </div>
          <div className='update-group-page__field update-group-page__field--flex'>
            <CustomSearchableSelect
              items={[{ name: 'Нет', value: null }, ...contracts]}
              setSelectItem={setContractId}
              value={contractId}
              setLoading={() => {}}
              loading={false}
              setSearchValue={setSearchContracts}
              searchValue={searchContracts}
              setValue={getContracts}
              placeholder='Введите номер контракта'
              error={hasError && !contractId}
              setData={setData}
            />
            <Button
              size='medium'
              color='success'
              startIcon={<AddCircleIcon />}
              className='update-group-page__add'
              onClick={addContracts}
            ></Button>
          </div>
          {!!contractIds.length &&
            contractIds?.map((i: any, index: number) => (
              <div className='update-group-page__item' key={index}>
                <div className='update-group-page__name'>{i?.name}</div>
                <Button
                  size='medium'
                  color='error'
                  startIcon={<DeleteForeverIcon />}
                  className='update-group-page__delete'
                  onClick={() => deleteContracts(i.id)}
                ></Button>
              </div>
            ))}
          {!!contractIds.length && (
            <Paper className='update-group-page__contracts'>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Номер контаркта / Ф.И.О.</TableCell>
                    <TableCell>Тариф</TableCell>
                    <TableCell>Статус</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {contractIds.map((i: any, index: number) => (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{i.name}</TableCell>
                      <TableCell>{i.tariff}</TableCell>
                      <TableCell>
                        <Statuses status={i.status} />
                      </TableCell>
                      <TableCell>
                        <div className='update-group-page__flex'>
                          <IconButton
                            aria-label='delete'
                            size='medium'
                            color='error'
                            onClick={() => deleteContracts(i.id)}
                          >
                            <DeleteForeverIcon />
                          </IconButton>
                          <IconButton
                            aria-label='delete'
                            size='medium'
                            color='primary'
                            onClick={() => goToContract(i.id)}
                          >
                            <ArrowForwardIosIcon />
                          </IconButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          )}
          <div className='update-group-page__hr'>
            <div className='update-group-page__label'>Список уроков</div>
            {/* <Button
              color='success'
              className='update-group-page__add'
              startIcon={<AddCircleIcon />}
              onClick={addLessons}
              size='small'
            ></Button> */}
          </div>
          {!!lessons.length &&
            lessons?.map((i: any, index: number) => (
              <div
                className='update-group-page__field update-group-page__field--flex'
                key={index}
              >
                <DatePicker
                  selected={
                    !!lessons[index].value
                      ? new Date(lessons[index].value)
                      : undefined
                  }
                  timeInputLabel='Time:'
                  dateFormat='Pp'
                  showTimeInput
                  onChange={(e: any) => lessonsChange(e, index)}
                  locale='ru'
                  customInput={<TextField autoComplete='off' />}
                  autoComplete='off'
                />
                {/* <Button
                  size='medium'
                  color='error'
                  startIcon={<DeleteForeverIcon />}
                  className='update-group-page__delete'
                  onClick={() => deleteLessons(index)}
                ></Button> */}
              </div>
            ))}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                type='submit'
                color='primary'
                size='medium'
                disabled={
                  (user?.role !== 'ADMIN' &&
                    user?.role !== 'SU' &&
                    user?.role !== 'REGISTRATOR' && 
                    user?.role !== 'REGISTRATOR_WITH_CASHIER') ||
                  loading
                }
              >
                Сохранить
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Link to='/groups'>
                <Button color='inherit'>Назад</Button>
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Template>
  );
});

export default UpdateGroupPage;
