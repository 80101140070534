import React, { useCallback, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import _ from 'lodash';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import './CustomSearchableSelect.sass';

interface Props {
  setValue: (value: string) => void;
  items: any[];
  loading: boolean;
  setLoading: (value: boolean) => void;
  setSelectItem: (value: number) => void;
  value?: number;
  setSearchValue: (value: any) => void;
  setData?: (value: any) => void;
  searchValue: string;
  error?: boolean;
  placeholder?: string;
  disabled?: boolean;
  small?: boolean;
}
export const CustomSearchableSelect: React.FC<Props> = ({
  setValue,
  items,
  value,
  loading,
  setLoading,
  setSelectItem,
  setSearchValue,
  setData,
  searchValue,
  error,
  placeholder,
  disabled,
  small,
}) => {
  const [showItems, setShowItems] = useState(false);

  const selectRef = useRef<any>();

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setShowItems(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const selectItem = (
    e: React.MouseEvent,
    name: string,
    value: number,
    data?: any
  ) => {
    e.stopPropagation();
    e.preventDefault();
    setSearchValue(name);
    setSelectItem(value);
    setData && setData(data);
    setShowItems(false);
  };

  const request = _.debounce(async (value) => {
    return await setValue(value);
  }, 1000);

  const debouceRequest = useCallback((value: any) => {
    setLoading(true);
    return request(value);
  }, []);

  const updateValue = (val: string) => {
    setSearchValue(val);
    debouceRequest(val);
  };

  const focusedInput = () => {
    setShowItems(true);
  };

  return (
    <div className='custom-searchable-select' ref={selectRef}>
      <div className='custom-searchable-select__container'>
        <input
          className={cn('custom-searchable-select__input', {
            'custom-searchable-select__input--error': error,
            'custom-searchable-select__input--disabled': disabled,
            'MuiInputBase-sizeSmall': small,
          })}
          value={searchValue}
          disabled={disabled}
          placeholder={placeholder || ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            updateValue(e.target.value)
          }
          onFocus={focusedInput}
        />
        <ArrowDropDownIcon className='custom-searchable-select__icon' />
      </div>
      <div
        className={cn('custom-searchable-select__list', {
          'custom-searchable-select__list--active': showItems,
        })}
      >
        <div className='custom-searchable-select__list-container'>
          {items.map((i, index) => (
            <div
              className='custom-searchable-select__list-item'
              key={index}
              onClick={(e) =>
                selectItem(e, i.name, i.value, {
                  tariff: i?.tariff,
                  status: i?.status,
                })
              }
            >
              {i.name}
            </div>
          ))}
        </div>
        {!items.length && (
          <div className='custom-searchable-select__list-item custom-searchable-select__list-item--empty'>
            Результатов не найдено
          </div>
        )}
      </div>
    </div>
  );
};
