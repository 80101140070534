import instance from "api/instance";
import qs from 'query-string'
import { ICreateGroup, IDeleteGroup, IGetGroupById, IGetGroups, IUpdateGroup } from "./types";


export const getGroups: IGetGroups = (params) => instance.get(`/groups?${qs.stringify(params, { skipEmptyString: true })}`)

export const getGroupById: IGetGroupById = (id) => instance.get(`/groups/${id}`)

export const createGroup: ICreateGroup = (data) => instance.post('/groups', data)

export const updateGroup: IUpdateGroup = (id, data) => instance.put(`/groups/${id}`, data)

export const deleteGroup: IDeleteGroup = (id) => instance.delete(`/groups/${id}`)