import NotificationStore from 'components/Notification/NotificationStore';
import { NotificationStatus } from 'components/Notification/types';

const showNotification = (message: string, status?: NotificationStatus) => {
  NotificationStore.showNotification(
    message,
    status || NotificationStatus.WARNING
  );
};

export default showNotification;
