import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { categoriesServises } from "api/services";
import Title from "components/Title/Title";
import Template from "layout/Template/Template";
import UpdateCategoryPageStore from "./UpdateCategoryPageStore";
import { Button, Grid, TextField } from "@mui/material";
import './UpdateCategoryPage.sass'
import ConfirmationStore from "components/Confirmation/ConfirmationStore";
import showNotification from "helpers/showNotification";
import { NotificationStatus } from "components/Notification/types";
import CategoriesPageStore from "pages/CategoriesPage/CategoriesPageStore";


const UpdateCategoryPage: React.FC = observer(() => {
  const { categoryId } = useParams<{ categoryId: string }>();
  const { name, setName, hasError, setHasError, loading, setLoading, clearFields } = UpdateCategoryPageStore
  const { getData } = CategoriesPageStore
  const history = useNavigate()


  useEffect(() => {
    if(!categoryId) return
    getCategoryById()
  }, [categoryId])

  const getCategoryById = async () => {
    const res = await categoriesServises.getCategoryById(Number(categoryId)).then((res) => res.data)
    setName(res.name)
  }

  const updateCategory = async () => {
    if(!name.trim()) return
    const data = {
      name: name
    }
    try {
      setLoading(true)
      await categoriesServises.updateCategory(Number(categoryId), data)
      getData()
      clearFields()
      history('/categories')
    } catch (e: any) {

    } finally {
      setLoading(false)
    }
  }

  const confirmDeleteCategory = () => {
    if (!categoryId) return;
    const message = 'Вы действительно хотите удалить этот филиал?';

    const deleteCategory = async () => {
      try {
        setLoading(true)
        await categoriesServises.deleteCategory(Number(categoryId))
        showNotification('Категория удалена', NotificationStatus.INFO);
        getData()
        clearFields()
        history('/categories');
      } catch (e){
        showNotification('Ошибка попробуйте позже');
      } finally {
        setLoading(false)
      }
    }
    ConfirmationStore.showConfirm(message, deleteCategory);
  };
  
  

  

  return (
    <Template>
      <div className="update-category-page">
        <Title title="Обноваить категорию" className="update-category-page__title" />
        <form onSubmit={updateCategory}>
          <TextField
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={hasError}
            placeholder="Название"
            className="update-category-page__field"
          />
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Button type='submit' color="primary" size="medium">Сохранить</Button>
            </Grid>
            <Grid item xs={4}>
              <Link to="/categories">
                <Button color="inherit">Назад</Button>
              </Link>
            </Grid>
            <Grid item xs={4}>
              <Button color="error" onClick={confirmDeleteCategory}>Удалить</Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Template>
  )
})

export default UpdateCategoryPage