import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import moment from 'moment';
import Title from 'components/Title/Title';
import Template from 'layout/Template/Template';
import CoursesPageStore from './CoursesPageStore';
import showNotification from 'helpers/showNotification';
import { coursesTypes } from 'refs/courseTypes';
import { statuses } from 'refs/statuses';
import { individuals } from 'refs/individual';
import { translateCourseType } from 'helpers/translate';
import CategoriesPageStore from 'pages/CategoriesPage/CategoriesPageStore';
import AuthPageStore from 'pages/AuthPage/AuthPageStore';
import { isAdmin, isSU } from 'helpers/checkRole';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import './CoursesPage.sass';

export const CoursesPage: React.FC = observer(() => {
  const {
    params,
    setTake,
    setSkip,
    setCategoryId,
    setIsActive,
    setType,
    setIndividual,
    setSearch,
    getCourses,
    setHasError,
    hasError,
    clearFields,
    courses,
    totalCount,
  } = CoursesPageStore;
  const { categories } = CategoriesPageStore;
  const { user } = AuthPageStore;

  useEffect(() => {
    getCourses();
  }, [getCourses, params.take, params.skip]);

  useEffect(() => {
    return () => {
      clearFields();
    };
  }, []);

  const formSubmit = (e: React.FormEvent) => {
    e?.preventDefault();
    if (
      !params.isActive?.trim() &&
      !params.type?.trim() &&
      !params.categoryId &&
      !params.search?.trim() &&
      !params.individual?.trim() &&
      !!courses.length
    ) {
      showNotification('Для поиска заполните поля');
      setHasError(true);
      return;
    }
    setHasError(false);
    if (params.skip > 0) {
      setSkip(0);
    } else {
      getCourses();
    }
  };

  return (
    <Template
      btnUrl='/courses/create'
      roleGuard={(user && isSU(user)) || (user && isAdmin(user))}
    >
      <div className='courses-page'>
        <Title title='Курсы' className='courses-page__title' />
        <form onSubmit={formSubmit}>
          <Grid container spacing={2} justifyContent='flex-end'>
            <Grid item xs={4}>
              <TextField
                value={params.search}
                onChange={(e) => setSearch(e.target.value)}
                size='small'
                label='Поиск'
                error={hasError && !params.search?.trim()}
                autoComplete='off'
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl
                fullWidth
                size='small'
                error={hasError && !params.type.trim()}
              >
                <InputLabel variant='outlined'>Выберите тип курса</InputLabel>
                <Select
                  label={'Выберите тип курса'}
                  value={params.type}
                  onChange={(e) => setType(e.target.value)}
                  size='small'
                >
                  {coursesTypes?.map((i) => (
                    <MenuItem key={i.value} value={i.value}>
                      {i.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl
                fullWidth
                size='small'
                error={hasError && !params.isActive?.trim()}
              >
                <InputLabel variant='outlined'>Выберите статус</InputLabel>
                <Select
                  label={'Выберите статус'}
                  value={params.isActive}
                  onChange={(e) => setIsActive(e.target.value)}
                  size='small'
                >
                  {statuses?.map((i) => (
                    <MenuItem key={i.value} value={i.value}>
                      {i.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl
                fullWidth
                size='small'
                error={hasError && !params.individual?.trim()}
              >
                <InputLabel variant='outlined'>Выберите тип занятий</InputLabel>
                <Select
                  label={'Выберите тип занятий'}
                  value={params.individual}
                  onChange={(e) => setIndividual(e.target.value)}
                  size='small'
                >
                  {individuals?.map((i) => (
                    <MenuItem key={i.value} value={i.value}>
                      {i.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl
                fullWidth
                size='small'
                error={hasError && !params.categoryId}
              >
                <InputLabel variant='outlined'>Выберите категорию</InputLabel>
                <Select
                  label={'Выберите категорию'}
                  value={params.categoryId}
                  onChange={(e) => setCategoryId(e.target.value)}
                  size='small'
                >
                  {categories?.map((i) => (
                    <MenuItem key={i.id} value={i.id}>
                      {i.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4} justifyContent='flex-end'>
              <div className='row'>
                <Button
                  className='courses-page__button'
                  color='primary'
                  size='small'
                  type='submit'
                >
                  Найти
                </Button>
                <Button
                  className='courses-page__button'
                  color='error'
                  size='small'
                  type='button'
                  onClick={() => clearFields(true)}
                >
                  Очистить
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>

        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Название</TableCell>
                <TableCell>Статус</TableCell>
                {/* <TableCell>Категория</TableCell> */}
                <TableCell>Тип курса</TableCell>
                <TableCell>Тип занятия</TableCell>
                <TableCell>Дата изменения</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {courses.map((i) => (
                <TableRow
                  key={i.id}
                >
                  <TableCell>{i.id}</TableCell>
                  <TableCell>{i.titleRu}</TableCell>
                  <TableCell
                    className={cn('courses-page__table-cell', {
                      'courses-page__table-cell--active': i.isActive,
                    })}
                  >
                    {i.isActive ? 'Активный' : 'Неактивный'}
                  </TableCell>
                  {/* <TableCell>
                    {i.categoryId
                      ? CategoriesPageStore.getCategoryBydId(i.categoryId)
                      : ''}
                  </TableCell> */}
                  <TableCell>{translateCourseType(i.type)}</TableCell>
                  <TableCell>
                    {i.individual ? 'Индивидуальный' : 'Групповой'}
                  </TableCell>
                  <TableCell>
                    {moment(i.updatedAt).format('DD.MM.YYYY')}
                  </TableCell>
                  <TableCell>
                    <Link  to={`/courses/${i.id}/update`} className="go-to">
                      <ArrowForwardIcon />
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
      {totalCount > params.take && (
        <TablePagination
          count={totalCount}
          rowsPerPageOptions={[10, 25, 50]}
          component='div'
          rowsPerPage={params.take}
          labelRowsPerPage='Количество на странице'
          page={params.skip}
          onRowsPerPageChange={(e) => setTake(Number(e.target.value))}
          onPageChange={(e, newPage) => setSkip(newPage)}
        />
      )}
    </Template>
  );
});
