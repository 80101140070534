import { Button } from "@mui/material"
import { studentsServices } from "api/services"
import ModalTemplate from "layout/ModalTemplate/ModalTemplate"
import { observer } from "mobx-react-lite"
import { useEffect } from "react"
import './NoteModal.sass'
import NoteModalStore from "./NoteModalStore"


interface Props {
  data?: string
  hide: () => void
  studentId: number
}

const NoteModal: React.FC<Props> = observer(({
  data,
  hide,
  studentId
}) => {
  const {note, setNote, loading, setLoading} = NoteModalStore

  useEffect(() => {
    return (() => {
      setNote('')
    })
  }, [])

  const createNote = async () => {
    if(!studentId) return 
    const params = {
      note: note
    }
    try {
      setLoading(true)
      await studentsServices.updateStudentNote(studentId, params)
    }catch (e: any){

    } finally {
      setLoading(false)
    }


  }
  
  return (
    <ModalTemplate hide={hide} title="Оставить комментарий">
      <div className="note-modal">
        <textarea
          value={note ? note : data}
          className="note-modal__textarea"
          placeholder="Примечание"
          onChange={(e) => setNote(e.target.value)}
        />
      </div>
      <Button disabled={loading} onClick={createNote} color='primary' size='medium' type='submit'>
        Сохранить
      </Button>
    </ModalTemplate>
  )
})

export default NoteModal