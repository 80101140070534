export interface IStatus {
  title: string;
  value: string;
}

export const statuses: IStatus[] = [
  {
    title: 'Активный',
    value: 'true',
  },
  {
    title: 'Неактивный',
    value: 'false',
  },
];
