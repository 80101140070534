import { useEffect, useRef } from "react";
import { useParams } from "react-router";
import { observer } from "mobx-react-lite";
import moment from "moment";
import cn from 'classnames'
import Title from "components/Title/Title";
import Template from "layout/Template/Template";
import StudentTimelineStore from "./StudentTimelineStore";
import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import './StudentTimeline.sass'
import ExamModal from "components/Modal/ExamModal/ExamModal";
import Modal from "components/Modal/Modal";


const StudentTimeline: React.FC = observer(() => {
  const { studentId } = useParams<{ studentId: string }>();
  const ref = useRef<any>()
  const { getTimeline, timeline, examType, setExamType, result, setResult, student } = StudentTimelineStore

  useEffect(() => {
    if(!studentId) return
    getTimeline(Number(studentId))
  }, [getTimeline, studentId])

  const renderType = (i: any) => {
    switch (i.type) {
      case 'CONTRACT':
        return 'Заключил контракт';
      case 'LESSON':
        return 'Экспресс курсы (урок)';
      case 'YIM':
        return (
            i.result?.score ? 'Теория (экз центр)' : 'Практика (экз центр)'
        )
      default:
        return null;
    }
  }

  const showModal = (examType: string, result: any) => {
    ref.current.onShow()
    setExamType(examType)
    setResult(result)
  }

  const hideModal = () => {
    setExamType('')
    setResult({})
    ref.current.onHide()
  }
  
  return(
    <Template>
      <div className="student-timeline">
        <Title className="student-timeline__title" title="История студента"/>
        <Paper className='student-timeline__paper'>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Ф.И.О.: </TableCell>
                <TableCell>Серия/Номер/ПИНФЛ: </TableCell>
                <TableCell>Номер телефона: </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{student.lastName} {student.firstName} {student.middleName ? student.middleName : ''} </TableCell>
                <TableCell>{student.passportSerial}/{student.passportNumber}/{student.pinfl} </TableCell>
                <TableCell>{student.phone} </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
        {!!timeline.length && (
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Тип</TableCell>
                  <TableCell>Дата</TableCell>
                  <TableCell>Тариф</TableCell>
                  <TableCell className="student-timeline__colspan" colSpan={2}>Экспресс курсы</TableCell>
                  <TableCell className="student-timeline__colspan">Экз. центр</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {timeline.map((i: any, index: number) => (
                  <TableRow key={index}>
                    <TableCell>{renderType(i)}</TableCell>
                    <TableCell>{moment(i?.datetime).format('DD.MM.YYYY')}</TableCell>
                    <TableCell>{i?.tariff}</TableCell>
                    <TableCell className="student-timeline__colspan">{i.initEval ? `Начальная оценка: ${i.initEval} %` : null}</TableCell>
                    <TableCell className="student-timeline__colspan">{i.finalEval ? `Финальная оценка: ${i.finalEval} %` : null}</TableCell>
                    <TableCell className="student-timeline__colspan">
                      {i?.type === 'YIM' && i?.result?.details && (
                        <div 
                          onClick={() => showModal('PRACTICE_EXAM', i?.result)}
                          className={cn ('student-timeline__button', {
                          'student-timeline__button--red' : i.value === false,
                          'student-timeline__button--green' : i.value === true,
                        })}>Практика</div>
                      )}
                      {i?.type === 'YIM' && i?.result?.score && (
                        <div 
                          onClick={() => showModal('THEORY_EXAM', i?.result)}
                          className={cn ('student-timeline__button', {
                            'student-timeline__button--red' : i.value === false,
                            'student-timeline__button--green' : i.value === true,
                        })}>Теория</div>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        )}
      </div>
      <Modal ref={ref} onClick={hideModal}>
        <ExamModal result={result} type={examType} hide={hideModal}/>
      </Modal>
    </Template>
  )
})

export default StudentTimeline