import cn from 'classnames'
// import Print from 'assets/images/print.png'
import Print from 'assets/images/print-2.png'
import './PirntModal.sass'

interface Props {
  name: string
  issued: string
  address: string
  passport: string
  oneTime?: boolean

}

const PirntModal: React.FC<Props> = ({
  name,
  issued,
  address,
  passport,
  oneTime
}) => {
  return (
    <div className={cn ('print-modal', {
      'print-modal--one-time' : oneTime 
    })}>
      {/* <img src={Print} alt="" className="print-modal__img" /> */}
      <div className="print-modal__in">
        <div className="print-modal__name">Ф.И.О: {name}</div>
        <div className="print-modal__name">Адрес: {address}</div>
        <div className="print-modal__name">Паспорт серия/номер: {passport}</div>
        <div className="print-modal__name">Кем выдан: {issued}</div>
      </div>
    </div>
  )
}

export default PirntModal