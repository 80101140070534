import { IUser } from 'api/services/users/types';

export const isSU = (user?: IUser) => user?.role === 'SU';

export const isAdmin = (user?: IUser) => user?.role === 'ADMIN';

export const isRegistrator = (user?: IUser) => user?.role === 'REGISTRATOR';

export const isRegistratorWidthCashier = (user?: IUser) => user?.role === 'REGISTRATOR_WITH_CASHIER';

export const isGarage = (user?: IUser) => user?.role === 'GARAGE';

export const isTeacher = (user?: IUser) => user?.role === 'TEACHER';

export const isInstructor = (user?: IUser) => user?.role === 'INSTRUCTOR';

export const isReferral = (user?: IUser) => user?.role === 'REFERRAL';

export const isAccountant= (user?: IUser) => user?.role === 'ACCOUNTANT';

export const isCashier = (user?: IUser) => user?.role === "CASHIER"