import { makeAutoObservable } from "mobx"


class FinalEvalModalStore {
  finalEval = ''
  hasError = false
  loading = false

  constructor(){
    makeAutoObservable(this)
  }

  setLoading = (e: boolean) => {
    this.loading = e
  }
   
  setFinalEval = (e: any) => {
    if(e > 20) return
    this.finalEval = e
  }
  
  setHasError = (e: any) => {
    this.hasError = e
  }

}

export default new FinalEvalModalStore