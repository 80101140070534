import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { observer } from 'mobx-react-lite';
import { tariffsServises } from 'api/services';
import Title from 'components/Title/Title';
import Template from 'layout/Template/Template';
import CreateTariffPageStore from './CreateTariffPageStore';
import CoursesPageStore from 'pages/CoursesPage/CoursesPageStore';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@mui/material';
import './CreateTariffPage.sass';

const CreateTariffPage: React.FC = observer(() => {
  const {
    loading,
    setLoading,
    hasError,
    setHasError,
    cost,
    setCost,
    isActive,
    setIsActive,
    titleRu,
    setTitleRu,
    titleUz,
    setTitleUz,
    coursesData,
    courseChange,
    addCourses,
    clearFields,
    deleteHandler,
  } = CreateTariffPageStore;
  const { getCourses, courses } = CoursesPageStore;
  const history = useNavigate();

  useEffect(() => {
    getCourses();
  }, [getCourses]);

  useEffect(() => {
    return () => {
      clearFields();
    };
  }, []);

  const createTariff = async (e: React.FormEvent) => {
    e.preventDefault();
    let validation = true;

    if (!titleRu.trim() || !titleUz.trim() || !cost.trim()) {
      validation = false;
    }

    if (!validation) {
      setHasError(true);
      return;
    }

    const data = {
      titleUz,
      titleRu,
      cost: Number(cost) * 100,
      isActive,
      courses: coursesData,
    };
    try {
      setLoading(true);
      await tariffsServises.createTariff(data);
      clearFields();
      history('/tariffs');
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Template>
      <div className='create-tariff-page'>
        <Title title='Создать тариф' className='create-tariff-page__title' />
        <form onSubmit={createTariff}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  onChange={(e) => setIsActive(e.target.checked)}
                  checked={isActive}
                />
              }
              label='Активность'
            />
          </FormGroup>
          <TextField
            className='create-tariff-page__field'
            value={titleRu}
            onChange={(e) => setTitleRu(e.target.value)}
            placeholder='Название ру'
            error={hasError && !titleRu.trim()}
          />
          <TextField
            className='create-tariff-page__field'
            value={titleUz}
            onChange={(e) => setTitleUz(e.target.value)}
            placeholder='Название уз'
            error={hasError && !titleUz.trim()}
          />
          <TextField
            className='create-tariff-page__field'
            value={cost}
            onChange={(e) => setCost(e.target.value)}
            placeholder='Цена'
            error={hasError && !cost.trim()}
          />
          <div className="create-tariff-page__add-wrap">
            <div className="create-tariff-page__add-label">Добавить курс</div>
            <Button
              type='button'
              color='success'
              className='create-tariff-page__add'
              size='small'
              onClick={addCourses}
              startIcon={<AddCircleIcon/>}
            >
      
            </Button>
          </div>
          {!!coursesData.length &&
            coursesData.map((i, index) => (
              <Grid container spacing={2} key={index}>
                <Grid item xs={5}>
                  <TextField
                    className='create-tariff-page__field'
                    value={coursesData[index].count}
                    onChange={(e) =>
                      courseChange(e.target.value, index, 'text')
                    }
                    placeholder='Кол-во'
                  />
                </Grid>
                <Grid item xs={5}>
                  <FormControl fullWidth className='create-tariff-page__field'>
                    <InputLabel variant='outlined'>Выберите курс</InputLabel>
                    <Select
                      label={'Выберите курс'}
                      value={coursesData[index].courseId}
                      onChange={(e) =>
                        courseChange(e.target.value, index, 'select')
                      }
                    >
                      {courses?.map((i) => (
                        <MenuItem key={i.id} value={i.id}>
                          {i.titleRu}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    size='small'
                    className='create-tariff-page__delete'
                    color='error'
                    onClick={() => deleteHandler(index)}
                    startIcon={<DeleteForeverIcon/>}
                  >
                  </Button>
                </Grid>
              </Grid>
            ))}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                type='submit'
                color='primary'
                size='medium'
                disabled={loading}
              >
                Сохранить
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Link to='/tariffs'>
                <Button color='inherit'>Назад</Button>
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Template>
  );
});

export default CreateTariffPage;
